import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import {getLevelTitle} from './NextLevelShow';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";

const StatusBar = React.forwardRef((props, reff) => {

    let globTheme = useTheme();

    let navigate = useNavigate();

    if (!props.level)
        return null;
    
   
    // maroon
    return (
        globTheme && <Container >
        <Stack direction="row"   
        justifyContent="space-around"
        alignItems="center"
        >
        {/*!isNaN(props.words_count) && <Typography>Слів: {props.words_count}</Typography>*/}
        <Typography>{getLevelTitle(props.level)}</Typography>
        <Typography sx={{ml:1}}><font color={globTheme.palette.ahref}>Рівень: <b>{props.level}</b></font> / {props.max_level}</Typography>
        <Button variant="text" onClick={() => navigate('/rating')} startIcon={<EmojiEventsIcon fontSize="small" />}>Щабель</Button>
        </Stack>
        </Container>)
    return (
    <>
    <div id="info" style={{paddingTop:"0.1vw", textAlign:"center"}}>
    <span style={{marginRight:"10px"}}>Слів: {props.words_count} </span>
    <span style={{marginRight:"10px"}}><span style={{color:"maroon"}}>Рівень: <b>{props.level}</b> </span>/ {props.max_level}</span>
    </div>
    </>)
});


export default StatusBar;