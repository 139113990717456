import { Link, useNavigate } from "react-router-dom";
import WifiOff from "@mui/icons-material/WifiOff";
import { Container, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import React,{useState,useEffect, useLayoutEffect,  useRef, useImperativeHandle} from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

//export default function Header(props) {
const Header = React.forwardRef((props, reff) => {

    const logoRef = useRef();
    const str_color = useRef();
    const str_color_grad = useRef();
    const [show_refresh, setRefresh ] = React.useState(false);

    const globTheme = useTheme();

    useImperativeHandle(reff, () => ({startGradient }), [ ])

    let navigate = useNavigate();

    let timeoutid_change;

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    useEffect(() => {

        str_color.current = globTheme.palette.logo.main;//"white"//mar0on";
        //let str_color = "white"//mar0on";
        str_color_grad.current = globTheme.palette.logo.gradient; //"#bdbdbd";//"#c07878";
        //let str_color_grad = "#c07878";

    }, [globTheme]);

    useEffect(() => {

        if (!props.showWaiting)
            return;

        delay(20000).then(() => {setRefresh(true)});

    }, [props.showWaiting]);

    function startGradient(callback, globTheme) {
        
        if (null == logoRef.current)
            return;

        mv_sethint_percent(callback, 0, globTheme);
    }

    function mv_sethint_percent(callback, timeoutid_change_passed, globTheme) {

        let percent = Math.round(100*timeoutid_change_passed / 100)
        
        if (100 <= percent) {
           return callback();
        }
        
        if (null == logoRef.current)
            return;

        logoRef.current.style.background = '-webkit-linear-gradient(0deg, ' + str_color.current + ' 0%, ' + str_color_grad.current + ' ' + percent + '%, ' + str_color.current + ' ' + (percent + 1 )+ '%, ' + str_color.current + ' 100% )';
        logoRef.current.style.WebkitBackgroundClip ='text';
        
        clearTimeout(timeoutid_change);
        timeoutid_change = setTimeout(mv_sethint_percent, 600, callback, 1 + timeoutid_change_passed, globTheme);
   }

    if ("offline" != props.network) {

        if (props.multiscore) {
            return <><nav className="nav" style={{marginTop:"0px", textAlign:"center"}}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}><div id={globTheme.palette.mode=='light' ? "divukrmova" :"divukrmovaBlack"} ref={logoRef} ><Typography id="aukrmova"  style={{fontFamily: 'narbut_abetka', fontSize: "36.6px"}} >Мова</Typography></div>{props.multiscore}</Stack>
        </nav></>
        }
        else return (
        <><nav className="nav" style={{marginTop: !props.showWaiting ? "0px" : "40px", textAlign:"center"}}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}><div id={globTheme.palette.mode=='light' ? "divukrmova" :"divukrmovaBlack"} ref={logoRef} ><Typography id="aukrmova" style={{fontFamily: 'narbut_abetka', fontSize: "36.6px"}}>Українська мова</Typography></div></Stack>
            {props.showWaiting && <Box sx={{ width: '100%'}}><LinearProgress />
            {show_refresh && <><Alert severity="error" sx={{margin:"10px", padding:"5px"}}>Щось дуже довго!</Alert><Button variant="outlined" onClick={()=>{window.location.reload();}}>
      Завантажити знову.
      </Button></>}</Box>}
        </nav></>
        );
    };
        
    return (
        <>
        <nav className="nav" style={{marginTop:"20px", textAlign:"center"}}>
            <div ref={logoRef} id={globTheme.palette.mode=='light' ? "divukrmova" :"divukrmovaBlack"}><Link to='/' id="ukrmova2">Українська мова</Link></div>
        </nav><div style={{ display: "block", padding: 30 }}>
        <Container maxWidth="md" style={{ display: 'flex', alignItems: 'center' }}><WifiOff sx={{ fontSize: "80px", color: "grey" }} /><div style={{padding:"10px", fontSize: "20px"}}>Чекаємо на інтернет.</div></Container>
        </div>
        </>)
})

export default Header;