import { createContext } from "react";
import firebaseApp from './firebase.config';
import * as FBLogin from './FBLogin';
import {useRef, useState,useEffect, useContext} from 'react';
import { getAuth, onAuthStateChanged, browserLocalPersistence, setPersistence, signInAnonymously } from "firebase/auth";
import {getAdditionalUserInfo} from "firebase/auth"
import {serverTimestamp, getFirestore, arrayUnion} from 'firebase/firestore';
import { doc, setDoc } from "firebase/firestore"; 
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getColorName} from './game/Colors';
import { collection, getDocs, onSnapshot} from "firebase/firestore"; 
import { query, orderBy, limit } from "firebase/firestore"; 

import {CheckAfterLoginRedirect} from './Login';
import { useNavigate } from "react-router-dom";
import {getLevelTitle} from './game/NextLevelShow'
import {oldRegionUA} from './Countries'

import Header from "./Header";
import { hotjar } from 'react-hotjar';

import { getStorage, ref, getDownloadURL, connectStorageEmulator } from "firebase/storage";

import axios from 'axios';

import { getDatabase, ref as ref_database, push, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import {firebaseConfig2, analytics} from './firebase.config';
import { logEvent } from "firebase/analytics";

const app2 = initializeApp(firebaseConfig2, 'app2');
const database_logs = getDatabase(app2);

const storage = getStorage();

const UserContext = createContext(null)
const auth = getAuth();

const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west1");

if (!process.env.REACT_APP_FACEBOOK_MODE) {
    var hjid = 3382815;
    var hjsv = 6;
    hotjar.initialize(hjid, hjsv);
}

/*
const cryptonode = require('crypto');
const crypto = require('crypto-js');
const fetch = require('fetch-base64');

function fb_valid(signedRequest, signature) {
    try {
        var firstpart = signedRequest.split('.')[0];
        var replaced = firstpart.replace(/-/g, '+').replace(/_/g, '/');
        var signature = crypto.enc.Base64.parse(replaced).toString();
        const dataHash = crypto.HmacSHA256(signedRequest.split('.')[1], APP_SECRET).toString();
        var isValid = signature === dataHash;
        if (!isValid) {
            console.log('Invalid signature');
            console.log('firstpart', firstpart);
            console.log('replaced ', replaced);
            console.log('Expected', dataHash);
            console.log('Actual', signature);
        }

        return isValid;
    } catch (e) {
        console.log("error validating signature", e);
        return false;
    }
}*/

const geocodingQuery = () => {

    const json = JSON.stringify({
        /*"considerIp": "true",
        "wifiAccessPoints": [
          {
            "macAddress": "84:d4:7e:f6:99:64",
            "signalStrength": -54,
            "signalToNoiseRatio": 0
          },
          {
            "macAddress": "84:d4:7e:f6:99:71",
            "signalStrength": -43,
            "signalToNoiseRatio": 0
          },
          {
            "macAddress": "84:d4:7e:f7:21:35",
            "signalStrength": -32,
            "signalToNoiseRatio": 0
          }
        ]*/
      });
    //const geocoderQuery = encodeURIComponent(`${address} ${city}`.replace(/ /g, '+'))
    return axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBHPLI0pVk01jcuKwW4WuN-oZ5vFRZutuk`, json   )
      .then(res => res.data)
      .then(json => {

        console.log('GEO:', json)
        if (json.location.length === 0) {
          return null
        }
        let lat = json.location.lat
        let lng = json.location.lng
        return {lat, lng}
      })
  }

    export function useUser() {

        return useContext(UserContext)
    }

    export async function setUserLastActive(uid, event="", value = 0, props = {}) {

        const docRef = doc(db, "logs_user_active", uid);
        setDoc(docRef, {last_time: serverTimestamp()}, { merge: true });

        if (event!=="") {
            window.FBInstant && window.FBInstant.logEvent(event, +value, props);

            // const docRef = doc(db, "logs_user", event);
            let day = new Date().toISOString().split('T')[0];
            // setDoc(docRef, {[day]: arrayUnion({time: new Date().toISOString(), uid: uid, value: value, ...props})}, { merge: true });

            set(push(ref_database(database_logs, `/logs_user/${event}/${day}`)), {time: new Date().toISOString(), uid: uid, value: value, ...props})

            // Google Analytics
            logEvent(analytics, event, {uid: uid, value: value, ...props});
            if (event == 'level' || event == 'multi_level') {
                logEvent(analytics, `${event}_${value}`);
            }

            if (hotjar.initialized()) {
                hotjar.event(event, {uid: uid, value: value, ...props});

                if (event == 'level' || event == 'multi_level') {
                    hotjar.event( `${event}_${value}`);
                }
            }
        };
            
    }

    export function UserProvider({children}) {

        const [currentUser, setCurrentUserState] = useState();
        const [mFBInstant, setFbInstant] = useState(null);
        const [FbUser, setFbUser] = useState(null);
        const [sKey, setsKeyCookie] = useState(null);
        const lastWord = useRef([]);
        const lastWordShort = useRef();
        const lastWordShortKey = useRef();
        const lastWordGameId = useRef();

        const lastSnackbar = useRef();
        const lastGameId = useRef();
        const lastMultiGameId = useRef();
        const profile = useRef();
        const games = useRef();
        const solo_level = useRef();
        const showedInviteFriends = useRef({});
        const firstLoginMultiGameRedirect = useRef(false);
        const afterLoginRedirect = useRef();

        const setIntroOff = useRef(false);

        const navigate = useNavigate();
        const muted = useRef(false);

        const levelPromoShowed = useRef({});

        const userPhotos = useRef({});

        // var get_words_count = httpsCallable(functions, 'get_words_count');
        
        async function getUserPhotoURL(uid, funcSetPhoto) {

            //return '/error2.jpg';
            /*
            console.log('currentUser:', activeUser);
            if (activeUser && uid == activeUser.uid && activeUser.isAnonymous) {
                userPhotos.current[uid] = null;
            }
            */
            if (uid in userPhotos.current) {
                if (funcSetPhoto)
                    funcSetPhoto(userPhotos.current[uid]);
                return userPhotos.current[uid];
            };
            
            if (FbUser && FbUser.playerPic && uid == 'fb_uid_' + FbUser.asid)
            {
                userPhotos.current[uid] = FbUser.playerPic;
                return FbUser.playerPic;
            };
            
            let fb_url = 'photos/f_' + uid + '.jpg';
            let g_url = 'photos/g_' + uid + '.jpg';
    
            //fb_url = 'photos/logo3w.png';

            //let result = await Promise.resolve('this is a sample promise');
            //return result;
            //const f =  async () => {return 'AAA'};

            /*
            const urlOptions = {
                version: "v4",
                action: "read",
                expires: Date.now() + 1000 * 60 * 2, // 2 minutes
              }*/

            return getDownloadURL(ref(storage, fb_url))
            .then((url) => {
                
                userPhotos.current[uid] = url;
                if (funcSetPhoto)
                    funcSetPhoto(userPhotos.current[uid]);
            
                return url;
            })
            .catch((error) => {
                return getDownloadURL(ref(storage, g_url))
                .then((url) => {
                    
                    userPhotos.current[uid] = url;
                    if (funcSetPhoto)
                        funcSetPhoto(userPhotos.current[uid]);
                    
                    return url;
                })
                .catch((error) => {

                    userPhotos.current[uid] = null;
                    return null;
                });
            });
        }

        const getGamesList = async (userId) => {

            let docs = [];
        
            const metagameRef = collection(db, "meta_data_games");
            const q = query(metagameRef, orderBy("players." + userId), limit(150));
            
            const querySnapshot = await getDocs(q);
        
            querySnapshot.forEach(async (doc) => {
        
                let data = doc.data()
                
                for (var key in data.players) {
                    data.players[key].color_number = data.players[key].color;
                };

                docs.push([doc.id, data, data.last_move.seconds])

            });   
            
            return (docs.sort((a, b) => b[2] - a[2]));
        };

        function setProtoUser(user) {
            
            if (!user) 
                return;

            // console.log('getAdditionalUserInfo1:', user)
            // console.log('getAdditionalUserInfo2:', getAdditionalUserInfo(user))
            getGamesList(user.uid).then(async (user_games) => {

                user.lastWord = lastWord;
                user.lastWordShort = lastWordShort;
                user.lastWordShortKey = lastWordShortKey;
                user.lastWordGameId = lastWordGameId;
                user.lastSnackbar = lastSnackbar;

                user.lastGameId = lastGameId;
                user.lastMultiGameId = lastMultiGameId;
                user.games = games;
                user.games.current = user_games;
                
                user.solo_level = solo_level;
                user.muted = muted;
                user.game_profile = profile;
                user.levelPromoShowed = levelPromoShowed;

                user.setIntroOff = setIntroOff;
                
                user.showedInviteFriends = showedInviteFriends;
                user.firstLoginMultiGameRedirect = firstLoginMultiGameRedirect;
                user.afterLoginRedirect = afterLoginRedirect;
                
                user.onProfileUpdate = ()=>{};
                user.getUserPhotoURL = getUserPhotoURL;
                user.userPhotos = userPhotos;
                //user.photoURL = await getUserPhotoURL(user.uid);
                
                // Empty data
                lastWord.current = [];
                lastWordShort.current = null;
                lastWordShortKey.current = null;
                lastGameId.current = null;
                lastMultiGameId.current = null;
                profile.current = null;
                solo_level.current = null;

                showedInviteFriends.current = {};
                levelPromoShowed.current = {};

                //user.uid='c8c52d976c4d657040944088b0f00b79';
                // user.uid= '4b0ba3075be14519bb86c1b87810c550'; // Level 200
                // user.uid= 'f8e3a98bf10e789404219d5357d9eeb9'; // Level :352
                setCurrentUserState(user);
            })
           
        }

     function setLastLogin(user, FbUser) {

        if (user && !user.isAnonymous) {

            if (hotjar.initialized()) {
                hotjar.identify(user.uid);
            }
            /*
            const uid = user.uid;
            let new_data = {last_login: serverTimestamp()};
            if (FbUser && FbUser.entryPointData && FbUser.entryPointData.invitedBy) {
                new_data.invitedBy = FbUser.entryPointData.invitedBy;
            };

            const fsProfile = doc(db, 'profiles', uid);
            setDoc(fsProfile, new_data, { merge: true });*/
            const BACKEND_URL = 'https://europe-west1-mova-game-apps.cloudfunctions.net/';
            const url = BACKEND_URL + '/logLogin';
            const method = 'POST';

            let invite_accept;
            let payload = {
                uid: user.uid,
            };

            if (FbUser && FbUser.entryPointData && FbUser.entryPointData.invitedBy ) {

               payload.invitedBy = FbUser.entryPointData.invitedBy;
               invite_accept = FbUser.entryPointData;
            }

            //BackEndRequest(url, method, payload);
            new Promise(function(resolve, reject) {
                const xmlhttp = new window.XMLHttpRequest();
                xmlhttp.open(method, url, true);
                xmlhttp.setRequestHeader('Content-Type', 'application/json');
                xmlhttp.send(JSON.stringify(payload));
              });

            if (payload.invitedBy) {
                // LogLog user invite   

                const docRef = doc(db, "logs_user_invite_accept", user.uid);
                setDoc(docRef, {[payload.invitedBy]: {[new Date().toJSON()]: invite_accept}}, { merge: true });
            }
            setUserLastActive(user.uid, "invite_accept");
            // console.log('just call')

        /*
            axios.post(url, {},
                {
                  params: {
                    uid: user.uid,
                    invitedBy: FbUser.entryPointData ? FbUser.entryPointData.invitedBy : null
                  }
                })
                .then(response => {
                    console.log('SENT');
                  })
                  .catch(error => {
                    console.log('ERR');
                  });*/
        };
    }

    function getFbToken() {
        // console.log('I am here!')

        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/en_US/fbinstant.7.1.js'
        script.id = 'fbinstant'
        document.body.appendChild(script);
        script.onload = function() {
        
        const FBInstant = window.FBInstant;
        //$('#info-section').hide();
        
        if (FBInstant === undefined)
            return false
        
        // console.log('I am here2!');
        // When the window loads, start to initialize the SDK
        FBInstant.initializeAsync().then(function() {
    
            // We can start to load assets
            /*
            for (let i in assets) {
            // When preloading assets, make sure to report the progress
            FBInstant.setLoadingProgress(i / assets.length * 100);
            }*/
            
            FBInstant.setLoadingProgress(100);

            // Now that assets are loaded, call startGameAsync
            // console.log('Then1 after initializeAsync!');
            FBInstant.startGameAsync().then(function() {
                // console.log('Then2 after startGameAsync!');

                /*
                FBInstant.player
                .setDataAsync({
                    achievements: 'Hello2',
                    currentLife: 300,
                })
                .then(function() {
                    console.log('data is set');
                });

                FBInstant.player
                .getDataAsync(['achievements', 'currentLife'])
                .then(function(data) {
                    console.log('data is loaded', data);
                });*/

                setFbInstant(FBInstant)
            });
            //FBInstant.startGameAsync().then(onStart);
        });
        };
    };

    useEffect(() =>{
        
        if (mFBInstant) {
        //console.log('Run fetchAllFBData')
        FBLogin.fetchAllFBData(mFBInstant, setFbUser);
        //Vibration.vibrate();

        /*
        console.log('Before vibration!');
        for (let i=0; i<10; i++)
            mFBInstant.performHapticFeedbackAsync()
            .then(function() {
            console.log('After vibration!');
            });
            */
        }

    }, [mFBInstant]);

    useEffect(() => {
        
        if (null == FbUser) return;
    
        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            console.log('User changer:',user)
            //console.log('uid:',uid)
            user.FbUser = FbUser;
            setProtoUser(user);
            
            setLastLogin(user, FbUser);
            // ...
        } else {
            
            // User is signed out
            // ...
            setProtoUser(null);
            console.log('Start loginFBToken');
            FBLogin.loginFBToken(FbUser).catch(function(error) {
                console.log("firebase.auth().signInWithCustomToken() Error: ");
                console.log(error);
                navigate("/error");
            });;
        
        }
        });

        return unsubscribe;

    }, [FbUser]);

    useEffect(() =>{

        if (process.env.REACT_APP_FACEBOOK_MODE) return;
        if ("" == sKey || null === sKey) return;

        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
        //console.log('User changer:',user)
            //console.log('uid:',uid)
            setProtoUser(user);
            setLastLogin(user, FbUser);

            // ...
        } else {
            
            // User is signed out
            // ...
            setProtoUser(null);
            
            //console.log('sKey login')
            FBLogin.loginSKToken(sKey);
        }
        });

        // unsubscribing from the listener when the component is unmounting.
        return unsubscribe;
    }, [sKey]);

    useEffect(() =>{

        //console.log('FB MODE', process.env.REACT_APP_FACEBOOK_MODE);

        if (process.env.REACT_APP_FACEBOOK_MODE) {
            console.log(process.env, 'REACT_APP_FACEBOOK_MODE');
            getFbToken();
        } else {
            //console.log('REGULAR LOGIN');
            //let cookie_skey = document.cookie.replace(/(?:(?:^|.*;\s*)skey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            //setsKeyCookie(cookie_skey);
        };

    }, []);

    useEffect(() =>{

        if (process.env.REACT_APP_FACEBOOK_MODE) return;
        if (sKey) return;

        CheckAfterLoginRedirect(afterLoginRedirect.current);

        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            //console.log('User changer:',user)
            //console.log('uid:',uid)
            //console.log('ENV:', process.env)

            if (user.isAnonymous) {
                // Create the first Level

                var get_first_game = httpsCallable(functions, 'get_first_game');
                get_first_game({docId: uid});
            };

            setProtoUser(user);
            setLastLogin(user, FbUser);

            // ...
        } else {
            
            // User is signed out
            // ...
            setProtoUser(null);
            
            //console.log('NO USER, TRY ANONYMIZE')
            signInAnonymously(auth)
            .then((userCredential) => {
                //console.log('Additional user info: ', userCredential.additionalUserInfo);
                //console.log('Additional user info: ', getAdditionalUserInfo(userCredential));
            // Signed in..
            })
            .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
            });
            
        }
        });

        // unsubscribing from the listener when the component is unmounting.
        return unsubscribe;
    }, []);

    useEffect(() => {

        if (!currentUser)
            return;
        
            /*
            let docs = [];

            const metagameRef = collection(db, "meta_data_games");
            const q = query(metagameRef, orderBy("players." + userId), limit(150));
            
            const querySnapshot = await getDocs(q);
        
            querySnapshot.forEach((doc) => {
        
                let data = doc.data()
                docs.push([doc.id, data, data.last_move.seconds])
                
                for (var key in data.players) {
                    data.players[key].color_number = data.players[key].color;
                };
        
            });   
            
            return (docs.sort((a, b) => b[2] - a[2]));*/
           

        return onSnapshot(query(collection(db, "meta_data_games"), orderBy("players." + currentUser.uid), limit(150)), {}, (querySnapshot) => {
            let docs = [];

            querySnapshot.forEach(async (doc) => {
                let data = doc.data();
                docs.push([doc.id, data, data.last_move && data.last_move.seconds])
                for (var key in data.players) {
                    data.players[key].color_number = data.players[key].color;
                    //data.players[key].photo = await getUserPhotoURL(key);
                };
            });

            currentUser.games.current = (docs.sort((a, b) => b[2] - a[2]));
            //console.log(currentUser.games.current); 
        });

    }, [currentUser]);


    useEffect(() => {

        if (!currentUser)
            return;
        
        return onSnapshot(doc(db, "profiles", currentUser.uid), {}, (docum) => {
            let val = docum.data();
            if (!val)
                return;
                
            /*
            if (val.show_region) {

                if (!val.show_region.name && oldRegionUA[val.show_region]) {

                    val.show_region = oldRegionUA[val.show_region];
                }
            }*/
            let fb_player_id = null;
            if (val['facebook.com'] && val['facebook.com']['playerId'])
                fb_player_id = val['facebook.com']['playerId'];

            if (!val.show_name && val['google.com'] && val['google.com']['full_name'] ) {
                val.show_name = val['google.com']['full_name'];
            }
    
            if (!val.show_name && val['google.com'] && val['google.com']['displayName'] ) {
                val.show_name = val['google.com']['displayName'];
            }

            if (!val.show_name && val['facebook.com'] && val['facebook.com']['playerName'] ) {
                val.show_name = val['facebook.com']['playerName'];
            }
            
            // console.log('GMAP', );
            currentUser.game_profile.current = {level: val.level, title: getLevelTitle(val.level), fb_player_id: fb_player_id, show_name: val.show_name, show_country: val.show_country, show_region: val.show_region, show_city: val.show_city, gmap: val.gmap};
            currentUser.onProfileUpdate(currentUser.game_profile);
        });

    }, [currentUser]);

    return <>
        {currentUser && <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>}
        {!currentUser && <Header showWaiting={true} />}
    </>
}