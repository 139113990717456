/*import './index.css';
import './static/css/main.css';*/

import {useParams, Link, useNavigate} from "react-router-dom";
import parse, { domToReact, attributesToProps } from 'html-react-parser';

import Box from '@mui/material/Box';
import Header from "./Header";
import Button from '@mui/material/Button';
import SlowMotionVideo from '@mui/icons-material/SlowMotionVideo';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';

import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import {useUser} from './UserContext';
import { useEffect } from "react";

import {setUserLastActive} from './UserContext';

var __html = require('./templates/about.html.js');
var template = { __html: __html };

export default function About() {

    let navigate = useNavigate();

    const globTheme = useTheme();
    const user = useUser();

    useEffect(() => {

      if (!user)
        return;

      setUserLastActive(user.uid, 'about');
    }, [user]);

    let styles = {}
    styles["a"] = {color: globTheme.palette.primary.main};
    styles["code"] = {wordBreak:"break-all"};
    styles["p"] = {fontSize: "20px"};

    function replace(node) {
      
      if (node.type === 'tag' && node.name === "a" && node.attribs.href) {
    
          if (node.attribs.href.substr(0,4) !== 'http')
            return <Link to={node.attribs.href}>{domToReact(node.children, {replace: replace})}</Link>;
          else {
    
            if (user && user.FbUser)
              return <div style={{paddingBottom:"10px"}}>{domToReact(node.children, {replace: replace})} - <br /><a><code>{node.attribs.href}</code></a></div>;
            else
              return <a href={node.attribs.href} target={"_blank"}>{domToReact(node.children, {replace: replace})}</a>;
          };
      }
    
      if (node.type === 'tag' && !['hr', 'span', 'b', 'font', 'i', 'strong', 'div', 'center', 'h2', 'h1', 'ul', 'li', 'p'].includes(node.name)) {
        console.log(node.name);
        return <></>;
      }
    
    }

    if ('dark' == globTheme.palette.mode) {

      //<span style="color: maroon;">info@mova.ua</span>
      __html = __html.replace("<span style='color:maroon'>", '<span style=\'color: ' + globTheme.palette.primary.main + ';\'>');
      // <GlobalStyles styles={{ h1: { color: 'grey' } }} />
      
      styles[".WORD"] = {color: globTheme.palette.primary.main};
      styles[".ENTRY"] = {color: 'white'};
      styles["h4"] = {color: globTheme.palette.primary.main, textTransform: "uppercase !important"};
      styles[".ILLSRC"] = {color: "#bdbdbd"};
      styles["em"] = {color: "#bdbdbd"};
      
    } else {
      styles[".ILLSRC"] = {color: "black"};
      styles["em"] = {color: "#424242"};
    }

    return <><GlobalStyles styles={styles} />
    <Box sx={{padding:"20px", paddingBottom: "0px"}}>
      <Header />
      <Button startIcon={<BrightnessAutoIcon />} onClick={()=>navigate("/")}>
      Шукати нові слова
      </Button>
    {parse(__html, {replace: replace})}
    {/*<font style={{"fontSize":'20px'}}><span dangerouslySetInnerHTML={template} /></font>*/}
    </Box></>
}