import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBHPLI0pVk01jcuKwW4WuN-oZ5vFRZutuk';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps(props) {
  const [value, setValue] = React.useState(props.DefaultValue || null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  React.useEffect(() => {

    setValue(props.DefaultValue || null);

    console.log('props', props);
    console.log('value', value);
  }, [props]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ["(cities)"], componentRestrictions: { country: props.country }, }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const geocodeByPlaceID = (placeId) => {
    // After the component is mounted it is safe to create a new instance of the Geocoder client.
    // That's because at this point the Google Maps JavaScript API has been loaded. Also if we do it
    // before the component is mounted (i.e. in 'componentWillMount()') we won't be safe to render
    // on the server (SSR) as the 'window' object isn't available.
    const geocoder = new window.google.maps.Geocoder()
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          reject(
            new Error(
              `Geocoding query for a place with an ID of '${placeId}' failed - response status: ${status}`,
            ),
          )
  
          return
        }
  
        resolve(results)
      })
    })
  }
  /*
  const handleAutoCompletePlaceSelected = placeId=>{
    if (window.google) {
         const PlacesService = new window.google.maps.places.PlacesService(predictionsRef.current);
         try {
           PlacesService.getDetails(
             {
               placeId,
               fields: ['address_components'],
             },
            place => addSelectedPlaceDetail(place)
           );
         } catch (e) {
           console.error(e);
         }
       }
   }*/

   
  /*
  React.useEffect(() => {
    let newValue = 'Dandenong Вікторія, Австралія';
    setOptions([newValue]);
    setValue(newValue);
  }, []);*/

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Введіть перші літери"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        props.onChange(newValue);

        /* 
        if (newValue && newValue.place_id) {
            console.log('newValue:', newValue.place_id);

            geocodeByPlaceID(newValue.place_id).then((results) => {
                // Add your business logic here. In this case we simply set our state with the coordinates of
                // the selected suggestion...
          
                // Just use the first result in the list to get the geometry coordinates
                console.log('results:', resultss);
                const { geometry } = results[0]
          
                const coordinates = {
                  lat: geometry.location.lat(),
                  lng: geometry.location.lng(),
                }
          
              }).catch((err) => {
                // Handle any errors that occurred when we tried to get geospatial data for a selected
                // suggestion...
            })
        }*/
        }
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Місто або селище"
        color = "edit" fullWidth 
        helperText="Щоб вивчати говірку рідного краю"
        FormHelperTextProps={{sx:{fontSize:"16px"}}} />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}