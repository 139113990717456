import React,{useEffect, useImperativeHandle, useRef} from 'react';

import {NestedMenuItem} from 'mui-nested-menu';
//import {NestedMenuItem} from './templates/NestedMenuItem.js';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExtensionIcon from '@mui/icons-material/Extension';
import CasinoIcon from '@mui/icons-material/Casino';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { useTheme } from '@mui/material/styles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { DarkModeToggle } from '@anatoliygatt/dark-mode-toggle';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import AvatarGroup from '@mui/material/AvatarGroup';
import StartIcon from '@mui/icons-material/Start';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {getColorName} from './game/Colors';
import {getLevelTitle} from './game/NextLevelShow';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const formatDate = (dateString) => {
  // const dateString = '2020-05-14T04:00:00Z'
  // hour: 'numeric', hour12: true
const options = { year: "2-digit", month: "short", day: "numeric"}
return new Date(dateString).toLocaleDateString('uk-UK', options)
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


/*export default function AccountMenu(props) {*/
const AccountMenu = React.forwardRef((props, reff) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const open = Boolean(anchorEl);
  const buttonMenu = useRef();
  const navigate = useNavigate();
  const globTheme = useTheme();

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => {setExpanded(false);setAnchorEl(null);}

  const letsOpen = () => {setExpanded(true);handleClick({currentTarget:buttonMenu.current})};
  const [photos, setPhoto] = React.useState({});

  let user_player = {uid:props.user.uid, color: "#800000" /*'maroon'*/, name: (props.user.game_profile.current && props.user.game_profile.current.show_name) ? props.user.game_profile.current.show_name: props.user.displayName, fb_player_id: props.user.game_profile.current && props.user.game_profile.current.fb_player_id, score: (props.user.game_profile.current) ? props.user.game_profile.current.level: props.user.solo_level.current/*data.current.current_level*/, title: props.user.game_profile.current ? props.user.game_profile.current.title: getLevelTitle(props.user.solo_level.current)}

  useImperativeHandle(reff, () => ({ letsOpen }), [ ])

  React.useEffect(() => {

    let key = props.user.uid;
    props.user.getUserPhotoURL(key, (url)=>{ setPhoto(prev => {prev[key] = url; return structuredClone(prev);})});

    if (props.user.games.current)
      props.user.games.current.forEach((item) => {
        if (item[1].players)
          Object.entries(item[1].players).forEach((item2) => {
            let key = item2[0];
            //console.log('Menu:', item2[index2]);
            props.user.getUserPhotoURL(key, (url)=>{ setPhoto(prev => {prev[key] = url; return structuredClone(prev);})});
          }
          );
      });

  }, [props.user.games.current]);

  //console.log('props.user.games.current', props.user.games.current);
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Меню">
          <IconButton
            ref={buttonMenu}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={(e)=> {return; /*console.log('Onclick2 return;', e); return; *//*if (e.target && e.target.outerText=="Обрати гру") {console.log('Skip!')} else handleClose();*/}}
        PaperProps={{
          elevation: 0,
          sx: {
            maxWidth: '300px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiMenuItem-root': {
              pl:1,
              pr:1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
        <Box display="flex" width="100%" sx={{ justifyContent: 'center',  }}>
        <DarkModeToggle
      mode={globTheme.palette.mode}
      dark="Темно"
      light="Світло"
      size="sm"
      inactiveTrackColor="#e2e8f0"
      inactiveTrackColorOnHover="#f8fafc"
      inactiveTrackColorOnActive="#cbd5e1"
      activeTrackColor="#334155"
      activeTrackColorOnHover="#1e293b"
      activeTrackColorOnActive="#0f172a"
      inactiveThumbColor="#1e293b"
      activeThumbColor="#e2e8f0"
      onChange={(mode) => {
        props.ChangeDarkTheme(mode);handleClose();
      }}
    /></Box>
        </MenuItem>
        <Divider />
        <Accordion disableGutters elevation={0} expanded={expanded} sx={{
    
    minHeight: 15,
    '&$expanded': {
      margin: 0,
    },
    '&.Mui-expanded': {
      minHeight: 15,
      margin : 0,
    }}} >
            <AccordionSummary
              onClick= {() => setExpanded((prev) => !prev)} 
              expandIcon={<ExpandMoreIcon/>}
              sx={{
                padding:0,
                "&.Mui-expanded": {
                  minHeight: 0
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  // margin from https://github.com/mui-org/material-ui/blob/cc0e2ab63e8be9ec4d51a49bfde17ef28fc77b9c/packages/mui-material/src/AccordionSummary/AccordionSummary.js#L64-L64
                  margin: "0px 0"
                }
              }}
            >
          <MenuItem dense>
            <ListItemIcon>
              <VideogameAssetIcon fontSize="small" />
            </ListItemIcon>
            Обрати гру
          </MenuItem>
            </AccordionSummary>
            <AccordionDetails  sx={{ padding: "2px 2px 2px", maxHeight: "300px",  overflowY: "scroll" }}>
           <MenuList dense>
          <MenuItem selected={props.user.uid === props.selectedValue } style={{ paddingLeft:"10px", paddingRight:"10px" }} onClick={()=>{ props.SetselectGameId(props.user.uid);handleClose();}}>
          <Box width="100%" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
            <ListItemText sx={{display:'flex', justifyContent:'flex-start', paddingRight:"20px", justifyContent:"left"}} primary={user_player.title ? <><Typography type="body" fontSize={'18px'}>{user_player.title} </Typography></> : <Typography type="body" fontSize={'18px'}>Самотня гра</Typography>} secondary={""} />  
            <ListItemAvatar sx={{minWidth:0}}>
              <Badge 
              overlap="circular"
              badgeContent={user_player.score} 
              showZero 
              max={10000}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}

                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: user_player.color,
                    color: globTheme.palette.getContrastText(user_player.color)
                  }
                }}
                >
              <Tooltip title={user_player.name} >
              <Avatar
              alt={user_player.name}
              src={/*user_player.photo*/photos[user_player.uid]}>
              </Avatar>
              </Tooltip>
              </Badge>
              </ListItemAvatar>
            </Box>
          </MenuItem>
          {props.user.games.current && props.user.games.current.map((item, index) => (
                <MenuItem selected= {item[0] === props.selectedValue ? true : false} onClick={() => {props.SetselectGameId(item[0]);handleClose()}} key={item[0]}>
                  <Box width="100%" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} >
                  <ListItemText sx={{display:'flex', justifyContent:'flex-start', paddingRight:"20px", justifyContent:"left"}} /*primary={item[1].level ? "Рівень:" + item[1].level : ""}*/ secondary={<Typography type="body" fontSize={'18px'}>{item[1].last_move && formatDate(new Date(1000 * item[1].last_move.seconds))}</Typography>} />{/*{item[0]}*/}  
                  <ListItemAvatar sx={{minWidth:0}}>
                  <AvatarGroup max={6} spacing='medium'>
                  {item[1].players && Object.entries(item[1].players).map((item2, index2) => (
                    <Badge 
                    max={10000}
                    key={"b" + index2}
                    overlap="circular"
                    badgeContent={item2[1].score} 
                    showZero anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
    
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: getColorName(globTheme.palette.mode, item2[1].color),
                          color: globTheme.palette.getContrastText(getColorName(globTheme.palette.mode, item2[1].color))
                        }
                      }}
                      >
                    {<Tooltip title={item2[1].name?item2[1].name:""} key={"t" + index2}>
                    <Avatar key={"a" +index2}
                    alt={item2[1].name}
                    src={/*item2[1].photo*/photos[item2[0]]}>
                    </Avatar>
                    </Tooltip>}
                    </Badge>
                     ))}
                   </AvatarGroup>
                  </ListItemAvatar>
                   </Box>    
                </MenuItem>
              ))}
              </MenuList>
            </AccordionDetails>
          </Accordion>
          <Divider />
           <MenuItem selected={true} onClick={() => {props.CreateNewMultiGame(); handleClose();}}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Нова гра з друзями
            </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/rating")}>
          <ListItemIcon>
            <EmojiEventsIcon fontSize="small" />
          </ListItemIcon>
          Щабель
        </MenuItem>
        <MenuItem onClick={() => navigate("/dict")}>
          <ListItemIcon>
            <AutoStoriesIcon fontSize="small" />
          </ ListItemIcon>
          Словник
        </MenuItem>
        <MenuItem onClick={() => navigate("/dzherela")}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ ListItemIcon>
          Про гру
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/profile")}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Профіль
        </MenuItem>
        {/*
        <MenuItem onClick={() => navigate("/login")} >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Вихід
                    </MenuItem>*/}
      </Menu>
    </React.Fragment>
  );
});

export default AccountMenu;