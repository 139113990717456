import { useNavigate, useParams } from "react-router-dom";
import {useRef, useState,useEffect} from 'react';
import Header from "./Header";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {Link} from "react-router-dom";
import {useUser} from './UserContext';
import Typography from '@mui/material/Typography';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, onSnapshot} from "firebase/firestore"; 
import { runTransaction, transaction, updateDoc, Timestamp, serverTimestamp } from "firebase/firestore";
import { useSnackbar } from 'notistack';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import firebaseApp from './firebase.config';
import { getFirestore } from "firebase/firestore";
import {setUserLastActive} from './UserContext';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Login from "./Login"
import { useTheme } from '@mui/material/styles';

import game_img_dark from './static/img/game_600_600.png';
import game_img_light from './static/img/game_600_600_light.png';

const db = getFirestore(firebaseApp);

export default function Invite() {

    let { gameId, fromId } = useParams();
    const navigate = useNavigate();
    const user = useUser();
    const invitor = useRef();
    const [avatar, setAvatar] = useState();
    const [usr_name, setUsrName] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const globTheme = useTheme();

    if (!gameId || !fromId)  {
        navigate("/");
    }
    
    function switchToGame(id) {

        user.lastGameId.current = id;
        user.lastMultiGameId.current = id;
        navigate('/');
    }

    useEffect(() => {
        if (!fromId || !user)
            return;

        const docRef = doc(db, "profiles", fromId);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                setUsrName(docSnap.data().show_name);
                user.getUserPhotoURL(fromId, (url)=>{setAvatar(url)});
            } 
        });

    }, [user, fromId])

    useEffect(() => {
        if (user && !user.isAnonymous)
            AcceptInvite();
        else
            user.afterLoginRedirect.current = window.location.href;

    }, [user.uid, user.isAnonymous, gameId, fromId]);

    async function AcceptInvite() {

        let userId = user.uid;
        const curTime = serverTimestamp();
       
        const docRef = doc(db, "meta_data_games", gameId);
        //console.log('Before runTransaction');

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(docRef);
                if (!sfDoc.exists()) {
                    //throw "Document does not exist!";
                    return false;
                }
                
                if (undefined !== sfDoc.data().players[userId]) {

                    enqueueSnackbar && enqueueSnackbar(`Ви вже у грі.`, { autoHideDuration: 1550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        
                    switchToGame(gameId);
                    return true;
                };

                // Add new Player to game
                let color = Object.keys(sfDoc.data().players).length;

                let new_data = {"players":
                {
                    [userId]:
                    {
                        name: (user.game_profile.current.show_name) ? user.game_profile.current.show_name: user.displayName, 
                        /*fb_player_id: user.game_profile.current.fb_player_id,*/
                        /*photo: user.FbUser.playerPic,*/ 
                        score: 0, 
                        color: color, 
                        created: curTime, 
                        last_active: curTime, 
                        invitedBy: fromId
                    }
                }}

                //console.log('Before set');
                transaction.set(docRef, new_data, { merge: true });
            });
        
            //console.log("Transaction successfully committed!");
            // setSwitchChecked(true);
            // SetselectGameId(entryPointData.gameId);
            enqueueSnackbar && enqueueSnackbar(`Вітаємо у грі.`, { autoHideDuration: 1550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        
            const docRef2 = doc(db, "logs_user_invite_accept", user.uid);
            setDoc(docRef2, {[fromId]: {[new Date().toJSON()]: {invitedBy: fromId}}}, { merge: true });
            
            setUserLastActive(user.uid, "invite_accept");

            switchToGame(gameId);
            return true;

        } catch (e) {

            enqueueSnackbar && enqueueSnackbar(`Нажаль, виникла помилка. Спробуйте ще пізніше!`, { autoHideDuration: 1550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        
            console.log("Transaction failed: ", e);
            return false;
        }

    }

    /*return (
        <>
        <Box sx={{display: 'flex',justifyContent:"center",  flexDirection: 'column',    paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
          <Header />
          <Alert severity="info">Вітаємо, Вас запрошено у гру! <br/>Знаходь нові українські слова.</Alert>
        </Box>
        {(user && user.isAnonymous) &&
        <><Alert sx={{mt:2}} severity="warning">Щоб грати з друзями та отримати рейтинг, будь ласка, <Button to='/profile' component={Link} color='primary' endIcon={<PersonAddIcon/>}>зареєструйтесь!</Button> </Alert>
        <Box sx={{ display: 'flex',justifyContent:"center",
    alignItems: 'flex-center', flexDirection: 'column', margin: "20px" }}>
        <Typography sx={{fontSize:"20px", 
        display: 'flex',
        justifyContent:"center"
        }} variant='h1' component="h1">
        Або спробуйте гру наодинці!
      </Typography>
        <Button size='large' sx={{ml:2}} endIcon={<PlayArrowIcon />} onClick={()=> navigate('/')}>Грати</Button>
    </Box></>
        }
        {(user && !user.isAnonymous) && 
        <Box sx={{ display: 'flex',justifyContent:"center",
        alignItems: 'flex-center', flexDirection: 'column', margin: "20px" }}>
        <Button size='large' sx={{ml:2}} endIcon={<PlayArrowIcon />} onClick={AcceptInvite}>Грати з друзями</Button>
        </Box>
        }
        </>
        )*/
    return <>
    <Box sx={{display: 'flex',justifyContent:"center",  flexDirection: 'column',    paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
      <Header />
      </Box>
        <Card sx={{ maxWidth: 325, ml:2, pb:2, mb:0 }} elevation={12} >
        <CardHeader
          avatar={
            <Avatar src={avatar} alt={usr_name}>
            </Avatar>
          }
          
          title={"Запрошення від " + usr_name}
          subheader="Як прийшли на трушені груші, то давайте грати!"
        />
        <CardMedia
          component="img"
          height="194"
          image={('dark' == globTheme.palette.mode) ? game_img_dark : game_img_light }
          alt="Paella dish"
         
        />
        <CardContent sx={{p:0, m:0}}>
        <Box sx={{display: 'flex',justifyContent:"center"}}>
            <Login currentUser={user} hidemessage={true}/>
        </Box>
        </CardContent>
        <CardActions disableSpacing>
        </CardActions>
    </Card></>
}