import { FastfoodOutlined } from "@mui/icons-material";
import React from "react";
import Sketch from "react-p5";

let x = 50;
let y = 50;
let width = 350
let height = 350
let depthToShapes = {};

var config;
var curTime;
var TIME_OFFSET = 0;

const MAX_DEPTH = 6;//8;
const FRAME_RATE = 30;//12;


var curReveal = 6;
var curSpeed = 4;
var curDepth = 5;
var already_color = [];
var already_visible = [];

var config_grey = {};
var config_old = {};

// http://archive.bridgesmathart.org/2017/bridges2017-213.pdf
let depth = 0;
let LEFT = true;
let RIGHT = false;
const ratio = 2*Math.sin(Math.PI/10);

let p5;

export default (props) => {

    
	const setup = (p50, canvasParentRef) => {

        p5 = p50;

        depthToShapes = {};
        TIME_OFFSET = 0;

        curReveal = 6;
        curSpeed = 4;
        curDepth = 5;
        already_color = [];
        already_visible = [];

        config_grey = {};
        config_old = {};

        depth = 0;
        LEFT = true;
        RIGHT = false;

		// use parent to render the canvas in this ref
		// (without that p5 will render the canvas outside of your component)
		p5.disableFriendlyErrors = true;
        p5.createCanvas(width, height).parent(canvasParentRef);
        curReveal = getRandomInt(1,10)
        config = {}
        let colorSliders = initializeColorSliders()

        p5.frameRate(FRAME_RATE)

        const center = p5.createVector(width/2,height/2);
        const length = width*2/3;
        const halves = halfShapes(initializeSun(center,length));
        depthToShapes[0] = halves;

        // console.log('halves:', depthToShapes);
	};

	const draw = (p5) => {
		//p5.background(0);
       // p5.background('white');
        p5.translate(-15,-15);
        p5.scale(1.1);
        let percent = props.percent;
        var isAllVisible = 1;

        //clear()
        // 6 console.log(depthSlider.value())
        p5.strokeWeight(1/(2*curDepth))
        curTime = p5.frameCount / FRAME_RATE;
        const shapes = shapesAtDepth(depthToShapes,curDepth)
        const length = shapes.length;
        var is_grey_begin = 0;
      
        if (100 > percent && already_visible.length > percent /100 *shapes.length) 
            is_grey_begin = 1;
      
      
        for (let i = 0; i < length; i++) {
          const shape = shapes[i];
      
          if (is_grey_begin) {
            
            if (-1 != already_color.indexOf(i))
                config = {...config_old}
            else
                config = {...config_grey}
          }
      
          if (isVisible(shape.tip(),curTime-TIME_OFFSET)) {
              shape.render()
      
              if (!is_grey_begin && -1 == already_color.indexOf(i))
                already_color.push(i)
      
              if (-1 == already_visible.indexOf(i))
                already_visible.push(i);
            }
            else {
              isAllVisible = 0;
            }
        }
      
        if (isAllVisible || already_visible.length == shapes.length )
          { 
            p5.noLoop(); 
            /*goto_next();*/    
            // setTimeout(goto_next, 2000);
          }
	};

      function opposite(side) {
        return !side;
      }
      
      function vectorBetween(a,b) {
        return p5.createVector(b.x-a.x, b.y - a.y);
      }

      function midpoint(a,b) {
        return p5.Vector.lerp(a,b,0.5);
      }
      
      function vectorFromMagAndAngle(mag,angle) {
        p5.angleMode(p5.DEGREES)
        const x = mag * p5.cos(angle);
        const y = mag * p5.sin(angle);
        return p5.createVector(x,y)
      }
      
      function getRandomInt(min, max) {
          return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      

function initializeStar(center,length) {
    p5.angleMode(p5.DEGREES);
    const shapes = [];
    for (let i = 1; i <= 5; i++) {
      const angle = (360 / 5) * i;
      const newVec = vectorFromMagAndAngle(length,angle)
      newVec.rotate(angle)
      shapes.push(new Dart(center,newVec));
    }
    return shapes;
  }
  function initializeSun(center,length) {
    p5.angleMode(p5.DEGREES);
    const shapes = [];
    for (let i = 1; i <= 5; i++) {
      const angle = (360 / 5) * i;
      const newVec = vectorFromMagAndAngle(length,angle)
      newVec.rotate(angle)
      shapes.push(new Kite(p5.constructor.Vector.add(center,newVec),p5.constructor.Vector.mult(newVec,-1)));
    }
    return shapes;
  }
  function initializeKing(center,length) {
    p5.angleMode(p5.DEGREES);
  }
  function halfShapes(shapes) {
    const arr = [];
    shapes.forEach( (shape) => {
      arr.push(shape.half(LEFT));
      arr.push(shape.half(RIGHT));
    })
    return arr;
  }
  function shapesAtDepth(map, depth) {

    //if (depth > 0)
    //    console.log(depth, (Boolean(map[depth])), map);
    
    //if (depth < 0)
    //    return false;

    if (Boolean(map[depth])) return map[depth];

    const prevShapes = shapesAtDepth(map,depth-1);
    const newDepth = [];
    prevShapes && prevShapes.forEach( (shape) => {
      shape.subdivide().forEach( (subShape) => {
        newDepth.push(subShape);
      });
    });
    map[depth] = newDepth;
    return newDepth;
  }
  function timeSubmerged(point,func) {
    const newPoint = p5.createVector(point.x/width,point.y/height);
    const fracTime = func(newPoint);
    //console.log(speedSlider.value())
    //return fracTime * speedSlider.value();
    return fracTime * curSpeed;
  }
  function revealCurve(point) {
    var x = point.x;
    var y = point.y;
    // 4 console.log(revealSlider.value())
    return x + y * Math.sin(curReveal * x);
    //return x + y * Math.sin(revealSlider.value() * x);
  }
  function isVisible(point, time=0) {
    return timeSubmerged(point,revealCurve) < time;
  }
  
  function initializeColorSliders() {
    let colorSliders = []
    for (let i = 0; i < 5; i++) {
      let h = 120 + (30 * i)
      let randomInt = getRandomInt(0,100)
      //colorSliders[i] = createSlider(0,100,randomInt)
      //console.log('p5.HSB', p5);
      p5.colorMode(p5.HSB, 20)

      let val = randomInt;//colorSliders[i].value()
      let hs = Math.floor(val/5)
      let b = (val % 5) * 4 + 2
      config[i+1] = p5.color(hs,hs,b)
  
      //colorSliders[i].parent(document.getElementById('color'+String(i+1)))
      /*colorSliders[i].input((change) => {
        colorMode(HSB,20)
        val = colorSliders[i].value()
        hs = Math.floor(val/5)
        b = (val % 5) * 4 + 2
        config[i+1] = color(hs,hs,b)
      })*/
    }
  
    for (let i = 0; i < 5; i++) 
      config_grey[i+1] = p5.color(30);
  
    config_old = {...config}
    return colorSliders
  }
  
  class Kite {
    constructor(point,vector) {
      this.tip = point;
      this.tail = p5.constructor.Vector.add(point,vector);
      this.vector = vector;
      this.center = p5.constructor.Vector.lerp(this.tail,this.tip,ratio);
      this.pointFromLeftSide = this.slowPointFromSide(LEFT);
      this.pointFromRightSide = this.slowPointFromSide(RIGHT);
    }
    half(side) {
      return new HalfKite(this,side);
    }
    slowPointFromSide(side) {
      const copyVec = this.vector.copy();
      p5.angleMode(p5.DEGREES);
      side === RIGHT ? copyVec.rotate(216) : copyVec.rotate(-216);
      return copyVec.add(this.tail);
    }
    pointFromSide(side) {
      return (side === RIGHT ? this.pointFromRightSide : this.pointFromLeftSide);
    }
    pointFromOtherSide(side) {
      return this.pointFromSide(!side);
    }
    render() {
      p5.triangle(this.tip.x,this.tip.y,this.tail.x,this.tail.y,this.pointFromSide(LEFT).x,this.pointFromSide(LEFT).y)
      p5.triangle(this.tip.x,this.tip.y,this.tail.x,this.tail.y,this.pointFromSide(RIGHT).x,this.pointFromSide(RIGHT).y)
    }
  }
  class Dart {
    constructor(point,vector) {
      this.tip = point;
      this.tail = p5.constructor.Vector.add(point,vector);
      this.vector = vector;
      this.pointFromLeftSide = this.slowPointFromSide(LEFT);
      this.pointFromRightSide = this.slowPointFromSide(RIGHT);
    }
    half(side) {
      return new HalfDart(this,side);
    }
    slowPointFromSide(side) {
      const copyVec = this.vector.copy();
      p5.angleMode(p5.DEGREES);
      side === RIGHT ? copyVec.rotate(72) : copyVec.rotate(-72);
      return copyVec.add(this.tail);
    }
    pointFromSide(side) {
      return (side === RIGHT ? this.pointFromRightSide : this.pointFromLeftSide);
    }
    render() {
      p5.triangle(this.tip.x,this.tip.y,this.tail.x,this.tail.y,this.pointFromSide(LEFT).x,this.pointFromSide(LEFT).y)
      p5.triangle(this.tip.x,this.tip.y,this.tail.x,this.tail.y,this.pointFromSide(RIGHT).x,this.pointFromSide(RIGHT).y)
    }
  }
  
  class HalfKite {
    constructor(kite,side,label) {
      this.kite = kite;
      this.side = side;
      this.label = label;
    }
    subdivide() {
      const parentKite = this.kite;
      const babyKite = new Kite(parentKite.center,vectorBetween(parentKite.center,parentKite.pointFromSide(this.side)));
      const dart = new Dart(parentKite.tail,vectorBetween(parentKite.tail,babyKite.pointFromOtherSide(this.side)));
      return [new HalfKite(babyKite,this.side,1), new HalfKite(babyKite,opposite(this.side),2), new HalfDart(dart,opposite(this.side),3)];
    }
    render() {
      let val = "" + config[this.label]
      p5.fill(val)
      const parentKite = this.kite;
      const pointFromSide = parentKite.pointFromSide(this.side);
      p5.triangle(parentKite.tip.x,parentKite.tip.y,pointFromSide.x,pointFromSide.y,parentKite.tail.x,parentKite.tail.y);
    }
    tip() {
      return this.kite.tip;
    }
  }
  class HalfDart {
    constructor(dart,side,label) {
      this.dart = dart;
      this.side = side;
      this.label = label;
    }
    subdivide() {
      const parentDart = this.dart;
      const sidePoint = parentDart.pointFromSide(this.side);
      const babyKite = new Kite(parentDart.tail, p5.constructor.Vector.mult(parentDart.vector,-1))
      const babyDart = new Dart(sidePoint,vectorBetween(sidePoint,babyKite.pointFromSide(this.side)));
      return [new HalfKite(babyKite,this.side,4), new HalfDart(babyDart,this.side,5)]
    }
    render() {
      p5.fill(config[this.label])
      const parentDart = this.dart;
      const pointFromSide = parentDart.pointFromSide(this.side);
      p5.triangle(parentDart.tip.x,parentDart.tip.y,pointFromSide.x,pointFromSide.y,parentDart.tail.x,parentDart.tail.y);
    }
    tip() {
      return this.dart.tip;
    }
  }

  return <Sketch setup={setup} draw={draw} />;
};