import React,{useState,useEffect, useRef, useLayoutEffect, useImperativeHandle} from 'react';
import Square, {getWordScreenshot as square_getWordScreenshot} from "./Square"
import Cairo, {getWordScreenshot as cairo_getWordScreenshot} from "./Cairo"
import Hexagon, {GetWordScreenshot as hexagon_getWordScreenshot} from "./Hexagon"
import Voronoi, {getWordScreenshot as voronoi_getWordScreenshot} from "./Voronoi"

import {shortFilmFont} from '../fontShortFilm';
import { VpnLockRounded } from '@mui/icons-material';
import ReactDOMServer from 'react-dom/server';

function mv_get_node (t, sibling_ordered, size) {
   
    let x,y = (null, null)

    if (size > 0) {
        x = Math.floor(t / size)
        y = t - x * size

        x = size - 1 - x
        y = size - 1 - y
    }
    
    let z = sibling_ordered[t.toString()]
    //return {x: null, y: null, z: z}
    return {x: x, y: y, z: z}
}
//export default funsction CrossPath(props) {

let CrossPath = React.forwardRef((props, reff) => {
    const game_mode = useRef(props.game_mode)
    const gamedata = useRef(props.gamedata)
    const drawObject = useRef();
    const [scaleData, setScaleData] = useState({});

    const Vl = useRef( props.Vl)
    const [drawComponents, setDraw] = useState(null);
    let crosswordOffsetHeight = props.crosswordOffsetHeight;
    //let Vl = props.Vl;
    //let mv_selected_list = props.mv_selected_list;
    let color = props.color;

    useImperativeHandle(reff, () => ({ makeScale, getSVG }), [ ])

    function makeScale(x, y, scale) {
        
        if (props.largeBoard)
            return;
        //console.log('CrossPath makescale1:', x, y, scale, drawObject.current);
        if (!drawObject.current) {
            setScaleData({x:x, y:y, scale:scale});
        }

        if (!drawObject.current)
            return;
        //console.log('CrossPath makescale2:', x, y, scale);
        drawObject.current && drawObject.current.makeScale(x, y, scale, props.largeBoard)
    }

    function getSVG() {
        return {a: drawObject.current, props: props}
    }

    useEffect(() => {
        
        if (!drawObject.current)
            return;
        
        //console.log('CrossPath makescale3:',  drawObject.current, scaleData);
        (undefined!=scaleData.x) &&  drawObject.current.makeScale(scaleData.x, scaleData.y, scaleData.scale, props.largeBoard)


    }, [drawObject.current, props.largeBoard, scaleData]);

    //useLayoutEffect(() => {
    useEffect(() => {
        
        if (undefined == props.Vl) return;
        
        crosswordOffsetHeight = props.crosswordOffsetHeight;
        Vl.current = props.Vl;
        //let mv_selected_list = props.mv_selected_list;
        color = props.color;
        //console.log('props.gamedata', props.gamedata);
        gamedata.current=props.gamedata;
        game_mode.current = props.gamedata.board_type;

        //console.log(props);
        //console.log(props.mv_selected_list, Vl, game_mode.current, color, crosswordOffsetHeight);
        let new_arr = [...props.mv_selected_list]
        
        if (Vl.current[0]!=undefined || gamedata.current.board_type!="square")// || props.from_passed_words==true)
        {
            let t = mv_pi(game_mode.current, gamedata.current, Vl, new_arr, color, "", [], !0, [], crosswordOffsetHeight);
            //console.log(t)
            //console.log('H0')
            setDraw(t);
        }
        else {
            setDraw(null);
        }

    }, [props])

    // console.log('props.gamedata', props.gamedata);
    //return mv_pi(game_mode.current, gamedata, Vl, mv_selected_list, color, "", [], !0, [], crosswordOffsetHeight);
    //console.log('Draw', drawComponents);
   
    return drawComponents;
    /*
    if ('cairo' == props.gamedata) {
        //show_data_cairo(items, false, "", color, n, o, a);
        return <Cairo id_object={props.id_object} is_show_last={props.is_show_last} palette={props.palette} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata.current} items={[...props.mv_selected_list]} color={color}/>;
    }
    else if ('hexagon' == props.gamedata) {
        //show_data_cairo(items, false, "", color, n, o, a);
        return <Hexagon id_object={props.id_object} is_show_last={props.is_show_last} palette={props.palette} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata.current} items={[...props.mv_selected_list]} color={color}/>;
    }
    else if ('voronoi' == props.gamedata) {
        //show_data_cairo(items, false, "", color, n, o, a);
        return <Voronoi id_object={props.id_object} is_show_last={props.is_show_last} palette={props.palette} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata.current} items={[...props.mv_selected_list]} color={color}/>;
    }
    else {
        return drawComponents;
    };*/

    function mv_pi(game_mode, gamedata, Vl, items, color, n, o, a, letters = [], OffsetHeight) {
        
        if ('square' == game_mode) {
            //show_data_cairo(items, false, "", color, n, o, a);
            return <Square extra_pointer={props.extra_pointer} Vl={Vl} OffsetHeight={OffsetHeight} id_object={props.id_object} is_show_last={props.is_show_last} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata} items={items} color={color}/>;
        }

        if ('cairo' == game_mode) {
            //show_data_cairo(items, false, "", color, n, o, a);
            return <Cairo id_object={props.id_object} is_show_last={props.is_show_last} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata} items={items} color={color}/>;
        }
        if ('hexagon' == game_mode) {
            //show_data_cairo(items, false, "", color, n, o, a);
            //console.log('HERRRE-9', color);
            return <Hexagon id_object={props.id_object} is_show_last={props.is_show_last} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata} items={items} color={color}/>;
        }
        if ('voronoi' == game_mode) {
            //show_data_cairo(items, false, "", color, n, o, a);
            return <Voronoi id_object={props.id_object} is_show_last={props.is_show_last} scaleup={props.scaleup} largeBoard={props.largeBoard} ref={drawObject}  gamedata={gamedata} items={items} color={color}/>;
        }
        
        // ref={(el) => {drawObject.current = el;}}
        /*
        if ('hexagon' == game_mode) {
        show_data_hexagon(items, false, "", color, n, o, a);
        return
        }

        if ('voronoi' == game_mode) {
        show_data_voronoi(Jl,items, false, "", color, n, o, a);
        return
        }*/
    }
});

export default CrossPath;