import React,{useState,useEffect, useRef, useLayoutEffect} from 'react';
import { useTheme } from '@mui/material/styles';
//import short_filmsmedium
import '../static/css/shortfilms-medium-webfont.ttf'
const sibling_ordered = ['01', '02', '03', '04', '05', '11','12','13','14','15','16','21','22','23','24','25','26','27','31','32','33','34','35','36','37','38','41','42','43','44','45','46','47','51','52','53','54','55','56','57','58','61','62','63','64','65','66','67','71','72','73','74','75','76', '81', '82', '83', '84', '85']
const sibling = {}

sibling['01'] = ['02', '12', '11']
sibling['02'] = ['03', '13', '12', '01']
sibling['03'] = ['04', '14', '13', '02']
sibling['04'] = ['05', '15', '14', '03']
sibling['05'] = ['16', '15', '04']

sibling['11'] = ['12', '22', '21', '01']
sibling['12'] = ['13', '23', '22', '11', '01', '02']
sibling['13'] = ['14', '24', '23', '12', '02', '03']
sibling['14'] = ['15', '25', '24', '13', '03', '04']
sibling['15'] = ['16', '26', '25', '14', '04', '05']
sibling['16'] = ['27', '26', '15', '05']


sibling['21'] = ['22', '32', '31', '11']
sibling['22'] = ['23', '33', '32', '21', '11', '12']
sibling['23'] = ['24', '34', '33', '22', '12', '13']
sibling['24'] = ['25', '35', '34', '23', '13', '14']
sibling['25'] = ['26', '36', '35', '24', '14', '15']
sibling['26'] = ['27', '37', '36', '25', '15', '16']
sibling['27'] = ['38', '37', '26', '16']

sibling['31'] = ['32', '41', '21']
sibling['32'] = ['33', '42', '41', '31', '21', '22']
sibling['33'] = ['34', '43', '42', '32', '22', '23']
sibling['34'] = ['35', '44', '43', '33', '23', '24']
sibling['35'] = ['36', '45', '44', '34', '24', '25']
sibling['36'] = ['37', '46', '45', '35', '25', '26']
sibling['37'] = ['38', '47', '46', '36', '26', '27']
sibling['38'] = ['47', '37', '27']

sibling['41'] = ['42', '52', '51', '31', '32']
sibling['42'] = ['43', '53', '52', '41', '32', '33']
sibling['43'] = ['44', '54', '53', '42', '33', '34']
sibling['44'] = ['45', '55', '54', '43', '34', '35']
sibling['45'] = ['46', '56', '55', '44', '35', '36']
sibling['46'] = ['47', '57', '56', '45', '36', '37']
sibling['47'] = ['58', '57', '46', '37', '38']

sibling['51'] = ['52', '61', '41']
sibling['52'] = ['53', '62', '61', '51', '41', '42']
sibling['53'] = ['54', '63', '62', '52', '42', '43']
sibling['54'] = ['55', '64', '63', '53', '43', '44']
sibling['55'] = ['56', '65', '64', '54', '44', '45']
sibling['56'] = ['57', '66', '65', '55', '45', '46']
sibling['57'] = ['58', '67', '66', '56', '46', '47']
sibling['58'] = ['67', '57', '47']

sibling['61'] = ['62', '52', '51', '71']
sibling['62'] = ['63', '53', '52', '61', '71', '72']
sibling['63'] = ['64', '54', '53', '62', '72', '73']
sibling['64'] = ['65', '55', '54', '63', '73', '74']
sibling['65'] = ['66', '56', '55', '64', '74', '75']
sibling['66'] = ['67', '57', '56', '65', '75', '76']
sibling['67'] = ['58', '57', '66', '76']

sibling['71'] = ['72', '62', '61', '81']
sibling['72'] = ['73', '63', '62', '71', '81', '82']
sibling['73'] = ['74', '64', '63', '72', '82', '83']
sibling['74'] = ['75', '65', '64', '73', '83', '84']
sibling['75'] = ['76', '66', '65', '74', '84', '85']
sibling['76'] = ['67', '66', '75', '85']

sibling['81'] = ['82', '72', '71']
sibling['82'] = ['83', '73', '72', '81']
sibling['83'] = ['84', '74', '73', '82']
sibling['84'] = ['85', '75', '74', '83']
sibling['85'] = ['76', '75', '84']

export const hexagonConfig = {sibling_ordered:sibling_ordered, sibling:sibling}

export function GetWordScreenshot(props) {
    
}

let Hexagon = React.forwardRef((props, reff) => {
    const [draw_object, setDrawObject] = useState(null)
    const [level, SetLevel] = useState()
    //let lens = Object.keys(sibling).length;
    //console.log(sibling)
    //console.log(sibling.length)
    const hexagonSVG = useRef();
    const gamedata = useRef(props.gamedata)
    const Vl = useRef(props.Vl)
    const letterVl = useRef(props.letterVl)
    const items = useRef(props.items)
    const is_first_init = useRef(props.init)
    const color = useRef(props.color)

    const globTheme = useTheme();
    let words_string;
    
    useEffect(() => {
        gamedata.current = props.gamedata
    }, [props.gamedata]);

    useLayoutEffect(() => {

        SetLevel(props.level)

        let data = gamedata.current;
        words_string = data.level_longstring;
        
        let mv_selected_list;
        items.current = props.items;

         if (is_first_init.current) {
            mv_selected_list = [...sibling_ordered]
         } else {
            mv_selected_list = items.current
         }
         
         setDrawObject(show_data_hexagon(Vl, letterVl, props.SelectionRef, words_string, mv_selected_list, is_first_init.current, props.id_object, props.color, 'hilite-mark2', {}, ''))
    }, [gamedata, props.palettemode, props.level, props.items, is_first_init, props.color, Vl, props.SelectionRef])

   return draw_object;

   //function show_data_hexagon(Jl, show_list, is_first_init, id_object, color, n, o, r) {
     function show_data_hexagon(Vl, letterVl, SelectionRef, words_string, show_list, is_first_init, id_object, color, n, o, r) {   
        let letters = []
        
        if (is_first_init)
            letters = words_string
        else
            letters = []

        //let f = $('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 305" style="width:368px; height:368px" transform="translate(2,10)scale(1)" style="position:absolute;opacity:.6;"></svg>')

        // #876fc1
        /*
        if (n)
            f.addClass(n)

        if (o)
            for (var v in o) f.attr(v, o[v]);
        
        //if (r)
        //      f.css("background-color", color)
        f.css("position", 'absolute')
        
        f.css("top", '0px')
        f.css("left", '0px')*/
        
        //if (is_first_init)
        //    f.css("opacity", '.6')


        /*if (!is_first_init)
            f.css("z-index", '0')*/

        //$(".crossword", Jl).prepend(f) 

        let scale = '0.144'
        
        let c_number = '#876fc1'
        let c_number_w = 0
        let c_size = 15

        let stroke_width = 1
        let stroke_color = 'black'
        let angle = "-45"
        let ppart = ""

        if (color && color.substring(0,5) == 'url(#' && n) {
            
            //stroke_width = 4
            //color = 'white'
            c_number_w = n.substring(n.length - 2)
            if ("-" == c_number_w.substring(0,1) )
                c_number_w = n.substring(n.length - 1)
            
            let color_list = ["red", "green", "grey", "blue", 'orange']

            c_number_w = parseInt(c_number_w)
            c_number = color_list[c_number_w % color_list.length]

            if (c_number_w >= color_list.length)
                //angle = "90"
                ppart = "2"

            if (c_number_w >= 2*color_list.length)
                angle = "90"

            if (c_number_w >= 3*color_list.length)
                angle = "45"

        }

        if (undefined == c_number) 
            c_number = 'black'

        if (c_number && color == 'url(#secondPattern)')
            color = 'url(#secondPattern'+ ppart + c_number + '_' + c_size + '_' + angle + ')'

        let html = '<defs><pattern id="secondPattern'+ c_number + '_' + c_size + '_' + angle +'" patternUnits="userSpaceOnUse" width="19.5" height="9.5" patternTransform="rotate('+ angle +')"><line x1="0" y="0" x2="0" y2="9.5" stroke="'+ c_number + '" stroke-width="2" /></pattern><pattern id="secondPattern2'+ c_number + '_' + c_size + '_' + angle +'" x="8" y="8" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="' + c_size + '" y="' + c_size + '" width="5" height="5" fill= "'+ c_number + '" /></pattern></defs>';
        let htmls = [];
        
        let gradient = <></>
        if (!is_first_init && props.is_show_last)
            gradient = <><pattern id="grad2" width="30" height="10" 
            patternTransform="rotate(130)" patternUnits="userSpaceOnUse">
                <rect width="100%" height="100%" fill={color}></rect>
                <line x1="5" x2="5" y2="10" style={{stroke:"grey", strokeWidth:3}} />
                
           </pattern>
           </>

        var i = 0;
        //console.log(show_list)
        for (var index in show_list) {
            
            //console.log(index)
            let item;
            if (-1 != Object.keys(show_list[index]).indexOf('z'))
                item = show_list[index].z.toString();
            else
                item = show_list[index].toString()

            let col = parseInt(item[0]);
            let row = parseInt(item[1]);
            let frm = parseInt(item[2]);

            frm = 1
            let path_style = 'style=""';
            //if (1 == frm)  {transform = 'rotate(90)';  letter_shift = 'x="140" y="210"'}
            //if (2 == frm) {transform = 'rotate(180)'; letter_shift = 'x="100" y="140"'}
            //if (3 == frm) {transform = 'rotate(-90)'; letter_shift = 'x="170" y="110"';path_style = 'style="width:36px; height:45px"';}
            /*if (4 == frm)*/
            let transform = ''; 
            let letter_shift = 'x="155" y="160"'
            let hh = 37.45
            
            let shift_x_delta = 34; 
            let shift_y_delta = 0;

            if (1 == col || 7 == col) {
                shift_y_delta = hh;
            }


            if (0 == col % 2) {
                shift_y_delta = hh / 2;
            }

            if (0 == col || 8 == col) {
                shift_y_delta = 1.5*hh;
            }
            /*
            if (0 == col % 2) {
                //shift_x_delta = 37.8; shift_y_delta = 0;
            }

            if (1 == col % 2) {
                shift_x_delta = 0; shift_y_delta = 0;
            }
            */
            
            let shift_x = 0 + (hh  - 5.)* (col -1);
            let shift_y = 0  + (hh - 0) * (row -1);
            
            shift_x = shift_x + shift_x_delta
            shift_y = shift_y + shift_y_delta 

            
            let text_style={fill: globTheme.palette.board.text, align: "middle",fontSize:"140px", fontFamily:"short_filmsmedium",  textTransform:"uppercase"}

            let letter_box = '';

            /*if ( letters.length > 0 && id_object) {
                var l_count = sibling_ordered.indexOf(item);
                letter_box = <text x="155" y="160"  dominantBaseline={"middle"}  textAnchor={"middle"} style={text_style} className={"letter-in-box"} transform-origin={"center"}>{letters[l_count]}</text>;
            
            } else */if ( letters.length > 0 ) {
                
                letter_box = <text ref={(el) => {letterVl.current['z'+ item] = el;}} x="155" y="160"  dominantBaseline={"middle"}  textAnchor={"middle"} style={text_style} className={"letter-in-box"} transform-origin={"center"}>{letters[i]}</text>;
            }
            
            /*let z_factor = ""
            if (is_first_init)
                z_factor = 'data-z="' + item + '"'*/

            var opts1 = {};
            let z_factor = ""
            if (is_first_init) {
                //z_factor = 'data-z="' + item + '"'
                opts1['data-z'] = item;
                //opts1['backgroundColor'] = "white";
                opts1['ref'] = (el) => {Vl.current[item] = el;};
                opts1['onMouseDown'] = (e) => SelectionRef.current.mv_beginSelectingWord(e, props.largeBoard, globTheme.palette.board.selection);
                opts1['onTouchStart'] = (e) => SelectionRef.current.mv_beginSelectingWord(e, props.largeBoard, globTheme.palette.board.selection);
                opts1['onMouseUp'] = (e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection);
                opts1['onTouchEnd'] = (e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection);
                opts1['onMouseMove'] = (e) => SelectionRef.current.mv_hiliteSelection(e, props.largeBoard, globTheme.palette.board.selection);
                //opts1['onMouseMove'] = {handler: (e) => SelectionRef.current.mv_hiliteSelection(e, props.largeBoard, globTheme.palette.board.selection), passive: true };
                
                opts1['onTouchMove'] = (e) => SelectionRef.current.mv_hiliteSelection(e, props.largeBoard, globTheme.palette.board.selection);
                
            }
            /*
            let str_fill = color;
            //str_fill = ''

            let str_fill_opacity;
            if (is_first_init)
                str_fill_opacity = 0.2
            else
                str_fill_opacity = 0.8*/

            var opts2 = {};
            //let str_fill = 'fill="' + color + '"'
            opts2['fill'] = color;
            opts2['stroke'] = globTheme.palette.board.stroke;
            //"#bdbdbd"; //let stroke_color = '#FFF';
                
                
            if (!is_first_init && props.is_show_last) {
                //opts2['fill'] = 'yellow';
                opts2['fill'] = "url(#grad2)";
            }   
            //str_fill = ''

            let str_fill_opacity = ''
            if (is_first_init)
                //str_fill_opacity = 'filfl-opacity="0.2"'
                opts2['fillOpacity'] = .1
            else
                //str_fill_opacity = 'fill-opacity="0.8"'
                opts2['fillOpacity'] = .8

                    
            //str_fill = ''
            // fill="url(#secondPattern)"

            let newItem = <g key={index} {...opts1} className="box" transform={'translate('+ shift_x +','+ shift_y + ')scale('+scale+')'}>
                <polygon {...opts2}  strokeWidth={stroke_width} points={"300,150 225,280 75,280 0,150 75,20 225,20"}  transform={transform} transform-origin={"center"}/>
                {letter_box}</g>;

            htmls.push(newItem);
            //html = html + newItem;
            i = i + 1
        }

        //f.html(html);
        //$(".crossword", Jl).prepend(f)
        //$("#main_svg").append($circle);

        let style1 = {width: "100%"}
        style1.position="absolute";
        style1.left="0px";
        style1.top="0px";
        
        if (!is_first_init) {
            style1.zIndex = -1;
            //style1.top = 0;
            
        }else {
            //style1.zIndex = -1000;
        };

        let str_scale = "translate(0,0)scale(1)";
        let str_to = "0,0"
        let begin="indefinite"

        let f = <svg {...id_object} xmlns="http://www.w3.org/2000/svg" className={n} viewBox="0 0 305 305" style={style1} >
            <defs>{gradient}</defs>
            <g ref={hexagonSVG}  transform={str_scale}>
                {htmls}
            </g>
            </svg>
        //console.log(f)
        return f
    }

})

export default Hexagon;