import firebaseApp from './firebase.config';
import React,{useState,useRef, useEffect, useTransition} from 'react';
import {setUserLastActive} from './UserContext';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, onSnapshot} from "firebase/firestore"; 
import { runTransaction, transaction, updateDoc, Timestamp, serverTimestamp } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useUser} from './UserContext';
import Header from "./Header";
import AccountMenu from "./AccountMenu";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';
import Skeleton from '@mui/material/Skeleton';
import {getColorName} from './game/Colors';
import { useTheme } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';

import { SnackbarProvider, useSnackbar } from 'notistack';
import {getAdditionalUserInfo} from "firebase/auth"
//import thefirstjs from "./data/the_first_level_100_games.json";
//import thefirstjs from "./data/test.json";

import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import {MOVAbase64Picture} from './game/MovaImg.js';

//import { getDatabase, ref, onValue } from "firebase/database";
import Board from "./game/Board"
import Intro from "./Intro"
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import useSound from 'use-sound';
import onMP3 from  './static/sound/on_7c5db326cc70213.mp3';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west1");
//const database = getDatabase();
// ,
const rgbsMUI = ['#f0f4c3', '#bbdefb', '#b2dfdb', '#ffecb3', '#ffe0b2', '#e1bee7', '#ffccbc', '#d7ccc8', '#cfd8dc', '#c8e6c9', '#d1c4e9']

const audioSwith = new Audio(onMP3);
audioSwith.volume = 0.1;

const formatDate = (dateString) => {
    // const dateString = '2020-05-14T04:00:00Z'
    // hour: 'numeric', hour12: true
  const options = { year: "numeric", month: "long", day: "numeric"}
  return new Date(dateString).toLocaleDateString('uk-UK', options)
}

export default function Game(props) {

    //const [level, setUpLevel] = useState(null);
    const [gamedata, setGameData] = useState({level:-1, tooltips: null, raw: null});
    const [gameId, setGameId] = useState(null);
    const [skeleton_show, setSkeleton] = useState(true);
    const [show_intro, setIntroShow] = useState(false);

    const aMenuRef = useRef();
    const user = useUser();
    const navigate = useNavigate();

    const [switchChecked, setSwitchChecked] = useState(false);
    
    const [getGameTryAgain, setGetGameTryAgain] = useState({gameId:null, time:null});
    const timerGetGameTryAgain = useRef();

    //const muted = useRef(false);
    const [muted_show, setShowMuted] = useState();

    const globTheme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    var fb_link_share = "https://www.mova.ua";
    
    if (window.FBInstant)
        fb_link_share = "https://fb.gg/play/508106471491931";
    
    /*const [onSwitchSound] = useSound(onMP3,
        { volume: 0.1 }
      );*/

    function setIntroOff() {
        setIntroShow(false);
        user.setIntroOff.current = true;
    }

    useEffect (() => {
        if (!user)
            return;

        if (!user.setIntroOff.current && Date.now() - user.metadata.createdAt < 5*60*1000)
            //setIntroShow((prev) => prev === null);
            setIntroShow(true);
        
        // setIntroShow(true);

    }, [user]);

    useEffect(() => {

        if ( user == null) 
            return;

        //console.log('User games:', user.games.current);
        // Run once the first render!
        //getGamesList();

        setShowMuted(user.muted.current);

        if (!user.isAnonymous && user.firstLoginMultiGameRedirect.current) {
            // Redirect to Multigame
            user.firstLoginMultiGameRedirect.current = false;
            setSwitchChecked(true);
           
            return;
        };

        if (null != user.lastGameId.current) {

            switchToGame(user.lastGameId.current)
        } else {

            if (null == user.lastGameId.current) {

                if (user.FbUser && user.FbUser.entryPointData) {
                    
                    // Invite from FB friends
                    AddUser2MultiGame();

                };
            };
        };

    }, [user]);

    function onSwitchSound() {
        audioSwith.play();
    }

    const calcOpenModalFunction = (gid) => {
        console.log('user.showedInviteFriends', user.showedInviteFriends)
        if (undefined === user.showedInviteFriends.current[gid]) {
            user.showedInviteFriends.current[gid] = 1;
            return true;
        }
        return false;
    };

    async function CreateNewMultiGame() {

       if (user.isAnonymous ) {
            
            // TODO: redirect to multigame after Login
            //user.firstLoginMultiGameRedirect.current = true;

            navigate('/druzi');
            return false;
        }

        let noFriendGame = getNoFriendMultiGame();
        if (noFriendGame) {
            switchToGame(noFriendGame);
            return noFriendGame;
        };

        let userId = user.uid;
        
        const curTime = serverTimestamp();
        const metagameRef = collection(db, "meta_data_games");
        
        console.log('game_profile:', user.game_profile, user);
        // rgb(238, 247, 158)
        const doc = {
            'created': curTime, 
            'last_move': curTime, 
            owner: userId, 
            players : 
                {
                    [userId]:
                        {
                            color: 0, 
                            name: (user.game_profile.current && user.game_profile.current.show_name) ? user.game_profile.current.show_name: user.displayName, 
                            fb_player_id: user.game_profile.current && user.game_profile.current.fb_player_id,
                            /*photo: user.photoURL,*/ 
                            last_active: curTime, 
                            score:0 
                        }
                }
            }
        //const { id } = await addDoc(metagameRef, doc);
        
        return await addDoc(metagameRef, doc)
        .then(docRef => {
            console.log(docRef.id);
            console.log("Document has been added successfully");

            //setSwitchChecked(true);
            //SetselectGameId(docRef.id);
            switchToGame(docRef.id);

            return docRef.id;
        })
        .catch(error => {
            console.log(error);
            return false;
        })
    } 

    function AfterSelectClick(id) {

        console.log(' A00 LLLL:', user.uid !== id, user.uid, id);
        //startTransition(() => {
            
            switchToGame(id);
            //user.lastGameId.current = id; 
            //setGameId(id);
            //setSwitchChecked(user.uid !== id);
        //});
        
    }

    async function AddUser2MultiGame() {

        let userId = user.uid;
        const curTime = serverTimestamp();
        const entryPointData = user.FbUser.entryPointData;

        /*
        if (userId == entryPointData.invitedBy) {

            switchToGame(entryPointData.gameId);
            return true;
        };*/

        const docRef = doc(db, "meta_data_games", entryPointData.gameId);
        //console.log('Before runTransaction');

        try {
            await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(docRef);
                if (!sfDoc.exists()) {
                    //throw "Document does not exist!";
                    return false;
                }
                /*
                // Maybe create new context
                let fb_players = [];
                Object.keys(sfDoc.data().players).forEach((x) => {
                    let item = sfDoc.data().players[x];
                    if (item.fb_player_id) {
                        fb_players.push(item.fb_player_id)
                    }
                });

                if (user.game_profile.current.fb_player_id)
                    fb_players.push(user.game_profile.current.fb_player_id);

                // If only 2 FB player
                if (fb_players.length <=2 && !sfDoc.data().fb_context_id && user.FbUser && user.FbUser.contextId) {
                    transaction.set(docRef, {fb_contextId: user.FbUser.contextId}, { merge: true });
                };

                // Check if smth new in player list
                if (fb_players.length > 2 && user.game_profile.current.fb_player_id) {
                    if (window.FBInstant) {
                        try {
                            console.log('OLD Context ID:',  window.FBInstant.context.getID());
                            await window.FBInstant.context.createAsync(fb_players)
                            .then(() => {
                                let fb_contextId = window.FBInstant.context.getID();
                                transaction.set(docRef, {fb_contextId: fb_contextId}, { merge: true });
                            
                                console.log('NEW Context ID:', fb_contextId);

                                var contextPlayers = window.FBInstant.context.getPlayersAsync()
                                .then(function(players) {
                                    console.log('Players from Context:', players.map(function(player) {
                                    return {
                                        id: player.getID(),
                                        name: player.getName(),
                                    }
                                    }));
                                });
                            });
                        }
                        catch (err) {};
                    };
                };
                */
                
                if (undefined !== sfDoc.data().players[userId]) {

                    switchToGame(entryPointData.gameId);
                    return true;
                };

                let fb_contextId = window.FBInstant && window.FBInstant.context.getID();
                
                /*
                if (!sfDoc.data().fb_contextId && fb_contextId) {
                    transaction.set(docRef, {fb_contextId: fb_contextId}, { merge: true });
                }*/
                
                // Add new Player to game
                let color = Object.keys(sfDoc.data().players).length;

                let new_data = {"players":
                {
                    [userId]:
                    {
                        name: (user.game_profile.current.show_name) ? user.game_profile.current.show_name: user.displayName, 
                        fb_player_id: user.game_profile.current.fb_player_id,    
                        /*photo: user.FbUser.playerPic,*/ 
                        score: 0, 
                        color: color, 
                        created: curTime, 
                        last_active: curTime, 
                        invitedBy: entryPointData.invitedBy,
                        fb_contextId: fb_contextId
                    }
                }}

                //console.log('Before set');
                transaction.set(docRef, new_data, { merge: true });
            });
        
            //console.log("Transaction successfully committed!");
            // setSwitchChecked(true);
            // SetselectGameId(entryPointData.gameId);

            switchToGame(entryPointData.gameId);
            return true;

        } catch (e) {
            console.log("Transaction failed: ", e);
            return false;
        }

    }

    async function get_correct_data(user, docRef, gameId, doc) {
        
        let data  = doc.data()
        //console.log('get_correct_data', gameId)
        // console.log(data)
        if (undefined == data || !(doc && doc.exists) || ( data && undefined == data.current_level)) {
            console.log('HERE, need to get_first_game')
            //setDoc(docRef, thefirstjs[Math.floor(100*Math.random())])
            //setDoc(docRef, {})
            try {
                var get_first_game = httpsCallable(functions, 'get_first_game');
                let result = await get_first_game({docId: gameId});
                data = result.data;
            } catch (err) {
                return false;
            }
        }
        
        // || ( data && data.lets_level_up && (data.lets_level_up == data.current_level))
        
        data.currentUser = user;
        data.gameId = gameId;

        /*
        const docRef = doc(db, "current_passed_words", gameId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            var list = docSnap.data();
            if (list.length == data.level_words.length) {
                
                // Finished game, let's create new one
                return await get_new_game(gameId);
            }
        }*/
        return data;
    }
    /*
    const fetchGameData=async()=>{
        const levelRef = ref(database, 'games/' + 'fd9e4e3be44090fef4be2025e400d81e' + '/current_level');
        onValue(levelRef, (snapshot) => {
            setUpLevel(snapshot.val())
        });
    }*/
    /*
    const fetchLevelData=async()=>{

        // "fd9e4e3be44090fef4be2025e400d81e"
        
        let gameId = props.currentUser.uid;
        let userId = props.currentUser.uid;
        const docRef = doc(db, "games", gameId);
        const unsub = onSnapshot(docRef, (doc) => {
            console.log('T1')
            if (!doc.exists()) {
                console.log('T2')
                setDoc(docRef, thefirstjs[Math.floor(100*Math.random())])
                //setDoc(docRef, {})
                return 
            }
            console.log('T3')
            let data = doc.data()
            console.log(data)
            if (data == undefined)
                return 
            console.log('T4')
            data.currentUser = user.current;
            data.gameId = gameId;
            if (undefined == data.current_level) return 
            let tooltips = JSON.parse(data.level_tooltip)
            
            setGameData({tooltips: tooltips, current_level:data.current_level, raw: data, gameId: gameId, userId: userId});
            //console.log("Current data update: ", doc.data());
        }, 
        err => console.log(err));

        return unsub
        
    }
    */
    function getNoFriendMultiGame() {

        let docs = user.games.current;
        let freshSelected = null;
        if (docs) {
            docs.forEach((datas) => {
                // No Friend
                if (datas[1].players && 1 == Object.keys(datas[1].players).length)
                    freshSelected = datas[0];
            });
        };
        return freshSelected;
    }

    async function getSomeMultiGame() {

        let docs = user.games.current;

        if (docs && docs.length > 0) {
            //return docs[0][0];

            let freshSelected = null;
            let FreshTime = 0;
            docs.forEach((datas) => {

                if (FreshTime < datas[2]) {
                    FreshTime = datas[2];
                    freshSelected = datas[0];
                }
            });
            return freshSelected;
        };

        return await CreateNewMultiGame();
    }

    function switchToGame(id) {

        user.lastGameId.current = id;

        if (user.uid != id) {
            user.lastMultiGameId.current = id;
        }
        setSwitchChecked(user.uid != id);
        //SetselectGameId(user.lastGameId.current);
        setGameId(id);
    }

    useEffect(() => {
         // Select game options:
         // 1. First normal Start - Render, Solo 
         // 2. First start-render - but lastGameId Multigame
         // 3. Came from invite url or FB invite - multigame
         // 4. Create new game - multigame
         // 5. Swith mode by switcher
         // 6. Select multi game from list
        
        if (null == user) return;

        if (!switchChecked) {
            // console.log('T3');
            setGameId(user.uid);
            user.lastGameId.current = user.uid; 
            
        } else {

            if (user.lastMultiGameId.current) {
                user.lastGameId.current = user.lastMultiGameId.current;
                //console.log('T4');
                setGameId(user.lastGameId.current);
            } else {
                // Get some multi game
                getSomeMultiGame().then((game_id) => {
                    //console.log('After // Get some multi game', game_id)
                    if (game_id) {

                        user.lastGameId.current = game_id;
                        user.lastMultiGameId.current = game_id;
                        //console.log('S4:');
                        setGameId(game_id);
                    }
                })
            }
        }

    }, [user, switchChecked])

    useEffect(() => {

        clearTimeout(timerGetGameTryAgain.current);

        if (null == gameId) 
            return;

        if (null == getGameTryAgain.gameId)
            return;

        if (getGameTryAgain.gameId != gameId) 
            return;

        try {
            // console.log('UseEffect get_first_game', gameId);
            var get_first_game = httpsCallable(functions, 'get_first_game');
            
            get_first_game({docId: gameId}).then(
                (result) => {
                    if ( !result || !result.data || undefined === result.data.current_level) {
                        
                        let period = 5*1000;
                        timerGetGameTryAgain.current = setTimeout(() => setGetGameTryAgain({gameId: gameId, time: Date.now()}), period);
                    }
                }
            );//.catch(() => {});

        } catch (err) {
            let period = 5*1000;
            timerGetGameTryAgain.current = setTimeout(() => setGetGameTryAgain({gameId: gameId, time: Date.now()}), period);
        }

        return (() => clearTimeout(timerGetGameTryAgain.current));

    }, [gameId, getGameTryAgain]);

    useEffect(() => {
        if (null == gameId) return;

        clearTimeout(timerGetGameTryAgain.current);
        setGetGameTryAgain({gameId:null, time: null})

        setSkeleton(true);
        //fetchGameData();
        
        //user.current = props.currentUser

        //console.log('User:', props.currentUser)
        //let res = fetchLevelData();
        //return res;
        
        //let gameId = "n6t0aL6FY8MAFZDwpsO0v1MkDVQ2";
        // let gameId = "c8c52d976c4d657040944088b0f00b79";
        let userId = user.uid;
        //console.log(gameId)
        const docRef = doc(db, "games", gameId);
        const unsub = onSnapshot(docRef, (docres) => {
            //console.log('on Game Snapshot:', gameId);

            if (gameId == userId)
                setUserLastActive(user.uid, 'game', gameId);
            else
                setUserLastActive(user.uid, 'multi_game', gameId);
            /*
            if (!doc.exists()) {
                //setDoc(docRef, thefirstjs[Math.floor(100*Math.random())])
                setDoc(docRef, {})
                return 
            }
            let data = doc.data()
            if (data == undefined)
                return 
            */
            get_correct_data(user, docRef, gameId, docres)
            .then(
                (data) => {
                    // console.log('data1:', data);
                    // console.log('after');
                    // data.currentUser = user;
                    // data.gameId = gameId;
                    // console.log('Current Level:', data.current_level);
                    if (undefined === data.current_level) {
                        let period = 5*1000;

                        timerGetGameTryAgain.current = setTimeout(() => setGetGameTryAgain({gameId:gameId, time: Date.now()}), period);
                        return
                    }; 

                    let tooltips = JSON.parse(data.level_tooltip)
                    data.multigame = (user.uid != gameId)
                    data.tooltips = tooltips;
                    
                    if (1 < data.current_level || data.multigame) 
                        setIntroOff();

                    setGameData({tooltips: tooltips, current_level:data.current_level, raw: data, gameId: gameId, userId: userId});
                    setSkeleton(false);
                    
                    // Last level for solo game
                    if (gameId == userId) {
                        user.solo_level.current = data.current_level;
                    }

                    if (gameId == userId)
                        setUserLastActive(user.uid, 'level', data.current_level);
                    else
                        setUserLastActive(user.uid, 'multi_level', data.current_level, {gameId:gameId});
                }
            )
            /*
            const fetchData = async () => {
                // console.log(gameId);
                let data = await get_correct_data(docRef, gameId, docres);
                console.log('after');
                data.currentUser = user;
                data.gameId = gameId;
                if (undefined == data.current_level) return 
                let tooltips = JSON.parse(data.level_tooltip)
                
                setGameData({tooltips: tooltips, current_level:data.current_level, raw: data, gameId: gameId, userId: userId});
                //console.log("Current data update: ", doc.data());
            }
            fetchData().catch(console.error);*/
        }, 
        err => console.log(err));

        return unsub

    }, [gameId])

        
    function ShareLink() {

        if (window.FBInstant)
            window.FBInstant.shareAsync({
                intent: 'REQUEST',
                image: MOVAbase64Picture,
                text: 'Шукай нові українські слова!',
                data: {invitedBy: props.userId, source:'FB'},
                    
            }).then(function() {
                // continue with the game.
            });
    };

    function CopyLink() {

        enqueueSnackbar && enqueueSnackbar(<><Typography>Лінк на гру в буфері обміну! (Cntrl-V)</Typography></>, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'top', horizontal: 'right' },});
            
    }

    if (0 && gamedata.raw === null) 
        return <><Box justifyContent="center" sx={{ minWidth: 275, margin: "30px", marginTop:"60px" }}><Header /><center style={{paddingTop:"100px",marginBottom:"170px"}}><div className="spinner-container">
        <div className="loading-spinner"><div></div></div>
      </div></center></Box></>;

    
    var createGameButton;
    if (switchChecked) {
        createGameButton = <Chip
        label="Обрати гру"
        variant="outlined" 
        sx = {{marginLeft:"20px"}}
      />;
      } else {
        createGameButton = <></>;

        /*
        if (!process.env.REACT_APP_FACEBOOK_MODE) {
            createGameButton = <IconButton
            onClick={() => navigate("/login")} 
            variant="outlined" 
            sx = {{marginLeft:"20px"}}><LoginIcon /></IconButton>;
        };*/
      }
     

    if (!user)
        return <></>;

    return (<>
    {show_intro && <Intro setIntroShow={setIntroOff} user={user}/>}
    {!show_intro && <>
    <Container>
    <CssBaseline />
    
        <Stack direction="row" sx={{margin:'2px'}} spacing={1} alignItems="center" justifyContent="center">
        <AccountMenu ref={aMenuRef} CreateNewMultiGame={CreateNewMultiGame} SetselectGameId = {(value) => {AfterSelectClick(value)}} selectedValue={gameId} user={user} switchChecked={switchChecked} ChangeDarkTheme={(mode)=>{props.ChangeDarkTheme(mode)}}/>
        {<IconButton key="a2" onClick={() => {user.muted.current = !user.muted.current; setShowMuted(user.muted.current);}} >
        {muted_show  ?  <VolumeOffIcon />: <VolumeUpIcon />}
        </IconButton>}
        <Typography sx= {{cursor:"pointer", fontSize:"21px"}} variant="body" onClick={() => { setSwitchChecked(false); if (!user.muted.current) onSwitchSound();}}>Самотність</Typography>
        <Switch checked={switchChecked} onChange={(event) => {setSwitchChecked(event.target.checked); onSwitchSound(); /*if (!user.muted.current) onSwitchSound();*/}} size="small" />
        <Typography sx= {{cursor:"pointer", fontSize:"21px"}} variant="body3" onClick={() => {setSwitchChecked(true);if (!user.muted.current) onSwitchSound();}}>Друзі</Typography>
        {/* 
        <CopyToClipboard style={{paddingLeft:'2px'}} text={fb_link_share}
         >
        <Tooltip title="Лінк на гру"><IconButton variant="text" onClick={CopyLink}><LinkIcon style={{ fontSize: 30 }} /></IconButton></Tooltip>
        </CopyToClipboard>
        */}
        {window.FBInstant && <IconButton style={{alignItems: "center"}} variant="text" sx={{p:0, pt:1, m:0}} onClick={ShareLink}> 
        <SvgIcon inheritViewBox  width="18.224" height="15.639" viewBox="0 0 18.224 15.639">
          <path id="Icon_ionic-ios-share-alt" data-name="Icon ionic-ios-share-alt" d="M22.064,12.569l-6.7-6.451A.338.338,0,0,0,15.1,6c-.209.01-.474.161-.474.391V9.626a.207.207,0,0,1-.171.2C7.8,10.878,5,16.009,4.005,21.337c-.038.21.237.406.365.239,2.429-3.152,5.385-5.21,10.059-5.249a.24.24,0,0,1,.2.235v3.177a.4.4,0,0,0,.688.259l6.742-6.564a.548.548,0,0,0,.166-.411A.667.667,0,0,0,22.064,12.569Z" transform="translate(-4.002 -6)"/>
        </SvgIcon>
        </IconButton>}

        {!window.FBInstant && user.isAnonymous &&
        <Tooltip title="Логін"><IconButton variant="text"  onClick={()=> {navigate('/profile')}}><AccountCircleIcon color="primary" style={{ fontSize: 24 }} /></IconButton></Tooltip>
        }

        </Stack>
    </Container>
    {!skeleton_show ? (
        <Board aMenuRef={aMenuRef} palettemode = {props.palettemode} muted={user.muted}  calcOpenModalFunction = {calcOpenModalFunction}  skeleton_show = {skeleton_show} gamedata={gamedata.raw} level={gamedata.current_level} setSwitchChecked={setSwitchChecked} user={user} CreateNewMultiGame={CreateNewMultiGame}/>
    ) : (
        <><Header />
        <Box justifyContent="center" sx={{ minWidth: 275, margin: "30px" }}>
        <Stack spacing={2}>
          <Skeleton animation="wave"  variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
        </Stack></Box></>
    )}</>}
    </>)

}