import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import './static/css/shortfilms-medium-webfont.ttf'
import './static/css/Narbut_Abetka.ttf'
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import red from '@mui/material/colors/red';

import { HashRouter } from 'react-router-dom';



const tagManagerArgs = {
  gtmId: 'G-MV7MH8ZL2Q'
}

// Pantone / PMS 18-1750 TPG / Viva Magenta / #be3455 Hex Color Code
// https://encycolorpedia.com/be3455


// git config --global --unset credential.helper
// git config --system --unset credential.helper
// git config --global --add --path credential.helper gcloud 
// git push --all google 

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));

/*
root.render(
  <React.StrictMode>
    <HashRouter>
    <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={1}>
      <App />
    </SnackbarProvider>
    </ThemeProvider>
    </HashRouter>
  </React.StrictMode>
);*/

root.render(
    <HashRouter>

      <App />

    </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
