import React,{useState,useEffect, useRef, useLayoutEffect, useImperativeHandle} from 'react';
import CrossPath from "./CrossPath"
import { useTheme } from '@mui/material/styles';
import {useUser} from '../UserContext';
import {ColorSet, blendColors} from './Colors';

const PassedWords = React.forwardRef((props, reff) => {
//export default function PassedWords(props) {

    const [res_CrossPathes, setPasses] = useState(null);
    //const [passed_words, setUpPassedWords] = useState(null);
    //const [passed_words, setWords] = useState(['залізо', 'починочок']);
    //const [passed_words, setUpPassedWords] = useState(props.passed_words);
    //const [offsetHeight, setHeight] = useState(0);
    var crosswordOffsetHeight = useRef(props.offsetHeight);
    const Vl = useRef();
    const map_colors = useRef({});
    const level = useRef();
    const game_mode = useRef();
    const gamedata = useRef();
    const func_updateUsedRaw = useRef();
    const crossPathRef = useRef({});

    const words_reds = JSON.parse(props.gamedata.level_red);
    const words = props.gamedata.level_words;
    const [largeBoard, setLargeBoard] = useState(props.largeBoard );

    var size = props.gamedata.board_size;
    var sibling_ordered = props.sibling_ordered;
    let mv_selected_list = {}
    
    const globTheme = useTheme();
    const user = useUser();

    useImperativeHandle(reff, () => ({ makeScale }), [ ])

    function makeScale(x, y, scale, largeBoard) {
        
        //console.log('PassedWords Crosspath makeScale 000', largeBoard);
        //crossPathRef.current 
        if (largeBoard || !crossPathRef.current)
            return;

        //console.log('PassedWords Crosspath makeScale 111', largeBoard);
        for (const [key, value] of Object.entries(crossPathRef.current)) {
            value && value.makeScale(x, y, scale)
          }
          
        //&& crossPathRef.current.makeScale(x, y, scale)
        //console.log('PassedWords Crosspath makeScale');
    }

    
    useEffect(() => {
        map_colors.current = {}
    }, [props.level]);

    useEffect(() => {

        setLargeBoard(props.largeBoard );
        //if (Vl.current.length === 0) return ;
        if (null == props.passed_words) return;
        
        Vl.current = props.Vl
        level.current = props.level
        game_mode.current = props.gamedata.board_type;
        gamedata.current = props.gamedata;
       

        crosswordOffsetHeight.current = props.offsetHeight
        //setUpPassedWords(props.passed_words);
        func_updateUsedRaw.current = props.updateUsedRaw;

        let res = []
        //props.passed_words.forEach(
        //    function(word){
        
        //console.log('passed_words', props.passed_words)
        for (let word in props.passed_words) {
            let i = props.gamedata.level_words.indexOf(word)
            // console.log('I:', i, word, props.gamedata.level_words);
            // if (-1 === i) return;
            if (-1 === i) continue;

            mv_selected_list[i] = []
            for (var j=0; j < words_reds[i].length; j++) {
                let t = words_reds[i][j]
                let obj = mv_get_node(t, props.sibling_ordered);
                mv_selected_list[i].push(obj)
            }  

            func_updateUsedRaw.current(mv_selected_list[i]);
            // червоний rgb(255, 201, 226)
            
            //console.log(mv_selected_list)
            let uid = props.passed_words[word];
            let palette = globTheme.palette.mode;

            let color = get_user_color( props.gamedata.multigame , word, uid, props.players, props.level, i, palette);
            let is_show_last = false;
            if ( props.gamedata.multigame && word == user.lastWordShort.current)
                is_show_last = true;

            //console.log('SET PPP1:', word);
            res.push(<CrossPath is_show_last={is_show_last} palette={globTheme.palette.mode} ref={(el) => {crossPathRef.current['passed_' + i] = el}} largeBoard={props.largeBoard} from_passed_words={true} gamedata={gamedata.current} key={'passed_' + i} Vl={Vl.current} color={color} mv_selected_list={mv_selected_list[i]} crosswordOffsetHeight = {crosswordOffsetHeight.current} game_mode = {game_mode.current} level={level.current}/>)
        }

        setPasses(res)
        //console.log('SET PPP2:', res);
    }, [props]);//.passed_words, props.offsetHeight, props.level]);

    return res_CrossPathes
    
    function get_user_color(multigame, word, uid, players, level, iWordNumber, palette) {
        
        //if ( multigame && word == user.lastWordShort.current)
        //    return globTheme.palette.board.justselect;

        if (multigame && players && players[uid] && players[uid].color )
        {
            /*if ( multigame && word == user.lastWordShort.current)
                return blendColors(players[uid].color, '#000', 0.3);*/

            return players[uid].color;
        }

        let iCount = (2*level + 3*iWordNumber) % 10;
        return ColorSet[palette][iCount];
        /*
        let colorset = {
            light: ["#b2ebf2", "#ffe082", "#b39ddb","#9ccc65", "#dce775",
            "#9fa8da", "#f48fb1", "#ffb74d", "#bcaaa4", "#81d4fa",  "#4db6ac"],
            dark: ["#00bcd4", "#ffd54f", "#7e57c2","#8bc34a", "#9e9d24", 
            "#3f51b5", "#ad1457", "#ff9800", "#5d4037", "#0288d1",  "#00695c"]
        }*/
       

        return ColorSet[globTheme.palette.mode][Math.floor(Math.random()*10)]
        if (players && players[uid] && undefined!=players[uid].color && 
            globTheme.palette.gamer[players[uid].color])
            return globTheme.palette.gamer[players[uid].color];
            //  return players[uid].color;

        return "#26c3c7"; //"#2196f3"; //"#4caf50"; //"#ffb74d"; //"#3f51b5";
        if (players && players[uid] && players[uid].color )
            return players[uid].color;

        let rgbs = ['#B4EBFD', 'rgb(229, 246, 253)', 'rgb(102, 126, 221)','rgb(117, 201, 229)',
                        'rgb(127, 255, 206)',
                        'rgb(144, 244, 147)',
                        'rgb(162, 249, 225)',
                        'rgb(165, 249, 142)',
                        
                        'rgb(177, 231, 249)',
                        'rgb(174, 187, 242)',
                        'rgb(178, 255, 226)',
                        'rgb(255, 201, 226)',
                        'rgb(252, 228, 169)',
                        'rgb(184, 183, 255)',
                        'rgb(186, 249, 159)',

                        'rgb(232, 204, 255)',
                        'rgb(244, 205, 149)',
                        'rgb(247, 243, 145)',
                        'rgb(252, 128, 141)',
                        
                        'rgb(255, 201, 226)'];

        if (undefined!=map_colors.current[uid])
            return map_colors.current[uid]

        if ("" == uid)
            return "rgb(238, 247, 158)"

        for (let x in rgbs) {
            let color = rgbs[x]

            for (let y in map_colors.current) {
                if (map_colors.current[y] == color) {
                    color = ""
                    break;
                }
            }

            if ("" != color) {
                map_colors.current[uid] = color;
                return color;
            }
        }

        return "rgb(238, 247, 158)";
        
    }
    function mv_get_node (t, sibling_ordered) {
   
        let x,y = (null, null)

        if (size > 0) {
            x = Math.floor(t / size)
            y = t - x * size

            x = size - 1 - x
            y = size - 1 - y
        }
        
        let z = sibling_ordered[t.toString()]
        //return {x: null, y: null, z: z}
        return {x: x, y: y, z: z}
   }
})

export default  PassedWords;