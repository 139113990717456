import * as React from 'react';
import {useRef, useState,useEffect} from 'react';
import { doc, getDoc } from "firebase/firestore";

import {useUser} from './UserContext';

import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Header from "./Header";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SlowMotionVideo from '@mui/icons-material/SlowMotionVideo';
import ListIcon from '@mui/icons-material/List';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import SettingsIcon from '@mui/icons-material/Settings';

import { useTheme } from '@mui/material/styles';
import {useParams, Link, useNavigate, useLocation} from "react-router-dom";
import { Typography } from '@mui/material';

import firebaseApp from './firebase.config';
import {serverTimestamp, getFirestore, arrayUnion, documentId } from 'firebase/firestore';
import { collection, getDocs, onSnapshot} from "firebase/firestore"; 
import { query, where, orderBy, limit } from "firebase/firestore"; 
import LinearProgress from '@mui/material/LinearProgress';

import {setUserLastActive} from './UserContext';
import Login from "./Login";

const db = getFirestore(firebaseApp);

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 0, paddingLeft: 0}}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="Перша сторінка"
          sx={{p:0.5}}
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="попередня сторінка"
          sx={{p:0.5}}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="наступна сторінка"
          sx={{p:0.5}}
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="остання сторінка"
          sx={{p:0.5}}
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      //backgroundColor: ('dark'==theme.palette.mode) ? '#202124'/*theme.palette.common.black*/ : '#434343',
      //color: theme.palette.common.white,
      backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 20,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&.MuiTableRow-root:hover':{
      backgroundColor: ('light'==theme.palette.mode) ? 'lightgrey' : 'grey' ,
    },
  }));

export default function Rating() {

    const [page, setPage] = React.useState(0);
    const [rows, SetRows] = useState([]);
    const [rows_friends, SetRowsFriends] = useState([]);
    const [rows_friends_ids, SetRowsFriendsIDS] = useState([]);
    
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [photos, setPhoto] = useState({});

    const user = useUser();
    let navigate = useNavigate();
    const globTheme = useTheme();

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function createData(name, level, region, avatar, position, uid) {
        if (region && region.name)
          region = region.name;

        return { name, level, region, avatar, position, uid };
      }
      
    useEffect(() =>{

      if (!user)
        return;

      setUserLastActive(user.uid, 'rating');
      
      // Get all friends IDs s
      return onSnapshot(query(collection(db, "meta_data_games"), orderBy("players." + user.uid), limit(150)), {}, (querySnapshot) => {
        let rows = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();
            for (var key in data.players) {
              rows.push(key);
          };
        });
        
        // For future , merge IDS with Facebook friends_list
        SetRowsFriendsIDS([...new Set(rows)]);
      },
      error => {
          console.log('AAA',error)
      })

    }, [user]);


    useEffect(() =>{

        // Not only me
        if (rows_friends_ids.length < 2)
          return;

       //console.log('FF:', rows_friends_ids);
        // Friends
        return onSnapshot(query(collection(db, "profiles"), orderBy(documentId()), where(documentId(), "in", rows_friends_ids), limit(100)), {}, (querySnapshot) => {

            let rows = [];
            let position = 0;
            querySnapshot.forEach( (doc) => {
                let data = doc.data();
                //position++;
                user.getUserPhotoURL(doc.id, (url)=>{setPhoto(prev => {prev[doc.id] = url; return structuredClone(prev);})});

                let picture_url = '';// 
                let show_region = '';

                /*
                if (data.facebook && data.facebook.playerPic)
                    picture_url = data.facebook.playerPic;

                if (!picture_url && data.google && data.google.picture_url)
                    picture_url = data.google.picture_url;*/

                let show_name = data.show_name;

                if (!show_name && data["facebook.com"] && data["facebook.com"].playerName)
                  show_name = data["facebook.com"].playerName;

                if (!show_name && data.google && data.google.full_name)
                  show_name = data.google.full_name;

                if (!show_name && data.google && data.google.displayName)
                  show_name = data.google.displayName;

                show_region = data.show_region;
                if (!show_region && data.geo_ip) {
                  show_region = data.geo_ip;
                }
                //console.log(doc.id);
                rows.push(createData(show_name, data.level, show_region, picture_url, position, doc.id))
            });
            
            // Its a bug https://stackoverflow.com/questions/61049330/firestore-can-we-not-use-orderby-on-a-field-filtered-with-wherein
            // Then - have to sort and set position

            rows.sort( (a,b) => b.level - a.level );
            position = 0;
            rows.map((row) => {
              row.position = (++position);
            });

            SetRowsFriends(rows);
        },
        error => {
            console.log('BBB',error)
        });
    }, [rows_friends_ids]);

    useEffect(() =>{

        return onSnapshot(query(collection(db, "profiles"), orderBy("level", "desc"), limit(100)), {},  (querySnapshot) => {
            let rows = [];
            let position = 0;
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                position++;

                user.getUserPhotoURL(doc.id, (url)=>{ setPhoto(prev => {prev[doc.id] = url; return structuredClone(prev);})});
                let picture_url = '';//await user.getUserPhotoURL(doc.id);
                let show_region = '';

                /*
                if (data.facebook && data.facebook.playerPic)
                    picture_url = data.facebook.playerPic;

                if (!picture_url && data.google && data.google.picture_url)
                    picture_url = data.google.picture_url;*/

                let show_name = data.show_name;

                if (!show_name && data.facebook && data.facebook.playerName)
                  show_name = data.facebook.playerName;

                if (!show_name && data.google && data.google.full_name)
                  show_name = data.google.full_name;

                show_region = data.show_region;
                if (!show_region && data.geo_ip) {
                  show_region = data.geo_ip;
                }
                //console.log(data);
                rows.push(createData(show_name, data.level, show_region, picture_url, position, doc.id))
            });
            SetRows(rows);

            //console.log('ROWS:', rows);
        });
    }, []);

  return (
    <>
    <Box sx={{paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
      <Header />
        <Button startIcon={<BrightnessAutoIcon />} onClick={()=>navigate("/")}>
        Грати</Button>
        <Button startIcon={<ListIcon />} onClick={() => navigate('/dict') }>
        Словник</Button>
    </Box>
    {(user && user.isAnonymous) &&
    <Login message="Щоб грати з друзями та отримати рейтинг, будь ласка, зареєструйтесь!" />}
    {/*<Alert severity="warning">Щоб грати з друзями та отримати рейтинг, будь ласка, <Button to='/profile' component={Link} color='primary' endIcon={<PersonAddIcon/>}>зареєструйтесь!</Button> </Alert>*/}
    
    {(1 < rows_friends.length) && 
    <>
    <Box sx={{ display: 'flex',justifyContent:"center",
    alignItems: 'flex-center', flexDirection: 'row', margin: "20px" }}>
      <Typography sx={{fontSize:"30px"}} variant='h2' component="h2">
        Щабель / Друзі
      </Typography>
    </Box>
    <TableContainer component={Paper}>
    <Table aria-label="customized table" size="small" >
      <TableHead>
        <TableRow >
          <StyledTableCell sx={{paddingRight:0}} align="center">#</StyledTableCell>
          <StyledTableCell sx={{paddingLeft:"86px", paddingRight:"0px"}} align="left">Ім'я</StyledTableCell>
          <StyledTableCell  sx={{paddingRight:"0px", paddingLeft:"0px"}} align="center">Регіон</StyledTableCell>
          <StyledTableCell sx={{paddingRight:"10px", paddingLeft:"0px"}} align="right">Рівень</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows_friends.map((row) => (
          <StyledTableRow key={'M' + row.position} hover={true}>
            <StyledTableCell sx={{paddingRight:0}} component="th" scope="row">
              {row.position}
            </StyledTableCell>
            <StyledTableCell align="left"  sx={{paddingRight:"0px", paddingLeft:"10px"}} >
              <Box sx={{ display: 'flex',justifyContent:"flex-start",
        alignItems: 'center', flexDirection: 'row'}} >
            <Avatar
                  alt={row.name}
                  src={/*row.avatar*/photos[row.uid]}
                  sx={{width: 48, height: 48}}
                  />
             { (row.uid != user.uid) ? 
             <Typography sx={{paddingLeft:"20px"}}>{row.name}</Typography>:
             <Button sx={{paddingLeft:"20px"}} color='primary' to="/profile" component={Link} endIcon={<SettingsIcon/>}>{row.name}</Button>
             }
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{paddingRight:"0px", paddingLeft:"0px"}} align="right"><Typography fontSize="14px">{row.region}</Typography></StyledTableCell>
            <StyledTableCell sx={{paddingRight:"10px", paddingLeft:"0px"}} align="right">{row.level}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
    </>
    }

    <Box sx={{ display: 'flex',justifyContent:"center",
          alignItems: 'flex-center', flexDirection: 'row', margin: "20px" }}>
            <Typography sx={{fontSize:"30px"}} variant='h2' component="h2">
              Щабель / Увесь загал
            </Typography>
    </Box>
    {(0==rows.length) && <Box sx={{ width: '100%'}}><LinearProgress /></Box>}
    <TableContainer component={Paper}>
      <Table aria-label="customized table" size="small" >
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{paddingRight:0}} align="center">#</StyledTableCell>
            <StyledTableCell sx={{paddingLeft:"86px", paddingRight:"0px"}} align="left">Ім'я</StyledTableCell>
            <StyledTableCell  sx={{paddingRight:"0px", paddingLeft:"0px"}} align="center">Регіон</StyledTableCell>
            <StyledTableCell sx={{paddingRight:"10px", paddingLeft:"0px"}} align="right">Рівень</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows).map((row) => (
            <StyledTableRow key={'MM' + row.position} hover={true}>
              <StyledTableCell sx={{paddingRight:0}} component="th" scope="row">
                {row.position}
              </StyledTableCell>
              <StyledTableCell align="left"  sx={{paddingRight:"0px", paddingLeft:"10px"}} >
                <Box sx={{ display: 'flex',justifyContent:"flex-start",
          alignItems: 'center', flexDirection: 'row'}} >
              <Avatar
                    alt={row.name}
                    src={photos[row.uid]}
                    sx={{width: 48, height: 48}}
              ></Avatar>
               { (row.uid != user.uid) ? 
             <Typography sx={{paddingLeft:"20px"}}>{row.name}</Typography>:
             <Button sx={{paddingLeft:"20px"}} color='primary' to="/profile" component={Link} endIcon={<SettingsIcon/>}>{row.name}</Button>
             }
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{paddingRight:"0px", paddingLeft:"0px"}} align="right"><Typography fontSize="14px">{row.region}</Typography></StyledTableCell>
              <StyledTableCell sx={{paddingRight:"10px", paddingLeft:"0px"}} align="right">{row.level}</StyledTableCell>
            </StyledTableRow>
          ))}

        {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}

        </TableBody>
        {/*<TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, { label: 'Усі', value: -1 }]}
              colSpan={4}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={rows.length <= 0 ? 0 : page}
              labelRowsPerPage={"На сторінку"}
              sx={{fontSize:"18px"}}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Кількість на сторінку',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
            </TableFooter>*/}
      </Table>
    </TableContainer>
    </>
  );
}