import firebaseApp from './firebase.config';
import Header from "./Header";

import Settings from '@mui/icons-material/Settings';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import {useUser} from './UserContext';
import { SnackbarProvider, useSnackbar } from 'notistack';
import {useParams, Link, useNavigate, useLocation} from "react-router-dom";
import React,{useState,useEffect, useRef} from 'react';
import './static/css/profile.css';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

import { collection, setDoc} from "firebase/firestore"; 
import { doc, deleteDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { onSnapshot} from "firebase/firestore"; 

const db = getFirestore(firebaseApp);

export default function Statistic(props) {

    const [level, setLevel] = React.useState(0);
    const [progress, setProgress] = React.useState({'pryslivya':0, 'prykazky':0, 'synonim':0, 'literatura':0}); 

    const globTheme = useTheme();
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const user = useUser();

    useEffect(() => {
    
        if (!props.uid)
              return;
          
        return onSnapshot(doc(db, "profiles", props.uid), {}, (docum) => {
            let val = docum.data();
            if (!val)
                return;
                
            setLevel(val.level);
        });
  
      }, [props.uid]);

    useEffect(() => {

        if (!level)
            return;

        var progress = {'pryslivya': 0, 'prykazky': 0, 'synonim': 0, 'literatura': 0}

        if (level < 83)
            progress['pryslivya'] = 5*Math.round(20*(level/83))

        else if (level < 161) {
            progress['pryslivya'] = 100
            progress['prykazky'] = 5*Math.round(20*((level-83)/(161-83)))

        } else if (level < 251) {
            progress['pryslivya'] = 100
            progress['prykazky'] = 100
            progress['synonim'] = 5*Math.round(20*((level-161)/(251-161)))

        } else if (level >= 251) {
            progress['pryslivya'] = 100
            progress['prykazky'] = 100
            progress['synonim'] = 100
            progress['literatura'] = 5*Math.round(20*((level-251)/(913-251)))
        };

        setProgress(progress);
        

    }, [level]);

    function InstallLevel(typeset, cur_level) {

        if (! user && user.uid)
            return;

        var new_level;

        if (typeset == 'begin5') {
            new_level = cur_level
        }

        if (typeset == 'begin') {
            new_level = 1
        }

        if (typeset == 'begin2') {
            new_level = 83
        }

        if (typeset == 'begin3') {
            new_level = 161
        }

        if (typeset == 'begin4') {
            new_level = 251
        }

        enqueueSnackbar && enqueueSnackbar(`Пошук нового рівня гри.`, { autoHideDuration: 550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        
        const levelRef = collection(db, "have_to_change_level");
        deleteDoc(doc(db, "have_to_change_level", user.uid))
        .then(() => {
            // console.log('Delete OK');

            setDoc(doc(db, "have_to_change_level", user.uid), {
                set_level: new_level
            })
            .then ( () => {
                enqueueSnackbar && enqueueSnackbar(`Новий рівень завантажується! Гарної гри.`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
                navigate('/');
            })
            .catch(() => {
                enqueueSnackbar && enqueueSnackbar(`Виникла помилка, рівень не встановлено!`, { autoHideDuration: 1550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
            });

        })
        .catch(() => {
            enqueueSnackbar && enqueueSnackbar(`Виникла помилка, рівень не встановлено!`, { autoHideDuration: 1550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        });
    }
    
    if (level)
        return (<>
        <Box sx={{paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
          <Header />
          <Box sx={{ display: 'flex',justifyContent:"center",
    alignItems: 'flex-center', flexDirection: 'row', margin: "0px" }}>
            <Button startIcon={<BrightnessAutoIcon />} onClick={()=>navigate("/")}>
            Грати</Button>
            <Button startIcon={<Settings />} onClick={() => navigate('/profile') }>
            Профіль</Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex',justifyContent:"center",
    alignItems: 'flex-center', flexDirection: 'column', margin: "20px" }}>
        <Typography sx={{fontSize:"30px", 
        display: 'flex',
        justifyContent:"center"
        }} variant='h2' component="h2">
        Макс. рівень: {level}
      </Typography>
    {(level < 912) ? 
        <Button size='small' sx={{ml:2}} endIcon={<PlayArrowIcon />} onClick={()=> InstallLevel("begin5", level)}>Далі грати цей рівень</Button>:''}
    </Box>

<Box sx={{ display: 'flex',justifyContent:"center",
    alignItems: 'flex-center', flexDirection: 'row', margin: "20px" }}>
      <Grid container 
        justifyContent="center"
        alignItems="center"
      rowSpacing={3} columnSpacing={{ xs:5, sm: 1, md: 1 }}>
        <Grid item xs={6}>
<Stack spacing={0.2} justifyContent="center" alignItems="center">
<Box><Typography>Прислів'я</Typography></Box>
<div className={"progress-radial progress-" + progress['pryslivya']}>
  <div className="overlay"><Typography sx={{mt:2}} color="black">{progress.pryslivya}%</Typography></div>
</div>
<Box>{(progress.pryslivya ==100)?
    <Button size='small' startIcon={<ReplayCircleFilledIcon />} onClick={()=>InstallLevel("begin")}>грати знову</Button>:''}
</Box>
</Stack>
</Grid>
        <Grid item xs={6}>
        <Stack spacing={0.2} justifyContent="center" alignItems="center">
<Box><Typography>Приказки</Typography></Box>
<div className={"progress-radial progress-" + progress['prykazky']}>
  <div className="overlay"><Typography sx={{mt:2}} color="black">{progress.prykazky}%</Typography></div>
</div>
<Box>{(progress.prykazky ==100)?
    <Button size='small' startIcon={<ReplayCircleFilledIcon />} onClick={()=>InstallLevel("begin2")}>грати знову</Button>:''}
</Box>
</Stack>
</Grid>

<Grid item xs={6}>
<Stack spacing={0.2} justifyContent="center" alignItems="center">
<Box><Typography>Синоніми</Typography></Box>
<div className={"progress-radial progress-" +progress['synonim']}>
  <div className="overlay"><Typography sx={{mt:2}} color="black">{progress.synonim}%</Typography></div>
</div>
<Box>{(progress.synonim ==100)?
    <Button size='small' startIcon={<ReplayCircleFilledIcon />} onClick={()=>InstallLevel("begin3")}>грати знову</Button>:''}
</Box>
</Stack>
</Grid>

<Grid item xs={6}>
<Stack spacing={0.2} justifyContent="center" alignItems="center">
<Box><Typography>Художня мова</Typography></Box>
<div className={"progress-radial progress-" + progress['literatura']}>
  <div className="overlay"><Typography sx={{mt:2}} color="black">{progress.literatura}%</Typography></div>
</div>
<Box>{(progress.literatura ==100)?
    <Button size='small' startIcon={<ReplayCircleFilledIcon />} onClick={()=> InstallLevel("begin4")}>грати знову</Button>:''}
</Box>
</Stack>
</Grid>
</Grid>
    </Box>
        </>)
}