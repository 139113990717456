import React,{useState,useEffect, useRef, useLayoutEffect, useImperativeHandle} from 'react';
import { useTheme } from '@mui/material/styles';
import img_pointer from '../static/img/icons8-click-67.png';

export function square_get_sibling_ordered(size) {
    
    var sibling_ordered = []
    
    for (var i = 0; i < size*size; i++) {
        sibling_ordered.push(i.toString());
    }

    return sibling_ordered;
}

export function square_get_sibling(size) {
    var sibling = {}
    for (var x = 0; x < size; x++) {
        for (var y = 0; y < size; y++) {
            
            let z =  (size-x-1)*size + (size-y-1) 

            sibling[z] = [];
            if (x > 0) {
                sibling[z].push((size-(x-1)-1)*size + (size-y-1) )
            }

            if (x < size - 1) {
                sibling[z].push((size-(x+1)-1)*size + (size-y-1) )
            }

            if (y > 0) {
                sibling[z].push((size-x-1)*size + (size-(y-1)-1) )
            }

            if (y < size - 1) {
                sibling[z].push((size-x-1)*size + (size-(y+1)-1) )
            }
        }
    }

    return (sibling);
}

export function getWordScreenshot(props) {

}

let Square = React.forwardRef((props, reff) => {

    const globTheme = useTheme();

    useImperativeHandle(reff, () => ({ makeScale }), [ ])
    
    function makeScale(x, y, scale, largeBoard) {

    };

    if (props.color == 'url(#secondPattern)')
        props.color = "#CF9FFF"

    if (props.init)
        return DrawSquare(props.gamedata, props.level, props.onIntroClick, props.Vl, props.letterVl, props.SelectionRef, props.largeBoard);
    
    //let t = mv_pi(game_mode.current, gamedata.current, Vl, new_arr, color, "", [], !0, [], crosswordOffsetHeight);
    return pi(props.Vl, props.items, props.color, "", [], !0, props.OffsetHeight, props.id_object);

    function DrawSquare(data, level, onIntroClick, Vl, letterVl, SelectionRef, largeBoard) {

        let size = data.board_size;
        let words_string = data.level_longstring
    
        var sibling = {}
        var sibling_ordered = []
        for (var i = 0; i < size*size; i++) {
            sibling_ordered.push(i.toString());
        }

        let alls = []
        for (var x = 0; x < size; x++) {
            let line = []
            for (var y = 0; y < size; y++) {
                
                let z =  (size-x-1)*size + (size-y-1) 

                sibling[z] = [];
                if (x > 0) {
                    sibling[z].push((size-(x-1)-1)*size + (size-y-1) )
                }

                if (x < size - 1) {
                    sibling[z].push((size-(x+1)-1)*size + (size-y-1) )
                }

                if (y > 0) {
                    sibling[z].push((size-x-1)*size + (size-(y-1)-1) )
                }

                if (y < size - 1) {
                    sibling[z].push((size-x-1)*size + (size-(y+1)-1) )
                }

                let Letter = words_string[z]
                
                let d = React.createElement( 
                    "div", 
                    { ref: (el) => {Vl.current[z] = el;}, key:"uniqueXY1"+x+"."+y, className:"box letter", onMouseDown:(e) => {if (0===level) onIntroClick(); if (0!==level) SelectionRef.current.mv_beginSelectingWord(e, largeBoard, globTheme.palette.board.selection)}, onTouchStart:(e) => { if (0===level) onIntroClick(); if (0!==level) SelectionRef.current.mv_beginSelectingWord(e, largeBoard, globTheme.palette.board.selection)} , onMouseUp:(e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection) , onTouchEnd:(e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection), onMouseMove:(e) => SelectionRef.current.mv_hiliteSelection(e, largeBoard, globTheme.palette.board.selection), onTouchMove:(e) => SelectionRef.current.mv_hiliteSelection(e, largeBoard, globTheme.palette.board.selection), style: {width: "50px", height: "50px", lineHeight: "50px"}, 'data-x': x, 'data-y': y,  'data-z': z }, 
                    React.createElement("div", {key:"uniqueXY2"+x+"."+y, className: "error-wrong-letter-bg"}, ""), 
                    React.createElement( 
                    "span", 
                    { ref: (el) => {letterVl.current['z'+z] = el;}, key:"uniqueXY3"+x+"."+y, className: "letter-in-box", style:{fontSize: '32.6667px', top: '0px', lineHeight: '50p'} }, 
                    Letter
                    ) 
                )
                alls.push(d)
            }
            
            let d = React.createElement("div", {className:"endRow", key:"uniqueX"+x})
            alls.push(d)
        }
        
        //alls.push(<PassedWords key="PassedWords" Vl={Vl} gamedata={data} passed_words={passed_words} offsetHeight={offsetHeight} sibling_ordered={sibling_ordered}/>)
        //alls.push(<Selection key="Selection" ref={SelectionRef} Vl={Vl} letterVl={letterVl} passed_words={passed_words} sibling={sibling} sibling_ordered = {sibling_ordered} gamedata={data} offsetHeight= {offsetHeight} />)

        return alls;
    
    }

    //pi(Jl, Vl, items, color, n, o, a);
    function pi(t, i, r, n, o, a, OffsetHeight, id_object) {
            
        var f = [];

        if (i && !(i.length < 1))
            for (var s = 0; s < i.length; s++) {
                
                var c = i[s].x, l = i[s].y,  z = i[s].z;
                
                if (1 === i.length) var d = i[s].x, u = i[s].y;
                else {
                    
                    d = i[s + 1].x;
                    u = i[s + 1].y;
                
                    if (d == c) {

                    while (s + 2 < i.length  && (i[s + 2].x == c)) { u = i[s + 2].y; s = s + 1; }
                    }
                    else if (u == l) {
                    
                    while (s + 2 < i.length && (i[s + 2].y == l)) { d = i[s + 2].x; s = s + 1; }
                    }
                }

                var h = vi(t, c, l, z, d, u, a, OffsetHeight);
                //var f = $('<div class="hilite-mark"></div>');
                //if (n && f.addClass(n), o)
                //    for (var v in o) f.attr(v, o[v]);

                let css = {}
                if (r && r.length > 0);
                h.translateY = h.translateY;
                //h.translateX = -1* h.translateX;
                //console.log('H:', h);
                css = {
                    position: "absolute",
                    top:"0px",
                    left:"0px",
                    backgroundColor: r,
                    width: h.width + "px",
                    height: h.height + "px",
                    transformOrigin: h.transformOrigin,
                    transform: "translate(" + h.translateX + "px, " + h.translateY + "px) rotate(" + h.rotate + "deg)"
                }
                
                f.push(React.createElement('div', {key:s, className: "hilite-mark " + n, style: css, ...id_object}))
            
                //$(".crossword").prepend(f);

                if (s === i.length - 2) break;
            }

        // Last Element
       
        if (props.extra_pointer && i && i.length > 0) {

            z = i[i.length-1].z;
            // Show demo pointer
            var css1 = {
                position: "absolute",
                top:"0px",
                left:"0px",
                width: "30px",
                height: "30px",
                transformOrigin: h.transformOrigin,
                transform: "translate(" + (20 + t.current[z].offsetLeft) + "px, " + (20 + t.current[z].offsetTop) + "px)"
            }
            f.push(React.createElement('img', {key:'img'+ s, src: img_pointer, style: css1}))   
        }

        return f;
    }
});




 
function vi(t, i, r, z, n, o, a, OffsetHeight) {

    let dt1 = {
        E: 0,
        SE: 45,
        S: 90,
        SW: 135,
        W: 180,
        NW: 225,
        N: 270,
        NE: 315
    };
    let dt = {
            E: 90,
            //SE: 45,
            S: 180,
            //SW: 135,
            W: 270,
            //NW: 225,
            N: 0,
            //NE: 315
        };
        
    //var outerWidth =55;//$(".box").outerWidth();
    var outerWidth = t.current[0].offsetWidth;
    var offset = {left:0, top: OffsetHeight} ;//$(".crossword").offset();
    //document.getElementById("idcrossword").offsetHeight
    var s, c, l, d, u, h, f, v = {
            rotate: 0,
            translateX: 0,
            translateY: 0,
            width: 0,
            height: 0,
            transformOrigin: 15
        };

    var y = null !== (s = outerWidth) && void 0 !== s ? s : 0;
    var p = Math.sqrt(2 * y * y);
    var b = (u = r, f = o, (h = n) - (d = i) == 0 || f - u == 0 ? Math.max(Math.abs(h - d) + 1, Math.abs(f - u) + 1) : Math.min(Math.abs(h - d) + 1, Math.abs(f - u) + 1));

    // t[i][r].offset()
    var m = null !== (c = {left:t.current[z].offsetLeft, top:t.current[z].offsetTop}) && void 0 !== c ? c : {
            left: 0,
            top: 0
        };
    
    var w = null !== (l = offset) && void 0 !== l ? l : {
            left: 0,
            top: 0
        };
    var g = m.left - w.left;
    var x = m.top - w.top;
    
    v.translateX = g;
    v.translateY = x;
    v.transformOrigin = y / 2;
    v.height = y;
    var k = fi(i, r, n, o, a);
    return v.rotate = dt[k], v.width = "NE" === k || "NW" === k || "SE" === k || "SW" === k ? b * p - (p - y) : b * y, v
}
function fi(e, t, i, r, n) {

    let q = {}
    q.S = "S"; q.N = "N"; q.E = "E"; q.W = "W"; q.SE = "SE"; q.NE = "NE"; q.NW = "NW"; q.SW = "SW"; q.X = "X";

    if (e === i && t < r) return q.N;
    if (e === i && t >= r) return q.S;
    if (n) {
        if (e < i && t === r) return q.E;
        if (e > i && t === r) return q.W;
        if (e < i && t < r) return q.SW;
        if (e < i && t > r) return q.NW;
        if (e > i && t > r) return q.NE;
        if (e > i && t < r) return q.SE
    }
    return e < i && t === r ? q.E : e > i && t === r ? q.W : e < i && t < r ? q.SE : e < i && t > r ? q.NE : e > i && t > r ? q.NW : e > i && t < r ? q.SW : e === i && t === r ? q.N : q.X
}

export default  Square;