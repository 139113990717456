import { getAuth, onAuthStateChanged, inMemoryPersistence, browserLocalPersistence, setPersistence, signInAnonymously, signInWithCustomToken, signInWithCredential } from "firebase/auth";
import firebase from 'firebase/compat/app';

import { FacebookAuthProvider } from "firebase/auth";

export async function fetchAllFBData(FBInstant, setFbUser) {
      
    /*
    {
        "id": "5922861661060827",
        "name": "Володимир",
        "email": "mikhalko@gmail.com",
        "picture": {
            "data": {
                "height": 600,
                "is_silhouette": false,
                "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5922861661060827&gaming_photo_type=unified_picture&ext=1664738887&hash=AeRIdqwjaDWz3F-cXq0",
                "width": 600
            }
        }
    }
    */
    let responseFbUserProfile = await FBInstant.graphApi.requestAsync('/me?fields=id,name,email,picture')
    
    /* {
        "data": [
          {
              "id": "3351779951807631",
              "name": "Anastasia",
              "picture": {
                  "data": {
                      "height": 600,
                      "is_silhouette": false,
                      "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3351779951807631&gaming_photo_type=unified_picture&ext=1664738890&hash=AeTbd9xPWCABVkqXENQ",
                      "width": 600
                  }
              }
          }
      ],
      "paging": {
          "cursors": {
              "before": "MAZDZD",
              "after": "MAZDZD"
          }
      },
      "summary": {
          "total_count": 1
      }
    }*/
    console.log('request to get FB user')
    let responseFbFriends = await FBInstant.graphApi.requestAsync('/me/friends')
    // let responseFbFriends = null

    // mFBASIDA 5922861661060827
    // signature: "rrEaS2WSsgNahyC9gXuA0sgChbdyX4HjBSKIb1ErAes.eyJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImFzaWQiOjU5MjI4NjE2NjEwNjA4MjcsImlzc3VlZF9hdCI6MTY2MjIxMTU2Nn0"
    //let asid = await FBInstant.player.getASIDAsync();
    let res_signed_asid = await FBInstant.player.getSignedASIDAsync();
    let signature = res_signed_asid.getSignature();
    let asid = res_signed_asid.getASID();

    let m_FBUser = {asid: asid, signature: signature, profile: responseFbUserProfile, friends: responseFbFriends }
    m_FBUser.contextId   =  FBInstant.context.getID();
    m_FBUser.contextType =  FBInstant.context.getType();
    m_FBUser.playerName  =  FBInstant.player.getName();
    m_FBUser.playerPic   =  FBInstant.player.getPhoto();
    m_FBUser.playerId    =  FBInstant.player.getID();

    m_FBUser.entryPointData = FBInstant.getEntryPointData();

    //if (fb_valid(asid, signature))
    setFbUser(m_FBUser)
    console.log('just got FB user:', m_FBUser)
    //console.log(FBInstant.getEntryPointData())
  };

function BackEndRequest(url, method, params) {
    return new Promise(function(resolve, reject) {
      const xmlhttp = new window.XMLHttpRequest();
      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
            try {
                const json = JSON.parse(xmlhttp.responseText);
                resolve(json);
            } catch (err) {
                reject(err);
            }
        }
      };
      xmlhttp.onerror = function(err) {
        reject(err);
      };
      xmlhttp.open(method, url, true);
      xmlhttp.setRequestHeader('Content-Type', 'application/json');
      xmlhttp.send(JSON.stringify(params));
    });
  };

async function getMovaCredentials (FbUser) {
    const BACKEND_URL = 'https://europe-west1-mova-game-apps.cloudfunctions.net/';

    const url = BACKEND_URL + '/getID';
    const method = 'POST';
    const payload = FbUser;
    return BackEndRequest(url, method, payload);
}

/*
{
    "asid": "5922861661060827",
    "signature": "PRMoE4yKKNSjyO5yFmm_qVG3T-0FmnY0rfL77rz7sHg.eyJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImFzaWQiOjU5MjI4NjE2NjEwNjA4MjcsImlzc3VlZF9hdCI6MTY2MjIyMTE3MX0",
    "profile": {
        "id": "5922861661060827",
        "name": "Володимир",
        "email": "mikhalko@gmail.com",
        "picture": {
            "data": {
                "height": 600,
                "is_silhouette": false,
                "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5922861661060827&gaming_photo_type=unified_picture&ext=1664813169&hash=AeRQeYbaowRbBTLnuzw",
                "width": 600
            }
        }
    },
    "friends": {
        "data": [
            {
                "id": "3351779951807631",
                "name": "Anastasia",
                "picture": {
                    "data": {
                        "height": 600,
                        "is_silhouette": false,
                        "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3351779951807631&gaming_photo_type=unified_picture&ext=1664813171&hash=AeQ3C41PIvVoWgwz3K8",
                        "width": 600
                    }
                }
            }
        ],
        "paging": {
            "cursors": {
                "before": "MAZDZD",
                "after": "MAZDZD"
            }
        },
        "summary": {
            "total_count": 1
        }
    },
    "contextId": null,
    "contextType": "SOLO",
    "playerName": "Володимир",
    "playerPic": "https://platform-lookaside.fbsbx.com/platform/instantgames/profile_pic.jpg?igpid=7843827979023598&height=256&width=256&ext=1664813165&hash=AeT88flOOMs3U8WS7fE",
    "playerId": "7843827979023598"
}
*/
export async function loginFBToken(FbUser) {

    let FbUser1 = {
        "asid": "5922861661060827",
        "signature": "PRMoE4yKKNSjyO5yFmm_qVG3T-0FmnY0rfL77rz7sHg.eyJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImFzaWQiOjU5MjI4NjE2NjEwNjA4MjcsImlzc3VlZF9hdCI6MTY2MjIyMTE3MX0",
        "profile": {
            "id": "5922861661060827",
            "name": "Володимир",
            "email": "mikhalko@gmail.com",
            "picture": {
                "data": {
                    "height": 600,
                    "is_silhouette": false,
                    "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=5922861661060827&gaming_photo_type=unified_picture&ext=1664813169&hash=AeRQeYbaowRbBTLnuzw",
                    "width": 600
                }
            }
        },
        "friends": {
            "data": [
                {
                    "id": "3351779951807631",
                    "name": "Anastasia",
                    "picture": {
                        "data": {
                            "height": 600,
                            "is_silhouette": false,
                            "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3351779951807631&gaming_photo_type=unified_picture&ext=1664813171&hash=AeQ3C41PIvVoWgwz3K8",
                            "width": 600
                        }
                    }
                }
            ],
            "paging": {
                "cursors": {
                    "before": "MAZDZD",
                    "after": "MAZDZD"
                }
            },
            "summary": {
                "total_count": 1
            }
        },
        "contextId": null,
        "contextType": "SOLO",
        "playerName": "Володимир",
        "playerPic": "https://platform-lookaside.fbsbx.com/platform/instantgames/profile_pic.jpg?igpid=7843827979023598&height=256&width=256&ext=1664813165&hash=AeT88flOOMs3U8WS7fE",
        "playerId": "7843827979023598"
    }

    if (!FbUser) return false;

    const auth = getAuth();
    
    if (auth) {
        const user = auth.currentUser;
        console.log(auth);
        console.log(user);
        
        console.log('BEFORE GGGGGG HERE !!!')
        if (user !== null) {
            console.log(user.providerData); 
            const providerData = user.providerData.filter(pData => pData.providerId=='facebook.com');
            
            if (0< providerData.length) {
                if (providerData[0].uid == 'fb_uid_' + FbUser.asid /*FbUser.profile.id*/) {
                    console.log('Already HERE !!! return');
                    return true;
                }          
            };
        }
    };

    
    if (!FbUser.firebase_login_token) {
    
      console.log('Get token');
      let res = await getMovaCredentials(FbUser) 
      FbUser.firebase_login_token = res.token;
      console.log('Receive token', res.token);

      /*  
      console.log('Send signature:', FbUser.signature);
      
      const credential = firebase.auth.FacebookAuthProvider.credential(
        FbUser.signature
        );

      console.log('Credential is:', credential);

      console.log('Before return signInWithCredential')
      return signInWithCredential(credential);
      */
      
    }
    
    // accessToken:
    // "eyJhbGciOiJSUzI1NiIsImtpZCI6IjUyZmEwZjE2NmJmZjZiODU5N2FjMGFlMDRlNTllZmYxOTk1N2MyYmIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVm9sb2R5bXlyIE1pa2hhbGtvIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BRmRadWNvcmU3THdmVDVMUXVPZmhwc2JzM1d6aGdRLW53S0RsSFpzWXY2ajV6dz1zOTYtYyIsInNrZXkiOiIiLCJmYl9wbGF5ZXJfaWQiOiIiLCJmYl91c2VyX2lkIjoiIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL21vdmEtZ2FtZS1hcHBzIiwiYXVkIjoibW92YS1nYW1lLWFwcHMiLCJhdXRoX3RpbWUiOjE2NjIyMzQwNTAsInVzZXJfaWQiOiJmZDllNGUzYmU0NDA5MGZlZjRiZTIwMjVlNDAwZDgxZSIsInN1YiI6ImZkOWU0ZTNiZTQ0MDkwZmVmNGJlMjAyNWU0MDBkODFlIiwiaWF0IjoxNjYyMjM0MDUwLCJleHAiOjE2NjIyMzc2NTAsImVtYWlsIjoibWlraGFsa29AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTc4ODM2NDg2NjI2MjY2MTgwNjEiXSwiZW1haWwiOlsibWlraGFsa29AZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.CcsAuSisSkLM3nPv1v4SGWJg3Cw3BXcPY8waUucXh0FZhThMb16ulSPUOsrFIjFa1tBeuFgSzZ_OGycewsoKCUosv7_wnsM_886wKVrKU9VxN2k-AYD909UJ5zZNBrJMo037V__Lwxup3uGbL3pt5nU8UVakwZNc_AB3EahYcFAUSW9lyMRLDWow7ZrLBjBIVJefrF38r6qHiGQw5IFEehobIwN4meUznWgdvl3AeojjczCQE4EjggkxOU2v4hRrBjJCo0m_0tQVPE852U3Q0Dl6q99XbADF-sQYp18Jfgh8m49WydyhwKFLdlMUo1LLrpTvqe14ZJvbpFe4sAse_Q"
    // "eyJhbGciOiJSUzI1NiIsImtpZCI6IjUyZmEwZjE2NmJmZjZiODU5N2FjMGFlMDRlNTllZmYxOTk1N2MyYmIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVm9sb2R5bXlyIE1pa2hhbGtvIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BRmRadWNvcmU3THdmVDVMUXVPZmhwc2JzM1d6aGdRLW53S0RsSFpzWXY2ajV6dz1zOTYtYyIsInNrZXkiOiIiLCJmYl9wbGF5ZXJfaWQiOiIiLCJmYl91c2VyX2lkIjoiIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL21vdmEtZ2FtZS1hcHBzIiwiYXVkIjoibW92YS1nYW1lLWFwcHMiLCJhdXRoX3RpbWUiOjE2NjIyMzQwNTAsInVzZXJfaWQiOiJmZDllNGUzYmU0NDA5MGZlZjRiZTIwMjVlNDAwZDgxZSIsInN1YiI6ImZkOWU0ZTNiZTQ0MDkwZmVmNGJlMjAyNWU0MDBkODFlIiwiaWF0IjoxNjYyMjM0MDUwLCJleHAiOjE2NjIyMzc2NTAsImVtYWlsIjoibWlraGFsa29AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTc4ODM2NDg2NjI2MjY2MTgwNjEiXSwiZW1haWwiOlsibWlraGFsa29AZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.CcsAuSisSkLM3nPv1v4SGWJg3Cw3BXcPY8waUucXh0FZhThMb16ulSPUOsrFIjFa1tBeuFgSzZ_OGycewsoKCUosv7_wnsM_886wKVrKU9VxN2k-AYD909UJ5zZNBrJMo037V__Lwxup3uGbL3pt5nU8UVakwZNc_AB3EahYcFAUSW9lyMRLDWow7ZrLBjBIVJefrF38r6qHiGQw5IFEehobIwN4meUznWgdvl3AeojjczCQE4EjggkxOU2v4hRrBjJCo0m_0tQVPE852U3Q0Dl6q99XbADF-sQYp18Jfgh8m49WydyhwKFLdlMUo1LLrpTvqe14ZJvbpFe4sAse_Q"
    
    // Try to login with signInWithCredential
    if (FbUser.firebase_login_token) {
      
        console.log('Before setPersistent')
        //let token = '222'; //FbUser.firebase_login_token
        let token = FbUser.firebase_login_token

        return signInWithCustomToken(auth, token);
        
        /*
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            console.log('Before send token in signInWithCustomToken')
            return signInWithCustomToken(auth, token)
        }).then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // FbUser.firebase_login_credentials
            console.log('FB Token Login:', user)

            // ...
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            // ...
        });*/
            
        //const auth = getAuth();
        // If Credentials Error -> reset it
    }
     console.log('After setPersistent')
  }

  export async function loginSKToken(skey) {

    const auth = getAuth();

    let res = await getMovaCredentials({"skey":skey}) 
    if (res && res.token) {
        setPersistence(auth, browserLocalPersistence)
        .then(() => {
            //console.log('Before send token')
            return signInWithCustomToken(auth, res.token)
        }).then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // FbUser.firebase_login_credentials
            console.log('SK Token Login:', user)

            // ...
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            // ...
        });
    }
  }

  //useCredentials();
  //console.log('mFBASIDA', mFBASIDA)
  
  
  //let token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY2MjE4MzQ0MywiZXhwIjoxNjYyMTg3MDQzLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1odmhvb0Btb3ZhLWdhbWUtYXBwcy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWh2aG9vQG1vdmEtZ2FtZS1hcHBzLmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoic2RkIiwiY2xhaW1zIjp7InNrZXkiOiJzZGQiLCJmYl9wbGF5ZXJfaWQiOiIifX0.BbCmb0P7Gjse7Y9bvd6hL0iAyVyQsI5nsmPDLRw9taxCCl7HefJqZP7ry5y71zyYELorxzziQqxTrMkRf9RjvxF4F006yoMlzasJlkshI0eGHnYvWf6sJr1JA6eJH9ZmX5_reZYMBEm-_sNwIiaY0PDCW2gBDwrJpIzWnIIrhnfnmV_My--0z0umXqi9SCWpXnmChfrDuQ-QFLk4KL2X5b8yD6k8cj2mw120kO4BBc6cGJU1RdLvXv3GDogiJJ6Vj1fXASMdFu3D-RAbeB-ESXNVTA3vGLcMiy2GAwxlZcvwUjhl2odMjYZYvsazbFMcc_axBC0C2w-Bd4zauTTbtw"
  /*
  firebase.auth().signInWithCredential(credential).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    if (errorCode === 'auth/account-exists-with-different-credential') {
      alert('Email already associated with another account.');
      // Handle account linking here, if using.
    } else {
      console.error(error);
    }
  });
  */
  /*
  setPersistence(auth, browserLocalPersistence)
  .then(() => {
    //console.log('Before send token')
    return signInWithCustomToken(auth, token)
  })
    /*.then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user)
      // ...
    })*/
    /*
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage)
      // ...
    });
    */
  /*
  signInAnonymously(auth)
  .then(() => {
    // Signed in..
    console.log('SignIn');
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });*/