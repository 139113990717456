import { useState, useEffect, useRef} from 'react';

export default (props) => {

    const svgRef = useRef(null);

    let svg;
    let grey_mode;
    let w = 368;
    let h = 368;
    let m;
    let config = {
        n: 6,
        d: 71
    };
    let refreshed = 0;
    let settings;
    let currentFIndex = 0;

    let counter = 0;


function setup() {
    //console.clear();
    //svg = document.querySelector("svg");
    m = Math.min(w, h) * 0.45;
    setupQuickSettings();
  }
  
  function setupQuickSettings() {
    //settings = QuickSettings.create(0, 0, "Parameters");
    //settings.addRange("n", 1, 500, config.n, 1, val => config.n = val); 
    //settings.addRange("d", 1, 500, config.d, 1, val => config.d = val);
    //settings.addButton("Random", random); 
  }
  
  function random() {
    config.n = Math.round(Math.random() * 500);
  
    //config.n = 270;
    //settings.setValue("n", config.n);
    config.d = Math.round(Math.random() * 500);
    
    //config.n = 180/2 //90*3/2
  
    // 2, 3, 6, 10
    let n = config.n;
    let d = config.d;
  
    let angle = Math.PI * d / 180;
    let r =  Math.sin(n * angle);
  
    var number = 1e-12;
    if (Math.abs(r) < number) {
  
      config.n = Math.round(Math.random() * 80)
      config.d = Math.round(Math.random() * 70);
      /*config.n = Math.round(Math.random() * 500);
      let angle = Math.PI * d / 180;
      let r =  Math.sin(n * angle);
  
      if (Math.abs(r) < number) 
        config.n = Math.round(Math.random() * 500);*/
    }
    /*
    if (Math.abs(r) < number)
      console.log('AAAAA', number, angle, r, Math.sin(n * angle))
    else
      console.log('BBBB', number, angle, r, Math.sin(n * angle))*/
    //config.d = Math.round(0.35 * 500);
    //settings.setValue("d", config.d);
  
    //console.log(config.n, config.d )
  }
  
  function draw() {
    let group = document.querySelector("#container");
    if (group) {
      group.remove();
    }
  
    currentFIndex++;
    let numberOfF = 4;
  
    var color = currentFIndex / numberOfF * 360;
  
    group = createSvgElement("g");
    group.setAttribute("id", "container");
    group.setAttribute("fill", "none");
    //group.setAttribute("stroke", "white");
    group.setAttribute("stroke", "#888888");
    //group.setAttribute("stroke", "hsl(" + color + ", 100%, 50%)");
    group.setAttribute("stroke-linecap", "round");
    group.setAttribute("stroke-linejoin", "round");
  
    drawSpirograph(group);
  
    //let logo = new Logo(w * 0.9, h * 0.95, "white");
    //logo.draw(group);
  
    svg.appendChild(group);
  
  
    //document.getElementById("counter").style.color = "hsl(" + color + ", 100%, 50%)";
  }
  
  function drawSpirograph(groupElement) {
    let n = config.n;
    let d = config.d;
    
    let points = [];
    let nrOfPoints = 361;
    for(let i = 0; i < nrOfPoints; i++) {
      let angle = Math.PI * i * d / 180;
      let r = m * Math.sin(n * angle);
      let x = -r * Math.cos(angle) + w / 2;
      let y = -r * Math.sin(angle) + h / 2;
      points.push(`${x} ${y}`);
    }
    let path = createSvgElement("path");
    let commands = convertPointsToCommands(points);
    path.setAttribute("d", commands);
    groupElement.appendChild(path);
  }
  
  
  function createSvgElement(elementName) {
    const svgNs = "http://www.w3.org/2000/svg";
    return document.createElementNS(svgNs, elementName);
  }
  
  function convertPointsToCommands(points) {
    let commands = [];
    commands.push(`M ${points[0]}`);
    for (let i = 1; i < points.length; i++) {
      commands.push(`L ${points[i]}`);
    }
    return commands.join(" ");
  }

  useEffect(() => {
    svg = svgRef.current
   
    setup();
    random();
    draw();
  
    let timerId3;
    let timerId1 = setInterval(() => {random();draw();},800);
    
    /*let timerId2 = setInterval(() => {counter++; if (counter<100) document.getElementById("counter").textContent = Math.ceil(percent*counter/100) + " %"; else {clearTimeout(timerId1); clearTimeout(timerId2); if (!refreshed) timerId3 = setTimeout(() => goto_next(),1000);}},60);
  });*/

    setTimeout(() => {grey_mode=2}, 5000);//5000 * props.percent / 100)

    return () => {
            clearTimeout(timerId1);
    }

  }, [])

  return <svg width="368px" height="368px" ref={svgRef} >  <animateTransform
  attributeName="transform"
  attributeType="XML"
  type="rotate"
  from="0"
  to="360"
  dur="9s"
  repeatCount="indefinite"
/></svg>
};
