//import { signInWithGoogle } from './firebase.config';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithCredential, linkWithPopup, signOut, linkWithRedirect } from "firebase/auth";
import React,{useState,useEffect} from 'react';
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {useUser} from './UserContext';
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import GoogleButton from 'react-google-button'

import { getRedirectResult, signInWithRedirect} from "firebase/auth";

//import '../App.css';
//export const auth = getAuth();


//const provider = new GoogleAuthProvider();
//provider.setCustomParameters({ prompt: 'select_account' });

//export const signInWithGoogle = () => signInWithPopup(provider);

export function CheckAfterLoginRedirect(urlLoginRedirect) {

  const auth = getAuth();
  auth.languageCode = 'uk';

  getRedirectResult(auth).then(function(result) {

    if (urlLoginRedirect)
      window.location.href = urlLoginRedirect;

    if (result.credential) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      //document.getElementById('quickstart-oauthtoken').textContent = token;
    } else {
      //document.getElementById('quickstart-oauthtoken').textContent = 'null';
    }
    // The signed-in user info.
    //var user = result.user;
  }).catch(function(error) {
    /*
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    if (errorCode === 'auth/account-exists-with-different-credential') {
      alert('You have already signed up with a different auth provider for that email.');
      // If you are using multiple auth providers on your app you should handle linking
      // the user's accounts here.
    } else {
      console.error(error);
    }*/

    if (error.code == 'auth/credential-already-in-use') {
        // Merge and relogin with new uid
        // The AuthCredential type that was used.

        

        const new_credential = GoogleAuthProvider.credentialFromError(error);
        
        let old_user_id = auth.currentUser.uid;
        // Lets Merge two accounts!

        signInWithCredential(auth, new_credential).then((result) => {
            const functions = getFunctions();
            const addMessage = httpsCallableFromURL(
              functions,
              /*"https://testfirebasefunction-nev535uipa-ew.a.run.app"*/
              "https://merge-nev535uipa-ew.a.run.app"
            );
            
            addMessage({old_user_id: old_user_id })
              .then((result) => {
                // Read result of the Cloud Function.
                console.log(result)
                //const data = result.data;
                
              });
        })
    };
  });
}

const Login = (props) => {

  //const [currentUser, setCurrentUser] = useState(null);

  const user = useUser();
  const navigate = useNavigate();

  //console.log(user);

  /*
  useEffect(() => {
      setCurrentUser(props.currentUser)
      //console.log("LoginForm:", props.currentUser)
  }, [props.currentUser]);*/

  function signOutBtn() {
    const auth = getAuth();

    document.cookie = "skey=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    signOut(auth).then(() => {
      navigate("/");
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  /*
  https://stackoverflow.com/questions/71307223/how-can-i-get-the-credential-from-linkwithpopup-when-it-has-an-error
  linkWithCredential()

  const isAnnon = auth.currentUser?.isAnonymous;
  const annonUser = auth.currentUser;

    try {
        const userCredential = await linkWithPopup(annonUser, provider);

        import { EmailAuthProvider } from "firebase/auth";
        const credential = EmailAuthProvider.credential(email, password);
    } 
    catch (error: any) {
    if (error?.code === "auth/credential-already-in-use") {
            const credential: any = GoogleAuthProvider.credentialFromError(error);
            const login = await signInWithCredential(auth, credential)
              .then((user) => {
                console.log(user);
              })
              .catch((error: any) => {
                console.log(error);
              });
        }
    }
  */
  async function signInWithGoogle() {
    const auth = getAuth();
    auth.languageCode = 'uk';
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
    /*
    // https://firebase.google.com/docs/auth/web/account-linking
    signInWithPopup(auth, provider)
    //linkWithRedirect(auth.currentUser, provider)
    //linkWithPopup(auth.currentUser, provider)
      .then((result) => {

        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result);
        //console.log('BEFORE OLD USER', auth.currentUser)
        
        if  (user && user.afterLoginRedirect.current)
          window.location.href = user.afterLoginRedirect.current;
        else
          navigate('/');

        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
       //const email = error.customData.email;

        if (error.code == 'auth/credential-already-in-use') {
            // Merge and relogin with new uid
            // The AuthCredential type that was used.
            const new_credential = GoogleAuthProvider.credentialFromError(error);
            
            let old_user_id = auth.currentUser.uid;
            // Lets Merge two accounts!

            signInWithCredential(auth, new_credential).then((result) => {
                const functions = getFunctions();
                const addMessage = httpsCallableFromURL(
                  functions,
                  //"https://testfirebasefunction-nev535uipa-ew.a.run.app"
                  "https://merge-nev535uipa-ew.a.run.app"
                );
                
                addMessage({old_user_id: old_user_id })
                  .then((result) => {
                    // Read result of the Cloud Function.
                    console.log(result)
                    //const data = result.data;
                    
                  });
            })
        };
        // ...
      });
    */
  }

  if (user && !user.isAnonymous) {
    return <></>
  } else {
    return (
      <><Box alignItems="center" justify = "center" justifyContent="center" sx={{ minWidth: 275, margin: "5px" }}>
        {/*<Header />*/}
        {/*<button className="button" onClick={signInWithGoogle}><i className="fab fa-google"></i>Sign in with google</button>*/}
        {!props.hidemessage && 
        <Alert severity="warning" sx={{margin:"1px", padding:"4px"}}>
            {(props.message)? props.message : "Щоб грати з друзями, зареєструйтесь. Один клік."}
            <GoogleButton type="light" style={{ marginTop:"30px"}} onClick={signInWithGoogle}></GoogleButton>
        </Alert>}
        {props.hidemessage && 
        <GoogleButton type="light" style={{ marginTop:"30px"}} onClick={signInWithGoogle}></GoogleButton>
        }
      </Box></>
    )
  }
}

export default Login;