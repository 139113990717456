import { useNavigate } from "react-router-dom";

export default function Error() {

  const navigate = useNavigate();

  function refreshPage() {
      navigate("/");
  }

  return <><div style={{"margin":"40px"}}><br /><span>Трапилась помилка :(<br/>Будь ласка спробуйте знову за деякий час.</span>
      <br />
      <button  style={{"marginTop":"40px"}} onClick={refreshPage}>Завантажити знову!</button>
      </div>
      </>
}