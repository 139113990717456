/*global FB*/
/*global FBInstant*/

import { Dimensions, ScrollView } from 'react-native';
import { View, Text, ViewStyle, TextStyle, StyleSheet } from "react-native"

import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import Header from "./Header"
import Footer from "./Footer"
import {RoutesPathes} from './RoutesPathes'
import {UserProvider} from "./UserContext"
import {Vibration} from 'react-native';
import { useColorScheme } from 'react-native';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Switch from '@mui/material/Switch';
import { SnackbarProvider, useSnackbar } from "notistack";

//import {ThemeProvider} from './ThemeProvider.js'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import logo from './logo.svg';
//import './App.css';

import './static/css/player-min.css'
import './static/css/wordsearch-min.css'
import './static/css/main.css'

import {Appearance} from 'react-native';

import { Offline, Online } from "react-detect-offline";
import { style } from '@mui/system';


//import {loginCredentials, getFbToken, fetchAllFBData} from './FBLogin'


export const MOVA = {TITLE: "МОВА", APP_LEVEL_URL: "https://get-level-nev535uipa-ew.a.run.app"}

var merge = require('lodash.merge');

const TITLE = 'МОВА';

const defTheme = {
  typography: {
    allVariants: {
      fontFamily: ['short_filmsmedium', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      textTransform: 'none',
      fontSize: 20,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500
    },
  },
  components: {
    // Name of the component
    MuiButton: {root:{
      color: '#800000'
    }},
    MuiStepLabel: {
      styleOverrides: {
        // Name of the slot
        label: {
          fontSize: 16,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#800000', // maroon
      light : '#a00000',
      dark : 'blue',
      contrastText: '#fff',
    },
    primary2: {
      main: '#be3455',
      light : '#d25c78',
      dark : '#982a44',
      contrastText: '#fff'
    },
    primary3: {
      main: '#ad1457',
      light : '#d8196d',
      dark : '#8a1046',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f48fb1',
    },
  },
};

const modeTheme = {
  light : {
    palette: {
      mode: 'light',
      ahref: "maroon",
      logo : {
        main: 'maroon',
        gradient: '#c07878',
        secondary: 'white',
      },
      onlineStatus: '3px solid rgb(52, 199, 89)',
      board: {
        text: 'black',
        stroke : 'grey',
        selection: '#ffcdd2', //'#f8bbd0', //'rgb(255, 201, 226)',
        justselect: '#cfd8dc',
      },
      tooltip: {
        background: {
          main: 'rgb(245, 245, 245)',
          secondary: 'hsl(47,87%,94%)',
        },
        fab: {
          main: "#000",
          secondary: 'maroon',// 'hsl(47,79%,58%)',
        },
        
      },
      edit: {
        main: '#42C0A1',
      }
    },
  },
   dark : {
    palette: {
      mode: 'dark',
      ahref: "#ff8a80",
      logo : {
        main: 'white',
        secondary: 'maroon',
        gradient: '#bdbdbd',
      },
      onlineStatus: '2px solid rgb(52, 199, 89)',
      board: {
        text: "#eee",
        stroke : '#757575',//'#bdbdbd',
        selection: '#f48fb1', //'#ff8a80',
        justselect: '#455a64',
      },
      tooltip: {
        background: {
          main: '#434343',
          secondary: '#434343',
        },
        fab: {
          main: 'white',
          secondary: "#ff8a80",
        }
      },
      background: {
        default: "#202124",
        paper: '#434343',
      },
      primary: {
        main: '#ff8a80', //'#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      edit: {
        main: '#42C0A1',
      }
    },
    typography: {
      color: "#FFF"
    },
  },
};

const App = ()=> {

  // const [acttheme, setTheme] = useState(null);
  const [userThemeMode, setUserThemeMode] = useState(null);

  const colorScheme = useColorScheme()
  const [showComp, setComp] = useState(null);


  useEffect(() => {
    //document.body.style.background = colors.background;
    let mode = colorScheme;

    if (null !== userThemeMode)
      mode = userThemeMode;

    let mergedTheme = {};
    merge( mergedTheme, defTheme, modeTheme[mode]);

    //let mergedTheme = merge(defTheme,s modeTheme['light']);

    //const darkTheme = createTheme(mergedTheme);
    const darkTheme = mergedTheme;

    // setTheme(darkTheme);
    setComp(getComp(darkTheme));

  }, [colorScheme, userThemeMode] )

  useEffect(() => {
    document.title = TITLE;
  }, []);

  
  const viewStyles = [
    styles.container,
    /*{ backgroundColor: colors.background },*/
  ]
  const textStyles = [styles.text/*, { color: colors.text }*/]

//<Route path="/selectgame" element={<SelectGame mFBInstant={mFBInstant}/>} />
  return showComp;

  function getComp(acttheme) {

    const newTheme = createTheme(acttheme)

    function SnackbarCloseButton({ snackbarKey }) {
      const { closeSnackbar } = useSnackbar();
      
      let style = {};
      if ('light' == newTheme.palette.mode) {
          style.color = newTheme.palette.secondary.main;
      };
      if ('dark' == newTheme.palette.mode) {
        style.color = newTheme.palette.primary.main;
      };

      return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
          <CloseIcon style={style} />
        </IconButton>
      );
    }

    return (
    <>
    {/*<div className="container" style={{display: 'flex', justifyContent: 'center'}}>
    <div style={{ width: '375px', justifyContent:'center', alignItems:'center'}}>
  */}
    {/*<Online>*/}
    
    <ThemeProvider  theme={{...newTheme}} >
    <CssBaseline enableColorScheme />
    <SnackbarProvider 
      preventDuplicate
      maxSnack={1} 
      action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      /*components={{
        default: ThemeResponsiveSnackbar,
        success: ThemeResponsiveSnackbar,
        error: ThemeResponsiveSnackbar,
        warning: ThemeResponsiveSnackbar
      }}*/ 
    >
    <View style={styles.container}>
    <View 
      style={{
      minWidth: 375,
      maxWidth: 550,
      flexDirection: "col",
      justifyContent: "center", alignItems: "center" 
    }}>
    <ScrollView>
    
    <RoutesPathes acttheme={acttheme} setUserThemeMode={setUserThemeMode}/>

    <Footer />
    </ScrollView> 
    </View></View>
    </SnackbarProvider>
    </ThemeProvider>
    {/*</Online>*/}
    {/*<Offline><Header network="offline"/></Offline>*/}
    {/*</div>
    </div>*/}

    </>
  );
  };

}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontWeight: "bold",
    fontSize: 20,
  },
})