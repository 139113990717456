import React,{useState,useEffect, useRef, useLayoutEffect, useImperativeHandle} from 'react';
import { useTheme } from '@mui/material/styles';
//import short_filmsmedium
import '../static/css/shortfilms-medium-webfont.ttf'
const sibling_ordered_cairo = ['011', '012', '013', '014', '021', '022', '023', '024', '112', '113', '114', '121', '122', '123', '124', '131', '132', '134', '203', '211', '212', '213', '214', '221', '222', '223', '224', '231', '312', '313', '314', '321', '322', '323', '324', '331', '332', '334', '411', '412', '413', '414', '421', '422', '423', '424']

const sibling_cairo = {}

sibling_cairo['011'] = ['012', '014', '114']
sibling_cairo['012'] = ['011', '013', '014', '113', '121']
sibling_cairo['013'] = ['012', '014', '021', '124']
sibling_cairo['014'] = ['011', '012', '013']

sibling_cairo['021'] = ['013', '124', '022', '024']
sibling_cairo['022'] = ['021', '123', '131', '023', '024']
sibling_cairo['023'] = ['022', '024', '134']
sibling_cairo['024'] = ['021', '022', '023']

sibling_cairo['112'] = ['113', '114', '203', '211']
sibling_cairo['113'] = ['112', '114', '012', '121', '214']
sibling_cairo['114'] = ['011', '113', '112']

sibling_cairo['121'] = ['012', '113', '214', '122', '124']
sibling_cairo['122'] = ['121', '124', '123', '213', '221']
sibling_cairo['123'] = ['124', '122', '224', '131', '022']
sibling_cairo['124'] = ['013', '021', '121', '122', '123']

sibling_cairo['131'] = ['022', '123', '224', '134', '132']
sibling_cairo['132'] = ['131', '134', '223', '231']
sibling_cairo['134'] = ['023', '131', '132']

sibling_cairo['203'] = ['112', '211', '314']
sibling_cairo['211'] = ['112', '203', '314', '212', '214']
sibling_cairo['212'] = ['211', '214', '213', '313', '321']
sibling_cairo['213'] = ['212', '214', '324', '221', '122']
sibling_cairo['214'] = ['211', '212', '213', '113', '121']

sibling_cairo['221'] = ['122', '213', '324', '222', '224']
sibling_cairo['222'] = ['221', '323', '331', '223', '224']
sibling_cairo['223'] = ['224', '222', '334', '231', '132']
sibling_cairo['224'] = ['123', '221', '222', '223', '131']
sibling_cairo['231'] = ['132', '223', '334']

sibling_cairo['312'] = ['411', '313', '314']
sibling_cairo['313'] = ['312', '314', '321', '414', '212']
sibling_cairo['314'] = ['211', '312', '313', '203']

sibling_cairo['321'] = ['212', '313', '414', '322', '324']
sibling_cairo['322'] = ['321', '413', '421', '323', '324']
sibling_cairo['323'] = ['324', '322', '424', '331', '222']
sibling_cairo['324'] = ['321', '322', '323', '221', '213']

sibling_cairo['331'] = ['323', '424', '332', '334', '222']
sibling_cairo['332'] = ['331', '423', '334']
sibling_cairo['334'] = ['223', '331', '332', '231']

sibling_cairo['411'] = ['312', '414', '412']
sibling_cairo['412'] = ['411', '413', '414']
sibling_cairo['413'] = ['412', '414', '322', '421']
sibling_cairo['414'] = ['313', '321', '411', '412', '413']

sibling_cairo['421'] = ['322', '413', '422', '424']
sibling_cairo['422'] = ['421', '423', '424']
sibling_cairo['423'] = ['422', '424', '332']
sibling_cairo['424'] = ['323', '421', '422', '423', '331']

export const cairoConfig = {sibling_ordered:sibling_ordered_cairo, sibling:sibling_cairo}

export function getWordScreenshot(props) {

}

let Cairo = React.forwardRef((props, reff) => {
    const [cairo_object, setCairoObject] = useState(null)
    const [level, SetLevel] = useState()
    const cairoSVG = useRef();
    const cairoSVGAnimation = useRef();
    const cairoSVGAnimationScale = useRef();
    const cairoSVGAnimationScaleOff = useRef();

    //let lens = Object.keys(sibling).length;
    //console.log(sibling)
    //console.log(sibling.length)

    const gamedata = useRef(props.gamedata)
    const Vl = useRef(props.Vl)
    const letterVl = useRef(props.letterVl)
    const items = useRef(props.items)
    const is_first_init = useRef(props.init)
    const oldXY = useRef({x:0, y:0});
    const [scaleData, setScaleData] = useState({});

    let words_string;
    //let cairo_object = null;

    const globTheme = useTheme();

    useImperativeHandle(reff, () => ({ makeScale }), [ ])

    useEffect(() => {
        
        if (!cairoSVG.current  )
            return;
        
        (undefined!=scaleData.x) &&  makeScale(scaleData.x, scaleData.y, scaleData.scale, scaleData.largeBoard)

    }, [cairoSVG.current, scaleData.x]);

    function makeScale(x, y, scale, largeBoard) {
        
        if (!cairoSVG.current ) {
            setScaleData({x:x, y:y, scale:scale, largeBoard:largeBoard});
        }

        if (!cairoSVG.current )
            return;

        if (/*x == 0 ||*/ largeBoard) {
            //console.log('Set Cairo to 0000');
            cairoSVG.current.setAttribute("transform", "translate(0,0)scale(1)");
            cairoSVGAnimation.current.setAttribute("begin", "indefinite");
            cairoSVGAnimation.current.setAttribute("values", "0,0")
            oldXY.current = {x: 0,y: 0};
            cairoSVGAnimation.current.beginElement();
        }
        else {
            
            if (1 == scale)
                cairoSVG.current.setAttribute("transform", `scale(${scale})`);
            // translate(-39.06000061035155,-32.63599853515625)scale(1.4)
            
            /*let str_scale = cairoSVG.current.getAttribute("transform");
            if ("translate(" == str_scale.substring(0,10)) {
                console.log('L1:', str_scale);
                str_scale = str_scale.substring(10);
                if (str_scale.indexOf(')scale(')) 
                    str_scale = str_scale.substring(0, str_scale.indexOf(')scale('));
                console.log('L2:', str_scale);
            }*/
            if (cairoSVGAnimation) {
                //path="M0,0 L10,15"
                if ((0 == oldXY.current.x && 0 == oldXY.current.y) ||
                    (0 == x && 0 == y))
                    cairoSVGAnimation.current.setAttribute("dur", `0.001s`);
                else
                    cairoSVGAnimation.current.setAttribute("dur", `0.4s`);
                // cairoSVGAnimation.current.setAttribute("path", `M${str_scale} L${x},${y}`);
                //setPathAnimate("M20,50 C20,-50 180,150 180,50 C180-50 20,150 20,50 z");
                //console.log('LOOOOFG:', cairoSVGAnimation.current.getAttribute("path"))
                //let x1 = str_scale.split(',')[0];
                //let y1 = str_scale.split(',')[1];
                /*
                console.log(oldXY.current);
                let x1 = (x - oldXY.current.x)/1;
                let y1 = (y - oldXY.current.y)/1;

                */

                //cairoSVGAnimation.current.setAttribute("path", `M 0,0 L${x1},${y1} `);
                //cairoSVGAnimation.current.setAttribute("values", "0,0;" + x1 + ", " + y1)
                cairoSVGAnimation.current.setAttribute("begin", "indefinite");
                cairoSVGAnimation.current.setAttribute("values", oldXY.current.x + ","+ oldXY.current.y + ";" + x + ", " + y)
                oldXY.current = {x: x,y: y};

                //console.log('LOOOOFG:', cairoSVGAnimation.current.getAttribute("values"))
                //cairoSVGAnimation.current.setAttribute("from",oldXY.current.x + ", " + oldXY.current.y)
                //cairoSVGAnimation.current.setAttribute("to", x + ", " + y)

                //cairoSVGAnimation.current.setAttribute("from"," 0,0 ")
                //cairoSVGAnimation.current.setAttribute("to", x1 + ", " + y1)

                //cairoSVGAnimation.current.setAttribute("path", `M 0,0 L -49.37999877929686,-70.11599731445311`);

                //console.log('LOOOOFG1:', `M${str_scale} L${x-x1},${y-y1}`)
                //console.log('LOOOOFG:', `M${str_scale} L${x},${y}`)
                //cairoSVGAnimation.current.setAttribute("path", `M${str_scale} L${x},${y}`);
                
                cairoSVGAnimation.current.beginElement();

                /*
                if (0 && 1!=scale && 
                    `scale(${scale})` != cairoSVG.current.getAttribute("transform")
                    )
                    cairoSVGAnimationScale.current.beginElement();

                */
            };
            
            
            cairoSVG.current.setAttribute("transform", `scale(${scale})`);
           
            //cairoSVG.current.setAttribute("transform", `translate(${x},${y})scale(${scale})`);
            
        };

        /*cairoSVG.current.animate(
            {
                transform: `translate(${x},${y})`
            },
            1000
        );*/

        //drawObject.makeScale(x, y, scale)
    }

    useEffect(() => {
        gamedata.current = props.gamedata
    }, [props.gamedata]);

    useLayoutEffect(() => {

        SetLevel(props.level)

        let data = gamedata.current;
        words_string = data.level_longstring;
        
        let mv_selected_list;
        items.current = props.items;

         if (is_first_init.current) {
            mv_selected_list = [...sibling_ordered_cairo]
         } else {
            mv_selected_list = items.current
         }

         setCairoObject(show_data_cairo(Vl, letterVl, props.SelectionRef, words_string, mv_selected_list, is_first_init.current, props.id_object, props.color, 'hilite-mark2', {}, ''))
    }, [gamedata, props.palettemode, props.level, props.largeBoard, props.items, is_first_init, props.color, Vl, letterVl, props.SelectionRef])

   //console.log(cairo_object) 
   return cairo_object;
    //return <div>CAAAIIIRO</div>

    
    function show_data_cairo(Vl, letterVl, SelectionRef, words_string, show_list, is_first_init, id_object, color, n, o, r) {
        
        //console.log(words_string)
        let letters = []
        if (is_first_init)
            letters = words_string
        
        /*if (o)
            for (var v in o) f.attr(v, o[v]);*/
        
        //if (r)
        //      f.css("background-color", color)
        
        /*f.css("position", 'absolute')
        
        f.css("top", '0px')
        f.css("left", '0px')*/
        
        //if (is_first_init)
        //    f.css("opacity", '.6')


        let scale = '0.146'
        //html = '';
        
        let c_number = '#876fc1'
        let c_number_w = 0
        let c_size = 15

        let stroke_width = 1
        let stroke_color = 'black'
        let angle = "-45"
        let ppart = ""
        if (color.substring(0,5) == 'url(#' && n) {
            
            //stroke_width = 4
            //color = 'white'
            c_number_w = n.substring(n.length - 2)
            if ("-" == c_number_w.substring(0,1) )
                c_number_w = n.substring(n.length - 1)
            
            let color_list = ["red", "green", "grey", "blue", 'orange']

            c_number_w = parseInt(c_number_w)
            c_number = color_list[c_number_w % color_list.length]

            if (c_number_w >= color_list.length)
                //angle = "90"
                ppart = "2"

            if (c_number_w >= 2*color_list.length)
                angle = "90"

            if (c_number_w >= 3*color_list.length)
                angle = "45"
    
        }

        if (undefined == c_number) 
            c_number = 'black'

        if (c_number && color == 'url(#secondPattern)')
            color = 'url(#secondPattern'+ ppart + c_number + '_' + c_size + '_' + angle + ')'

        let defs = <defs><pattern id={'secondPattern'+ c_number + '_' + c_size + '_' + angle} patternUnits="userSpaceOnUse" width="19.5" height="9.5" patternTransform="{'rotate(' + angle + ')'}"><line x1="0" y="0" x2="0" y2="9.5" stroke={c_number} stroke-width="2" /></pattern><pattern id={'secondPattern2'+ c_number + '_' + c_size + '_' + angle} x="8" y="8" width="20" height="20" patternUnits="userSpaceOnUse"><rect x={c_size} y={c_size} width="5" height="5" fill={c_number} /></pattern></defs>
        
        let htmls = [];
        var i = 0;
        //console.log(show_list)

        let gradient = <></>
        if (!is_first_init && props.is_show_last)
            gradient = <><pattern id="grad2" width="30" height="10" 
            patternTransform="rotate(130)" patternUnits="userSpaceOnUse">
                <rect width="100%" height="100%" fill={color}></rect>
                <line x1="5" x2="5" y2="10" style={{stroke:"grey", strokeWidth:3}} />
                
           </pattern>
          </> /*<linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" style={{stopColor: "grey", "stop-opacity":"1"}} />
                <stop offset="15%" style={{stopColor: color , "stop-opacity":"1"}} />
                <stop offset="85%" style={{stopColor: color , "stop-opacity":"1"}} />
                
                </linearGradient>*/

        for (var index in show_list) {
            
            //console.log(index)
            //console.log(show_list[index])
            //console.log(Object.keys(show_list[index]))
            let item
            if (-1 != Object.keys(show_list[index]).indexOf('z'))
                item = show_list[index].z.toString();
            else
                item = show_list[index].toString()

            let row = parseInt(item[0]);
            let col = parseInt(item[1]);
            let frm = parseInt(item[2]);

            let path_style = '';
            let transform;
            let letter_shift = {};

            
            let text_style={fill: globTheme.palette.board.text, align: "middle",fontSize:"160px", fontFamily:"short_filmsmedium",  textTransform:"uppercase"}

            if (1 == frm)  {transform = 'rotate(90)';  letter_shift = {x:"140", y:"210"};}//letter_shift = 'x="140" y="210"'}
            if (2 == frm) {transform = 'rotate(180)'; letter_shift = {x:"100", y:"140"};} //letter_shift = 'x="100" y="140"'}
            if (3 == frm) {transform = 'rotate(-90)'; letter_shift = {x:"170", y:"100"};}
            if (4 == frm) {transform = ''; letter_shift = {x:"190", y:"180"}};
            
            let shift_x_delta = 0; let shift_y_delta = 0;
            if (0 == row % 2) {
                shift_x_delta = 43.2; shift_y_delta = 0;
            }

            if (1 == row % 2) {
                shift_x_delta = -11.35; shift_y_delta = -0.01;
            }

            let hh = 54.6; 
            let shift_x;
            let shift_y;
            if (1 == frm) {
                //0,15
                shift_x = 0 + 109. * (col -1);
                shift_y = 14.5  + hh * (row );
            
            }
            if (2 == frm) {
                //40,44.5
                shift_x = 39.0 + 109. * (col - 1);
                shift_y = 43.3  + hh * (row );
                
            }
            if (3 == frm) {
                //66.5,28.5
                shift_x = 64.8 + 109. * (col - 1);
                shift_y = 27.8  + hh * (row );
                
            }
            if (4 == frm) {
                //26.5,-1
                shift_x = 25.8 + 109. * (col - 1);
                shift_y = -1  + hh * (row );
                
            }

            shift_x = shift_x + shift_x_delta
            shift_y = shift_y + shift_y_delta

            let letter_box = '';
            
            /*if ( letters.length > 0 && id_object) {
                var l_count = sibling_ordered_cairo.indexOf(item);
                letter_box = <text {...letter_shift} dominantBaseline="middle"  textAnchor="middle" style={text_style} className="letter-in-box" transform-origin="center">{letters[l_count]}</text>;
            
            } else */
            if ( letters.length > 0 ) {
                //console.log(letterVl)
                //letter_shift.ref=(el) => {letterVl.current['z'+item] = el;}
                //letter_box = <text ' + letter_shift + ' dominant-baseline="middle"  text-anchor="middle" style="align: middle;font:160px short_filmsmedium;" class="letter-in-box" transform-origin="center">{letters[i]}</text>;
                letter_box = <text ref={(el) => {letterVl.current['z'+ item] = el;}} {...letter_shift} dominantBaseline="middle"  textAnchor="middle" style={text_style} className="letter-in-box" transform-origin="center">{letters[i]}</text>;
            }
            
            var opts1 = {};
            let z_factor = ""
            if (is_first_init) {
                //z_factor = 'data-z="' + item + '"'
                opts1['data-z'] = item;
                //opts1['backgroundColor'] = "white";
                opts1['ref'] = (el) => {Vl.current[item] = el;};
                opts1['onMouseDown'] = (e) => SelectionRef.current.mv_beginSelectingWord(e, props.largeBoard, globTheme.palette.board.selection);
                opts1['onTouchStart'] = (e) => SelectionRef.current.mv_beginSelectingWord(e, props.largeBoard, globTheme.palette.board.selection);
                opts1['onMouseUp'] = (e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection);
                opts1['onTouchEnd'] = (e) => SelectionRef.current.mv_endSelectingWord(e, globTheme.palette.board.selection);
                opts1['onMouseMove'] = (e) => SelectionRef.current.mv_hiliteSelection(e, props.largeBoard, globTheme.palette.board.selection);
                opts1['onTouchMove'] = (e) => SelectionRef.current.mv_hiliteSelection(e, props.largeBoard, globTheme.palette.board.selection);
                
            }
            // ref: (el) => {Vl.current[z] = el;}, onMouseDown:(e) => SelectionRef.current.mv_beginSelectingWord(e), onTouchStart:(e) => SelectionRef.current.mv_beginSelectingWord(e), onMouseUp:(e) => SelectionRef.current.mv_endSelectingWord(e) , onTouchEnd:(e) => SelectionRef.current.mv_endSelectingWord(e), onMouseMove:(e) => SelectionRef.current.mv_hiliteSelection(e), onTouchMove:(e) => SelectionRef.current.mv_hiliteSelection(e)
            
            var opts2 = {};
            //let str_fill = 'fill="' + color + '"'
            opts2['fill'] = color;
            opts2['stroke'] = globTheme.palette.board.stroke;
            //"#bdbdbd"; //let stroke_color = '#FFF';
            
            
            if (!is_first_init && props.is_show_last) {
                //opts2['fill'] = 'yellow';
                opts2['fill'] = "url(#grad2)";
            }   
            //str_fill = ''

            let str_fill_opacity = ''
            if (is_first_init)
                //str_fill_opacity = 'filfl-opacity="0.2"'
                opts2['fillOpacity'] = .1
            else
                //str_fill_opacity = 'fill-opacity="0.8"'
                opts2['fillOpacity'] = .8

            
            let newItem = <g key={index} className="box" {...opts1} transform={'translate('+ shift_x +','+ shift_y + ')scale('+scale+')'}>
                <path style2={path_style}  {...opts2}  d="M277.1 304.1l108-187.1L198 9 10.9 117l108 187.1z"  transform={transform} transform-origin="center" />{letter_box}</g>

            //newItem = '<g transform="translate('+ shift_x +','+ shift_y + ')scale('+scale+')"><path id="cc" '+ path_style +' fill="' + color + '" stroke="#000" d="M277.1 304.1l108-187.1L198 9 10.9 117l108 187.1z"  transform="' + transform + '" transform-origin="center"/><text ' + letter_shift + ' dominant-baseline="middle"  text-anchor="middle" style="align: middle;font:160px short_filmsmedium;">' + l + '</text></g>';

            htmls.push(newItem);
            i = i + 1
        }

        //let style1 = {width: "368px", height:"368px"}
        let style1 = {width: "100%"}
        style1.position="absolute";
        style1.left="0px";
        style1.top="0px";
        


        if (!is_first_init) {
            style1.zIndex = -1;
            //style1.top = 0;
            
        }else {
            //style1.zIndex = -1000;
        };

        let str_scale = "translate(0,0)scale(1)";
        let str_to = "0,0"
        let begin="indefinite"

        /*
        console.log(props.scaleup);
        if (props.scaleup && props.scaleup.current && !props.largeBoard) {

            if (0 && "translate(" == props.scaleup.current.substring(0,10)) {
                console.log('HELLO--3', props.scaleup);
                str_scale = props.scaleup.current;
                str_to = str_scale.substring(10, str_scale.indexOf(')scale('));
                begin="0s"
                str_scale = "scale(1.4)";
            }
            str_scale = "scale(1.4)";
            
            console.log('str_scale: ', str_to, props.largeBoard);
        };*/
        var id_object_group = {}
        if (id_object && id_object.id) {
            id_object_group = {id: id_object.id + '_group'};
        };

        let f = <svg  {...id_object} xmlns="http://www.w3.org/2000/svg" className={n} viewBox="0 0 305 305" style={style1} >
              <defs>{gradient}</defs>
            <g {...id_object_group} ref={cairoSVG}  transform={str_scale}>
            <animateTransform ref={cairoSVGAnimation}
                dur="0.1s"
                begin="indefinite"
                type="translate"
                attributeName="transform"   
                values="0,0"
                fill="freeze" additive="sum"
            />
              <animateTransform2
            begin="0s" values="-238,-258; -100,0" dur="0.5s"
            type="translate"
            attributeName="transform"   
            fill="freeze" additive="sum"/>
    
            <animateTransform 
                ref={cairoSVGAnimationScale}
                attributeName="transform"
                type="scale"
                from="1 1"
                to="1.4 1.4"
                begin="indefinite"
                dur="3s"
                repeatCount="0"
            />
            <animateTransform 
                ref={cairoSVGAnimationScaleOff}
                attributeName="transform"
                type="scale"
                from="1.4 1.4"
                to="1 1"
                begin="indefinite"
                dur="0.3s"
                repeatCount="1"
            />
                {htmls}
            </g>
            </svg>
        //console.log(f)
        return f
        //f.html(html);
        //$(".crossword", Jl).prepend(f)
        //$("#main_svg").append($circle);
    }
})

export default  Cairo;