module.exports = `
<div style="margin-top:10px;text-align: left;max-width:600px;align-items: center;
justify-content: left;">
	<span style="display: table;margin: 0 auto;margin-top:10px">
<center><h2>Про гру:</h2></center>
<p>Намагатися осягнути українську мову, то велике задоволення.</p>
<p>Наша мова налічує більше півмільйона слів, а мільйони словоформ можна до рахуби не брати, таких повних друкованих словників ще не існує. Але є великі електронні бази данних та виграшки, що використовують їх.</p>
<p>Кожен зможе знайти своє нове українське слово. Зробити власне відкриття.</p>
<p>Гра створена на основі академічного тлумачного словника, словника синонімів, фразеологічного словника, орфографічного та етимологічного словників. Кожне слово має посилання.</p>
<p>Останнє сторіччя багато вчених досліджували українську мову, саме вони подарували можливість доторкнутися до цього джерела. Філологи, етнографи, письменники, а тепер і математики зробили дослідження мови великою часткою свого життя.</p>  
<p>Прислів'я, приказки, казки та пісні, прокльони, примовки роблять мову живою. Тутечки, в цій грі, їх десятки тисяч з усієї України. Крізь розмовну та літературну мову, гра пробуджує увагу до говірки, до мови наших бабусь та дідів.</p>
<p>Гра змінюється, тож буде ще діалектна лексика та говори. Вичерпати діжкою українську мову неможливо.</p>
<p>Ну як прийшли на трушені груші, то давайте <a href="/">грати!</a></p>

<center><h2>Джерела:</h2></center>
<ul style="text-align: left;">
  <li><a href="https://services.ulif.org.ua/expl/Entry/index?wordid=1&page=0">Тлумачний словник української мови. </a> - 20т, 2015-2022рр, 204т слів</li>
  <li><a href="https://encyclopedia.kyiv.ua/vydaniya/files/slovnyk-prykazok-rosiisko-ukrainskyi.pdf">Словник приказок, ЙОГАНСЕН Майк</a> - Відтворення видання 1929 року</li>

  <li><a href="https://github.com/brown-uk/dict_uk#readme">Великий електронний словник української мови (ВЕСУМ)</a> - 6,5 м словоформ, база даних, github</li>
  <li><a href="https://github.com/LinguisticAndInformationSystems/mphdict/wiki">"Цифрове лексикографічне надбання України"</a> - Етимологічний словник української мови, томи 1–6, 1982-2012рр та Словник синонімів - 68 тис. реєстрових одиниць, понад 18 тис. синонімічних рядів; база даних, github</li>
  <li><a href="http://irbis-nbuv.gov.ua/cgi-bin/ua/elib.exe?I21DBN=UKRLIB&P21DBN=UKRLIB&Z21ID=&S21REF=10&S21CNR=20&S21STN=1&S21FMT=preitem&S21SRW=dz&S21SRD=UP&C21COM=S&2_S21P03=T=&2_S21STR=%D0%95%D0%A2%D0%98%D0%9C%D0%9E%D0%9B%D0%9E%D0%93%D0%86%D0%A7%D0%9D%D0%98%D0%99%20%D0%A1%D0%9B%D0%9E%D0%92%D0%9D%D0%98%D0%9A%20%D0%A3%D0%9A%D0%A0%D0%90%D0%87%D0%9D%D0%A1%D0%AC%D0%9A%D0%9E%D0%87%20%D0%9C%D0%9E%D0%92%D0%98$">Етимологічний словник української мови, 6 томів, 1982-2012рр</a> - Електронна бібліотека "УКРАЇНІКА"</li>
  <li><a href="http://www.inmo.org.ua/sum.html">«Словник української мови» - 11т</a> та <a href="http://sum.in.ua">СУМ-11</a> - 1970 — 1980рр, 134 тис слів</li>
  <li><a href="https://lcorp.ulif.org.ua/dictua/">"Фразеологічний словник української мови" у
2-х книгах  та  "Словник синонімів української мови" у 2-х томах,</a> - близько 261 тис. слів реєстр "Орфографічного словника української мови" + 56 тис. фразеологічних одиниць + 9200 синонімічних рядів </li>
  <li><a href="http://hrinchenko.com/">Словник Грінченка</a> - 4т, 1907—1909 рр, 68 тис. слів</li>
  <li><a href="https://github.com/lang-uk/ukrainian-word-stress-dictionary">Словник наголосів української мови</a> — 2,9 м словоформ та словосполук, база даних, github</li>
  <li><a href="https://www.goroh.pp.ua/about">Горох</a> — онлайн-бібліотека: Тлумачний словник - 246 тис. слів, Орфографічний словник -  6.3 млн словоформ, Словник синонімів нараховує 109 тис. синонімічних груп.  </li>
  <li><a href="https://ua-gec-dataset.grammarly.ai/">Корпус (Grammatical Error Correction) для української мови</a> GEC-корпус - це колекція текстів, що містять граматичні, стилістичні та орфографічні помилки.  </li>
<li><a href="http://www.mova.info/Page2.aspx?l1=14">Корпус української мови</a> - проект лабораторії комп'ютерної лінгвістики Київського національного унівеситету імені Тараса Шевченка </li>
<li><a href="https://mova.institute/%D0%B7%D0%BE%D0%BB%D0%BE%D1%82%D0%B8%D0%B9_%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82">Золотий морфосинтаксовий стандарт</a> - Корпус розмічено руками, містить 140 тисяч токенів</li>
<li><a href="https://github.com/lang-uk/tone-dict-uk">Український тональний словник</a> - містить 3442 слів української мови, які мають не нейтральну тональність </li>
<li><a href="https://slovnyk.me/">Збірка словників</a> — база більше 50 словників, пошук </li>
</ul>
<hr style="margin-top:20px;opacity: 50%;">
<center><h2>Контакти:</h2></center>
<p>Саме ваші пропозиції та зауваження роблять гру кращою, відгуки надсилайте поштою <span style='color:maroon'>info@mova.ua</span> або на сторінку ФБ</p>
    </span>
</div>
`;