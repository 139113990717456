/* global BigInt */

import firebaseApp from './firebase.config';
import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { getDatabase, ref, onValue, set, remove  } from "firebase/database";
import { SnackbarProvider, useSnackbar } from 'notistack';
import {useParams, Link, useNavigate, useLocation} from "react-router-dom";
import React, { useEffect, useState, useRef} from "react";
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import './index.css';
import './static/css/main.css';
import './static/css/dictionary.css';
import { MOVA } from "./App.js"
import TagManager from 'react-gtm-module';
import Header from "./Header";
import ExtensionIcon from '@mui/icons-material/Extension';
import Button from '@mui/material/Button';
import SlowMotionVideo from '@mui/icons-material/SlowMotionVideo';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import IconButton from '@mui/material/IconButton';

import {useUser} from './UserContext';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import {getFunctions, httpsCallable} from 'firebase/functions';
import { setLogLevel } from 'firebase/app';
import { Bookmark } from '@mui/icons-material';

import {setUserLastActive} from './UserContext';
import Login from "./Login";

const database = getDatabase(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west1");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary2 = MuiAccordionSummary;
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = MuiAccordionDetails;

const AccordionDetails2 = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function PR(props) {
  return <Typography style={{paddingBottom:'15px'}}>{props.childs}</Typography>
}

export function FavoriteWord(props) {

  const [isLove, setLove] = useState(null);
    
  const [icon, setIcon] = useState(<BookmarkRemoveIcon />);
  const [icon1, setIcon1] = useState(<BookmarkBorderIcon />);

  let navigate = useNavigate();

  const globTheme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();

  useEffect(() => {

    setLove(null);

    if (props.word == null || props.word == "") {
      return;
    };

    try {
      // Error: child failed: path argument was an invalid path = "/love_words/E6X8AbSK9sVzZKgnYJKyxyGWG5u1/колесо-[". Paths must be non-empty strings and can't contain ".", "#", "$", "[", or "]"
      const postRef = ref(database, `/love_words/${user.uid}/${props.word}`);
      return onValue(postRef, (snapshot) => {
          setLove(snapshot.exists());
      }); 
    }
    catch (err) {};

  }, [props.word]);

  function addLoveWord(aWord) {

    if (user && user.isAnonymous) {
      navigate('/profile');
      return;
    } 
    try {
      set(ref(database, `/love_words/${user.uid}/${aWord}`), 1)
        .then(() => {
          enqueueSnackbar && enqueueSnackbar(`Слово додано в улюблені!`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
        });
    }
    catch (err) {};
  }

  function deleteLoveWord(aWord) {

    if (props.user && user.isAnonymous) {
      navigate('/profile');
      return;
    } 

    remove(ref(database, `/love_words/${user.uid}/${aWord}`))
    .then(() => {
      enqueueSnackbar && enqueueSnackbar(`Слово вилучено з улюблених!`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
    });
  }

  if (isLove === null)
    return <></>;

  let style = {}

  if (props.color)
    style.color = props.color;

  if (!isLove) {
      return  <IconButton style={style}
      onMouseEnter={() => setIcon1(<BookmarkAddIcon />)}
      onMouseLeave={() => setIcon1(<BookmarkBorderIcon />)}
      onClick = {()=>addLoveWord(props.word)}
      >{icon1}</IconButton>
    } else {
      return <IconButton style={style}
      onMouseEnter={() => setIcon(<BookmarkBorderIcon />)}
      onMouseLeave={() => setIcon(<BookmarkRemoveIcon />)}
      onClick = {()=>deleteLoveWord(props.word)}
      >{icon}</IconButton>
    }
  
};

function Article(props) {
  const [items, setItems] = useState({});
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [word, setWord] = useState(null);

  let navigate = useNavigate();
  let user = useUser();

  const globTheme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    <Chip icon={<ListIcon />} key="two" label="Улюблені"  {...(props.expanded=='panel2' ? {color:'primary'} : {})} variant="outlined" onClick={() => {props.setExpanded('panel2');props.DictsetWord('')} }/>, 
    (props.user && props.user.lastWord.current && props.user.lastWord.current.length>0) && <Chip key="one" label="Щойно знайдені" {...(props.expanded=='panel1' ? {color:'primary'} : {})} variant="outlined" onClick={() => {props.setExpanded('panel1');props.DictsetWord('')} }/>,
    <Chip key="three" label="Усі слова"  {...(props.expanded=='panel3' ? {color:'primary'} : {})} variant="outlined" onClick={() => {props.setExpanded('panel3');props.DictsetWord('')} }/>,
  ];

  useEffect(() => {
    // let word = params.word;
    setWord(props.word);
    setUserLastActive(user.uid, 'dictionary', props.word);
  }, [props.word])

  useEffect(() => {

    if (word == null || word == "") {
      setIsLoaded(true);
      return;
    }
      
    setIsLoaded(false);

    fetch(`${MOVA.APP_LEVEL_URL}/?${word}`)
    //fetch(`https://www.mova.ua/?${word}`)
    .then((response) => {
          return response.json()
      })
      .then(
        (result) => {
          window.scrollTo(0, 0);
          setError(null);
          setIsLoaded(true);
          //const result2 = reactStringReplace(result, '<a href="/?', () => '<a href="/#/dict');
          result.description = parseWithLinks(result.description);
          setItems(result);
          
          document.title = word.substr(0, word.lastIndexOf("-")) + " - " + MOVA.TITLE;
         
          TagManager.dataLayer({
            dataLayer: {
              event: 'pageview',
              pagePath: '/dictionary/' + word,
              pageTitle: word.substr(0, word.lastIndexOf("-")),
            },
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          error.message = 'Нажаль виникла помилка.'
          setError(error);
        }
      )
  }, [word])

  function parseWithLinks(text) {

    if ('dark' == globTheme.palette.mode) {
      text = text.replaceAll('style="color:black', 'style="color:white')
        .replaceAll('style="color: black', 'style="color: white')
        .replaceAll('style="color:grey"', 'style="color:#bdbdbd"')
        //.replaceAll('style="color: grey"', 'style="color:#bdbdbd"')
        .replaceAll('style="color:maroon', 'style="color:' + globTheme.palette.primary.main);
    };
    // console.log('text:', text);

    text = text.replace('<span class="com">кому і без додатка.</span>', '');
    text = text.replace('<span class="com">з ким і без додатка.</span>', '');
    
    function replace({ name, attribs, children }) {
      if (name === 'a' && attribs.href) {
        let href = attribs.href;
        
        if (href.substr(0, 2) === '/?') {
          href = href.substr(2);
        }
        let newlink = '/dict/' + href;
        
        let style = {};
        if ('dark' == globTheme.palette.mode) {
          style.color = globTheme.palette.primary.main;
        };

        return <Link style={style} to={newlink} onClick={() => {navigate(newlink, {replace: true}); /*setWord(href);*/}}>{domToReact(children)}</Link>;
        // return <Link to={newlink} onClick={() => setWord(href)}>{domToReact(children)}</Link>;
      }
      
      if (name=="pr") {
      
        const props = attributesToProps(attribs);
        props.childs = domToReact(children, {replace: replace});
        return <PR {...props}/>
      }

      if (name=="zn" || name=='lex') {
        return (<>{domToReact(children, {replace: replace})}</>);
      }
    }

    let styles = {}
    styles["p.trn"] = {display: "inline" , fontWeight:600, color: globTheme.palette.primary.main};
    styles["p.m2"] = {display: "inline" , color: globTheme.palette.primary.main};
    
    if ('dark' == globTheme.palette.mode) {
      // <GlobalStyles styles={{ h1: { color: 'grey' } }} />
      styles[".WORD"] = {color: globTheme.palette.primary.main};
      styles[".ENTRY"] = {color: 'white'};
      styles["h4"] = {color: globTheme.palette.primary.main, textTransform: "uppercase !important"};
      styles[".ILLSRC"] = {color: "#bdbdbd"};
      styles["em"] = {color: "#bdbdbd"};
      
    } else {
      styles[".ILLSRC"] = {color: "black"};
      styles["em"] = {color: "#424242"};
    }


    return <><GlobalStyles styles={styles} />
    {parse(text, {replace: replace})}</>;
  }

  if (error) {
    return <><Box sx={{paddingTop:"20px"}}>
    <div style={{marginLeft: "20px", padding:"20px", fontSize:"20px"}}>{error.message} &nbsp;&nbsp;&nbsp;<br /><button style={{marginTop:"20px"}} onClick={() => navigate(-1)}>Повернутися назад</button></div>
    </Box></>;
  } else if (!isLoaded) {
    return <>
    <Box sx={{ width: '100%'}}>
      <LinearProgress />
    </Box>
    <Typography variant='h2' m={2}>{word && word.split('-')[0].toUpperCase()}</Typography>
    {/*<center><div style={{padding:"20px"}} className="spinner-container">
    <div className="loading-spinner"><div></div></div>
  </div></center>*/}</>;
  } else {
    if (items.dzherelo_url && 'dark' == globTheme.palette.mode) {
      items.dzherelo_url = items.dzherelo_url.replace('color:black', 'color:white');
    };

      return (items && items.description && <>
      <Box style={{paddingTop:"10px"}} className="word_description">
        <Stack direction="row" spacing={2} alignItems="center">
        <FavoriteWord word={word} color = {globTheme.palette.primary.main}/>
        <Breadcrumbs separator="-" aria-label="breadcrumb">
        {breadcrumbs}
        </Breadcrumbs>
        </Stack>
        {items.description}
      </Box>
    { items.dzherelo_url && <Typography sx={{float:'right', fontSize:"16px"}} dangerouslySetInnerHTML={{__html:('<i>Джерело: '+items.dzherelo_url+'</i>')}}></Typography>}</>)
  };

}
function AllOpenWords(props) {

  const [letter, setLetter] = useState('');
  const [list, setList] = useState([]);
  const [listFirstLetter, setlistFirstLetter] = useState([]);
  const [list2, setList2] = useState([]);
  const [listFirstLetter2, setlistFirstLetter2] = useState([]);
  const [isDownloading, setDownload] = useState(false);

  const alpha = Array.from('абвгґдеєжзиіїйклмнопрстуфхцчшщюя');

  var get_passed_words = httpsCallable(functions, 'get_words');
  
  function showLink(href) {
            
    if (href.substr(0, 2) === '/?') {
      href = href.substr(2);
    }
  
    let newlink = '/dict/' + href;
    let title = href.split('-')[0];
    // return <Link key={href} to={newlink} onClick={() => props.GoToLink(href)}>{title}</Link>;
    return <Button sx={{margin:0, padding:0}} key={href} to={newlink} onClick={() => props.GoToLink(href)}>{title}</Button>;
  }

  useEffect(() => {
    let items = [];
    setDownload(true);

    get_passed_words({letter: letter})
    .then((result) => {

      setDownload(false);
      // console.log(result);
      if (letter != "" && result && result.data) {
        setList(result.data);
        // console.log(result.data);
      } else {
        // console.log(result);
        setlistFirstLetter(Object.keys(result.data).sort((a,b) => a?.localeCompare(b)));
      }
    });

  }, [letter]);

  useEffect(() => {
    let res = [];
    let res_words = [];

    props.user.lastWord.current.forEach(el => {
      if (!res.includes(el[0])) {
        res.push(el[0]);
      }

      if (letter == el[0]) {
        res_words.push(el);
      }
    });

    let uniqueLetter = [...new Set([...listFirstLetter, ...res])].sort((a,b) => a?.localeCompare(b));
    setlistFirstLetter2(uniqueLetter);

    let uniqueWords = [...new Set([...list, ...res_words])].sort((a,b) => a?.localeCompare(b));
    setList2(uniqueWords);

  }, [props.user.lastWord.current.length, letter, list, listFirstLetter]);

  if (letter == '')
    return <>
    <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      p: 0,
      m: 0,
      bgcolor: 'background.paper',
      borderRadius: 0,
      alignItems: 'center' 
    }}
  > {isDownloading && <Box sx={{ width: '100%'}}><LinearProgress /></Box>}
    {alpha.map((anchor) => (
        (listFirstLetter2 && (listFirstLetter2.includes(anchor))) && <Button  key={anchor} sx={{maxWidth: 50}}  onClick = {() => setLetter(anchor)} >{anchor.toUpperCase()}</Button>
        || (!listFirstLetter2 || (!listFirstLetter2.includes(anchor))) && <Button disabled={true} key={anchor} sx={{maxWidth: 50}}  onClick = {() => setLetter(anchor)} >{anchor.toUpperCase()}</Button>
    ))}
  </Box></>

  return <>{isDownloading && <Box sx={{ width: '100%'}}><LinearProgress /></Box>}
  <Pagination
  color="primary" 
  sx ={{paddingBottom:"5px"}}
  count={listFirstLetter2.length}
  page={listFirstLetter2.indexOf(letter)+1}
  onChange={(event, value) => {
    setLetter(""/*listFirstLetter[value-1]*/);
  }} 
  boundaryCount={1}
  renderItem={(item) => (
    <PaginationItem {...item} slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} page={String(listFirstLetter2[item.page - 1]).toUpperCase()} />
  )} />{(letter != '' && list2.length == 0) && <Stack justifyContent="space-around"
  alignItems="center" spacing={2} direction="row" sx={{paddingTop:"10px"}}><CircularProgress /></Stack>
    /*<Alert color="primary" severity="error">Ще немає слів на «{letter.toUpperCase()}». 
    <Button variant="text"  onClick = {() => setLetter('')} >Абетка... </Button>
    </Alert>*/
  }
  <Box sx={{ flexGrow: 1 }}>
    {list2 && <Grid container spacing={3} mt={1}>
      {list2.sort((a,b) => a?.localeCompare(b)).map((anchor) => (
        <Grid item key={anchor}>
          <Item key={anchor}>{showLink(anchor)}</Item>
        </Grid>
      ))}
      </Grid>}
  </Box>
  </>
}

export default function Dictionary(props) {

    const params = useParams();
    const [word, setWord] = useState(params.word);
    const [love_words_list, setLoveWordsList] = useState(null);
    const prevWord = useRef('');

    const [expanded, setExpanded] = React.useState('panel1');
    const myRefVoc = useRef(null)
    
    const user = useUser();
    const globTheme = useTheme();

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    
    let navigate = useNavigate();

    React.useEffect(() => {
      // Google Analytics
      // console.log('LLLL:', location, 'PPPPP:', params);
      if (params.word) {
        setWord(params.word);
      }
    }, [params.word])

    React.useEffect(() => {
      if (!user.uid)
        return;

      const postRef = ref(database, `/love_words/${user.uid}`);
      return onValue(postRef, (snapshot) => {
          if (!snapshot.exists()) {
            setLoveWordsList(null);
          } else {
            const data = snapshot.val();
            setLoveWordsList(Object.keys(data));
          };
      });
    }, [user])

    // IF click BACK or NEXT Button
    /*if (params.word !== word)
    {
      prevWord.current = word;
      setWord(params.word);
    }*/

    // "молоко-21255629";

    
      function showLink(href) {
            
        if (href.substr(0, 2) === '/?') {
          href = href.substr(2);
        }
      
        let newlink = '/dict/' + href;
        let title = href.split('-')[0];
        //return <Link key={href} to={newlink} onClick={() => GoToLink(href)}>{title}</Link>;
        return <Button sx={{margin:0, padding:0}} key={href} to={newlink} onClick={() => GoToLink(href)}>{title}</Button>;
 
      }

      function GoToLink(h) {
        // setIsLoaded(false);
        //console.log(h);
        // prevWord.current = word;
        let newlink = '/dict/' + h;
        navigate(newlink);
        setWord(h);
        //word = "молоко-21255629"
        //word = h;
        //const item = e.target.href;
        //console.log(item);
        //console.log(useParams());
        //setCart([...cart, item]);
        }
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    /*useEffect(() => {
      if (null == user)
        return;

      if (props && "" != props.word && null != props.word) {
        getWord(props.word);
      };

    }, [user, props.word]);*/

    {
        //console.log(user.lastWord);
        return (
    <>
    <Box sx={{paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
      <Header />
      <Button sx={{m:0, pl:0.5, pt:0, pb:0}} style={{color: globTheme.palette.logo.secondary, backgroundColor: globTheme.palette.logo.main}} startIcon={<BrightnessAutoIcon />} onClick={()=>navigate("/")}>
        Грати</Button>
        <Button style={{color: globTheme.palette.logo.main}}  startIcon={<ListIcon />} onClick={() => setWord('') }>
        Словник</Button>
        <Button style={{color: globTheme.palette.logo.main}}  startIcon={<EmojiEventsIcon />} onClick={()=>navigate("/rating") }>
        Щабель</Button>
        

      {word && <Article word={word} uid={user.uid} user={user} DictsetWord={(item) => setWord(item)} expanded={expanded} setExpanded={setExpanded} nextWord={'паністара-21309644'} prevWord={'репутація-2812658'}/>}
    </Box>
      <Box ref={myRefVoc} sx={{display: word ? { xl: 'none', xs: 'none' } : {}, paddingLeft: "10px", paddingTop: word ? "30px" : "10px"}}>
      {user && user.lastWord.current && (0 < user.lastWord.current.length) && 
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Щойно знайдені слова</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            {user && user.lastWord.current && user.lastWord.current.sort((a,b) => a?.localeCompare(b)).map((anchor) => (
              <Grid item key={anchor}>
                <Item>{showLink(anchor)}</Item>
              </Grid>
            ))}
          </Grid>
        </Box>
        </AccordionDetails>
      </Accordion>}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Stack direction="row" alignItems="center" gap={1}><Typography>Улюблені слова</Typography><BookmarkBorderIcon fontSize="12px" /></Stack>
        </AccordionSummary>
        <AccordionDetails>
        {(user && user.isAnonymous) &&
        <Login message="Щоб зберігати слова, будь ласка, зареєструйтесь!" />}
        {/*<Alert severity="warning">Щоб грати з друзями та отримати рейтинг, будь ласка, <Button to='/profile' component={Link} color='primary' endIcon={<PersonAddIcon/>}>зареєструйтесь!</Button> </Alert>*/}
        
        { (!love_words_list) &&
        <Typography>Додавайте слова, щоб не загубити. Тисніть на <BookmarkBorderIcon /> коли щось сподобалось.</Typography>
        }
        { love_words_list && 
          <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            {love_words_list.sort((a,b) => a?.localeCompare(b)).map((anchor) => (
              <Grid item key={anchor}>
                <Item>{showLink(anchor)}</Item>
              </Grid>
            ))}
          </Grid>
        </Box>
        }
        </AccordionDetails>
      </Accordion>
      <Accordion  style={{margin:0, padding:0}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Усі відкриті слова</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding:10}} >
          <AllOpenWords GoToLink={GoToLink} user={user}/>
        </AccordionDetails>
      </Accordion>
      </Box>
          </>
            )
      
      //</div>return (
      //  <div dangerouslySetInnerHTML={{__html:items}}></div>
      //);
    }
  }