import React, {useRef, useState,useEffect, useImperativeHandle} from 'react';
import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import {useNavigate, Link} from "react-router-dom";
import {FavoriteWord} from '../Dictionary';

import Button from "@mui/material/Button";
import Alert  from '@mui/material/Alert';
import AlertTitle  from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
//import nextMP3 from  '../static/sound/next_button_5a5a6197bf10656.mp3';
import nextMP3 from  '../static/sound/next1.mp3';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const audioNext = new Audio(nextMP3);
audioNext.volume = 0.3;

const ToolTips = React.forwardRef((props, reff) => {
 // export default function ToolTips(props) {
    const [data, setData] = useState({...props});
    const [current, setCurrent] = useState(0);
    const fadeword = useRef();
    const [tooltipIsOpen, setTooltipOpen] = useState(true);
    const timer_tooltipIsOpen = useRef();
    const timer_nextToolTip = useRef();
    const globTheme = useTheme();

    /*
    const [nextTip] = useSound(nextMP3,
      { volume: 0.3 }
    );*/

    useImperativeHandle(reff, () => ({selectIdx}), [ ])

    let navigate = useNavigate();

    const theme = createTheme({
    palette: {
        neutral: {
        main: 'red', //'#64748B',
        light: 'red', //'#64748B',
        contrastText: '#fff',
        },
    },
    });

    const classes = makeStyles( {
        tooltipAlert: {
            '& .MuiAlert-message': {fontSize: 40, color: 'black', display: 'block', textAlign:"center", width:"100%"}
          /*"& .MuiAlert-icon": {
            fontSize: 40
          }*/
        }
      });

    useEffect(() => {

      let level_words_keys;
      let level_words2_keys;

      if (props.level_words_keys)
        level_words_keys = JSON.parse(props.level_words_keys);

      if (props.level_words2_keys)
        level_words2_keys = JSON.parse(props.level_words2_keys);

      setData({item: props.item, tooltips: props.tooltips, level_words_keys:level_words_keys, level_words2_keys:level_words2_keys});

      if (props.last_current_word && props.level_words) {
        let indx = props.level_words.findIndex((x) => x === props.last_current_word)
        if (data.tooltips[indx] )
          setCurrent(indx);
        
        setTooltipOpen(true);

        fadeword.current && fadeword.current.animate(
            {opacity: [0, 1, 0, 1]},
            600
        );
         
        clearTimeout(timer_nextToolTip.current);
        timer_nextToolTip.current = setTimeout(() => {if (indx == current) {nextUnresolvedSlide();}}, 9500);
        return (()=>{clearTimeout(timer_nextToolTip.current);});
      } else {
        setCurrent(0);
      }

    }, [props]);

    useEffect(() => {

      props.changeToolTip();
        
    }, [current]);
    
    if (!data.tooltips)
        return null;
    
    //console.log(data.tooltips)


    function nextSlide() {
        setTooltipOpen(true);
        setCurrent( (prevState) => prevState === data.tooltips.length - 1 ? 0 : prevState + 1 );
       // if (!props.muted.current) audioNext.play();
    }

    function nextUnresolvedSlide() {
      // console.log('Callback, next id:', current, getNextUnresolved(current));

      setCurrent((prevState) => getNextUnresolved(prevState));
      
   }

    function prevSlide() {
        setCurrent((prevState) => prevState === 0 ? data.tooltips.length - 1 : prevState - 1  );
    }
   
    function selectIdx(idx) {
      setTooltipOpen(true);
      setCurrent(idx);
    }
    
    function getNextUnresolved(id) {

      let passed = props.getCurrentPassedList();

      for (let i=1; i < data.tooltips.length+1 ; i++) {
        let new_id = (id + i) % data.tooltips.length;
        let word = props.level_words[new_id];
        if (!passed || undefined === passed[word])
          return new_id;
      }
      return (id === data.tooltips.length - 1 ? 0 : id + 1 )
    }

    function getCurrentHTML(id)
    {
      //console.log('Here333:', id);
      let word = props.level_words[id];
      let word_in_text = data.tooltips[id][0][2][1];
      let word_hiden = data.tooltips[id][0][2][2];

      let html;

      let passed = props.getCurrentPassedList();
      
      if (props.HeaderRef.current)
        props.HeaderRef.current.startGradient(nextUnresolvedSlide, globTheme);

      let str_replace_color = globTheme.palette.ahref;
      // let str_replace_color = '#ff8a80'; // maroon '#d18885';//
      // console.log('TTT:', data.tooltips[id]);

      try {
        let str = "";
        if (1 == props.level) {
          // Demo

          if (!passed || undefined === passed[word]) {
            str = 'Знайди слово:  <font color="'+ str_replace_color + '"><b>' + word.toUpperCase() + '</b></font>';
          } else {
            str = 'Знайдене слово:  <font color="'+ str_replace_color + '" id="fadeword" wordid="'+ id + '"><b>' + word.toUpperCase() + '</b></font>. Шукай інші слова.';
          };
          
        } else if ( word_hiden && (!passed || undefined === passed[word])) {
          str = data.tooltips[id][0][2][0].replace(word_hiden,'<font color="'+ str_replace_color + '"><b>' + word_hiden + '</b></font>');
        }
        else if (word_hiden && word_in_text && passed && undefined !== passed[word]) {
          //let str = data.tooltips[id][0][0].replace(word_in_text,'<font color="maroon" id="fadeword" wordid="'+ id + '"><b>' + word_in_text + '</b></font>');
          str = data.tooltips[id][0][2][0].replaceAll(word_hiden,'<font color="'+ str_replace_color + '" id="fadeword" wordid="'+ id + '"><b>' + word_in_text + '</b></font>');
        } else {
          str = data.tooltips[id][0][2][0];
        }

        html = (str) ? parse(str, {replace: replace}) : "";
      } catch (err) {console.log(err)};

      let styles = {}
      styles["#def_sinonim"] = {fontWeight:600, margin:0, paddingBottom:"5px"/*, color: globTheme.palette.primary.main*/};
      //styles["p.m2"] = {display: "inline" , color: globTheme.palette.primary.main};
    
      return <><GlobalStyles styles={styles} />{html}</>
    }
    
    function replace(node) {
      
      if (node.type === 'tag' && !['p', 'a', 'b', 'font', 'i', 'strong', 'div', 'span'].includes(node.name)) {
        return <></>;
      }

      if (node.name == "a" && node.attribs && node.attribs.href) {
        // /?сорочка-37697344
        let href = node.attribs.href; 
        if (href.substr(0, 2) === '/?') {
          href = href.substr(2);
        }
        let newlink = '/dict/' + href;

        let str_href_style = {}
        str_href_style = {color: globTheme.palette.ahref}
        //str_href_style = {color: "#ff8a80"}

        return <Link to={newlink} style={str_href_style}>{domToReact(node.children, {replace: replace})}</Link>
      }

      if (node.name=="font" && node.attribs && node.attribs.id === 'fadeword') {
        
        const props = attributesToProps(node.attribs);
        return <font ref={fadeword} {...props}>{domToReact(node.children, {replace: replace})}</font>
        
      }
    }

    function transform(node, index) {
      return
      // do not render any <span> tags
      /*if (node.type === 'tag' && !['b', 'font', 'i', 'strong', 'div'].includes(node.name)) {
        return null;
      }

      if (node.type === 'tag' && node.name === 'b') {
        //console.log(node, index);
        node.ref = (input) => { console.log(input) }
        //node.name = 'ol';
        return convertNodeToElement(node, index, transform);
      }*/
    }
    
    const StyledListItem = styled(ListItem)(({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
       
        maxWidth: 280,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        margin: 0,
        marginLeft: "10px",
        marginRight: "10px",
        padding: '0px',
        
      },
    }));

    const Div = styled('span')(({ theme }) => ({
    }));

    function get_full_info(iword) {
      
      let word = props.level_words[iword];

      let word2 = "";
      
      if (props.level_words2 && props.level_words2[iword])
        word2 = props.level_words2[iword];

      let passed = props.getCurrentPassedList();
      
      if (!passed || undefined === passed[word])
        return (<></>)

      let voc_info="";

      let tool_item = null
      for (var i=0; i< props.tooltips[iword].length; i++) {
          if (props.tooltips[iword][i][3]!=2)
                  continue
          //props.ref_passed_words
          tool_item = props.tooltips[iword][i]
          break
      }
      if (tool_item == null)
        return (<></>)

      /*  
      clearTimeout(timer_tooltipIsOpen.current);
      let time_limit = tool_item[0].length * 10 * 1000; 
      timer_tooltipIsOpen.current = setTimeout(() => { setTooltipOpen(false);}, time_limit);
      */

      let url = '/dict/';
      let show_word = '';

      if (word2 && data.level_words2_keys && data.level_words2_keys[iword]) {
        show_word = word2 + '-' + data.level_words2_keys[iword];
      } else if (word && data.level_words_keys && data.level_words_keys[iword]) {
        show_word = word + '-' + data.level_words_keys[iword];
      };
      
      url = url + show_word;
      let bg_color = globTheme.palette.tooltip.background.main; //secondary; 
      //'#434343'; //'hsl(47,87%,94%)';
      let fab_color = globTheme.palette.tooltip.fab.secondary; 
      //'#ff8a80'; //'#fff59d'; // "hsl(47,79%,58%)"

      // console.log('tool_item[0][0]', tool_item[0][0]);
      if (tool_item && tool_item[0] && tool_item[0][0])
        tool_item[0][0] = tool_item[0][0].replaceAll('<span style="color:black">', '<span>');

      return <Box style={{backgroundColor: bg_color,  marginTop: '0px', marginBottom: '2px', paddingRight: '0px', marginLeft: '0px', border: '0px solid grey', borderRadius: '10% 30% 50% 20%' }}>
        <Fab size='small' elevation={1}
        sx={{
          float:'right',
          bottom: theme.spacing(2),
          left: theme.spacing(0),
          padding:0, 
          margin:0,
          backgroundColor:'transparent',
          boxShadow: 0 
        }}  
        onClick={()=> {/*navigate(url);*/ props.DictWord(show_word)}} 
        >
        <ArrowCircleRightRoundedIcon  sx={{color: fab_color}}/>
          </Fab>
          <Typography variant="body" component="div" style={{ fontStyle: 'italic' , fontSize: "18px", paddingLeft:"15px", paddingTop:"5px" }}>
          <FavoriteWord color = {fab_color} word={show_word} /><Div sx={{cursor:"pointer"}} onClick={()=> {props.DictWord(show_word)}} >{parse("<b>"+ (word2 || word) +"</b>&nbsp;&mdash;&nbsp; "+ tool_item[0][0], {replace: replace})}</Div>
          {false && tool_item[1] && parse("<b>"+ (word2 || word) +"</b>&nbsp;&mdash;&nbsp; "+ tool_item[1][0], {replace: replace})}
          </Typography>

          {/*
        <List sx={{ padding:0, margin:0, paddingRight: "5px", listStyleType: 'disc', pl: 3,'& .MuiListItem-root': {
  display: 'list-item'}, bgcolor: 'background.paper'}}
    >
    {[tool_item[0][0]].map((value) => (
      <StyledListItem
        key={value}
        disableGutters = {true} 
        sx={{padding: 0}}
      >
        <ListItemText primaryTypographyProps={{fontSize: '18px'}}  sx={{paddingBottom: 0, paddingLeft:"2px", marginLeft:"2px"}} primary={parse("<b>"+ (word2 || word) +"</b>&nbsp;&mdash;&nbsp; "+ value, {replace: replace})} />
      </StyledListItem>
    ))}
    </List>*/}
        </Box>
    }

    if (undefined ===  current || undefined === data) {
      return <></>;
    };

    let bg_color = globTheme.palette.tooltip.background.main; 
    let fab_color = globTheme.palette.tooltip.fab.main;
    //'#434343'; //'rgb(245, 245, 245)';
    // color:'black',
    return (
    <>
    <ThemeProvider theme={theme}>
       {/**/}
       
    <Box style={{ backgroundColor: bg_color,  marginBottom: '2px', marginTop: '10px', marginRight: '10px', marginLeft: '10px', border: '0px solid grey', borderRadius: '10% 30% 50% 20%' }}>
    <Fab size='small' elevation={1}
        sx={{
          float:'right',
          bottom: theme.spacing(2),
          left: theme.spacing(2),
          color: 'white',
          padding:0, 
          margin:0,
          backgroundColor:'transparent',
          boxShadow: 0 
        }}
        onClick={nextSlide} 
        >
        <ArrowCircleRightRoundedIcon  sx={{color: fab_color}}/>
          </Fab>
        <Box sx={{ flexDirection: 'column', display: 'flex'}}>
        <Typography  variant="body" onClick={nextSlide}  sx={{cursor: "pointer", fontSize: "22px", marginLeft:"10px", paddingTop:'1px'}}>
        {data.tooltips && data.tooltips[current] && <>{getCurrentHTML(current)}</> 
        }
        </Typography>
        { (1 != props.level) &&
        <Typography variant="body" style={{ fontStyle: 'italic' , fontSize: "1rem", fontWeight:"bold", paddingLeft:"15px", paddingBottom: "1px" }}>{data.tooltips && data.tooltips[current] && data.tooltips[current][0][1].replace("(", "").replace(")", "")}</Typography>
        }
        </Box>
    </Box>
    {get_full_info(current)}
   {/*
    <Alert icon={false} style={{ backgroundColor: 'rgb(245, 245, 245)', color:'black', marginTop: '10px', marginRight: '0px', marginLeft: '10px', border: '0px solid grey', borderRadius: '10% 30% 50% 30%' }}  className={classes.tooltipAlert} action={<ArrowCircleRightRoundedIcon sx={{padding:0, margin:0}} onClick={nextSlide} />}>
      <AlertTitle sx={{fontSize:22}}>
        {data.tooltips && data.tooltips[current] && <>{getCurrentHTML(current)}</> 
        }</AlertTitle>
      <Typography style={{ fontStyle: 'italic' , fontSize: "0.77rem", paddingLeft:"15px" }}>{data.tooltips && data.tooltips[current] && data.tooltips[current][0][1].replace("(", "").replace(")", "")}</Typography>
      </Alert>*/}
    </ThemeProvider>
    </>)
    
    return (
    <>
    <table width="368px" border="0" id="hint" cellSpacing="0px" cellPadding="2px" style={{borderCollapse: "collapse", visibility: "visible", marginTop:"0px"}} >
    <tbody>
    <tr>{/*td rowSpan="3"><button style={{outline: 0, border:"none", backgroundColor: 'transparent'}} onClick={prevSlide}><span id="hint_back" style={{cursor: "pointer", fontSize: "34px", opacity: "0.072"}}>❮</span></button></td>*/}
        <td text-align='center'><span id="hint_text" style={{marginLeft: "5px", fontSize: "20px", textAlign:"left"}}><div style={{textAlign:"center"}} dangerouslySetInnerHTML={{__html: data.tooltips && data.tooltips[current] && data.tooltips[current][0][2][0]}} /></span></td>
        {/*<td rowSpan="3"><button  style={{outline: 0, border:"none", backgroundColor: 'transparent'}} onClick={nextSlide}><span id="hint_next" style={{fontSize: "34px", cursor: "pointer", opacity: "0.072"}}>❯</span></button></td>*/}</tr>
     {/*<tr><td><span id="hint_type" style={{margin:"0px", padding: "0px", clear: "right", float: "right", marginRight:"0px", color:"black", opacity: "70%", fontSize:"70%"}}>{data.tooltips && data.tooltips[current] && data.tooltips[current][0][1]}</span>
    </td></tr>*/}</tbody>
    </table>
    </>
    )
});

export default ToolTips;