import Login from "./Login"
import React,{useState,useEffect, useRef} from 'react';
import { getAuth, signOut, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import firebaseApp from './firebase.config';
import { doc, setDoc } from "firebase/firestore"; 

import { useTheme } from '@mui/material/styles';
import {useUser} from './UserContext';
import { SnackbarProvider, useSnackbar } from 'notistack';

import GlobalStyles from '@mui/material/GlobalStyles';
import {useParams, Link, useNavigate, useLocation} from "react-router-dom";
import Header from "./Header";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import Avatar from '@mui/material/Avatar';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LogoutIcon from '@mui/icons-material/Logout';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import GoogleMaps from "./GoogleMaps";
import {Country_UA, CountriesRegions, Countries, oldRegionUA} from './Countries'

import {setUserLastActive} from './UserContext';
import {getLevelTitle} from './game/NextLevelShow'
import { onSnapshot} from "firebase/firestore"; 

const db = getFirestore(firebaseApp);
//import {Cities_UA, Cities_UA_titles} from './DecentralizationTree'

// https://dev.to/jorik/country-code-to-flag-emoji-a21
/*
referer: 
https://apps-632979254681500.apps.fbsbx.com/
https://mova-game-apps.web.app/
https://www.mova.ua/
https://mova.ua/
http://localhost:3000/
*/
function getFlagEmoji(countryCode) {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char =>  127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }

function getFlagRegion(regionCode, countryCode) {
    if ("ua" == countryCode.toLocaleLowerCase()) {

        try {
            // Import image on demand
            const image = require(`./static/ukraine_regions/${regionCode.toLowerCase()}.svg`);
        
            if (!image) return null;
            
            return <img
                loading="lazy"
                width="20"
                src={image}
                alt=""
                />;

          } catch (error) {
            return null;
          }
    }

    return;
};

function getLabelCountry(code) {

    code = code.toLowerCase();
    let items = Countries.filter((item) => {
        return item.code.toLocaleLowerCase() == code;
      })
    
    if (items.length > 0)
        return {code: code, label: items[0].label}
    else
        return null;
}

export default function Profile(props) {

    //const [user, setCurrentUser] = useState(null);

    let user = useUser();
    const [game_profile, setProfile] = useState(null);

    const [confirmOpen, setConfirmOpen] = useState(false);

    const [Regions, setRegions] = useState([]);
    const [Cities, setCities] = useState([]);
    const [user_photoURL, set_user_photoURL] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [valueCountry, setValueCountry] = useState(null);
    const [valueRegion, setValueRegion] = useState(null);
    const [valueCity, setValueCity] = useState();
    const [valueName, setValueName] = useState();
    const [valueGMap, setValueGMap] = useState();

    const refCountry = useRef();
    const refRegion = useRef();
    const refCity = useRef();

    const globTheme = useTheme();
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    
    /*
    const { ref, autocompleteRef } = usePlacesWidget({
      apiKey: "AIzaSyBHPLI0pVk01jcuKwW4WuN-oZ5vFRZutuk",
      onPlaceSelected: (place) => {
        console.log('onPlaceSelected:', place);
      }
    });*/

    useEffect(() => {
    
      if (!props.uid)
            return;
        
      return onSnapshot(doc(db, "profiles", props.uid), {}, (docum) => {
          let val = docum.data();
          if (!val)
              return;
              
          /*
          if (val.show_region) {

              if (!val.show_region.name && oldRegionUA[val.show_region]) {

                  val.show_region = oldRegionUA[val.show_region];
              }
          }*/
          let fb_player_id = null;
          if (val['facebook.com'] && val['facebook.com']['playerId'])
              fb_player_id = val['facebook.com']['playerId'];

          if (!val.show_name && val['google.com'] && val['google.com']['displayName'] ) {
            val.show_name = val['google.com']['displayName'];
          }

          if (!val.show_name && val['facebook.com'] && val['facebook.com']['playerName'] ) {
            val.show_name = val['facebook.com']['playerName'];
          }
          
          // console.log('GMAP', );
          var profile = {level: val.level, title: getLevelTitle(val.level), fb_player_id: fb_player_id, show_name: val.show_name, show_country: val.show_country, show_region: val.show_region, show_city: val.show_city, gmap: val.gmap};
          setProfile(profile);
      });

    }, [props.uid]);

    useEffect(() => {

      if (game_profile == null)
        return;
      //console.log('game_profile:', (game_profile));
    /*geocodeByPlaceId('ChIJfWm-Il46OEcRW-fiEqgMNfU')
    .then(results => console.log(results))
    .catch(error => console.error(error));*/

        // console.log('props', props);

        user && user.getUserPhotoURL(user.uid, (url)=>{ set_user_photoURL(url)});

        setUserLastActive(user.uid, 'profile');

        if (game_profile && game_profile.show_country)
            setValueCountry(game_profile.show_country);

        if (game_profile && game_profile.show_city)
            setValueCity(game_profile.show_city);

        if (game_profile && game_profile.show_name)
            setValueName(game_profile.show_name);

        if (game_profile && game_profile.show_region)
            setValueRegion(game_profile.show_region);

        setSubmitDisabled(true);
        if (!user.isAnonymous && user.firstLoginMultiGameRedirect.current) {
            // Redirect to Multigame

            //user.firstLoginMultiGameRedirect.current = false;

            navigate('/');
            return;
        };

    }, [game_profile]);

    useEffect(() => {

        if (!valueCountry) {
            setRegions([]);
            return;
        };

        if ("ua" == valueCountry.toLocaleLowerCase()) {
            setRegions(Country_UA.regions.sort((a,b) => a.name?.localeCompare(b.name)));
        } else {

            let regs = CountriesRegions.filter((item) => {return item["countryShortCode"].toLocaleLowerCase() == valueCountry.toLocaleLowerCase()});
            setRegions(regs.length > 0 ? regs[0].regions : 0);
        };

        if (game_profile.show_country == valueCountry.toLocaleLowerCase()) {
            
            if (game_profile && game_profile.gmap)
                //setValueRegion(game_profile.show_region);
                setValueGMap(game_profile.gmap)
            else
              setValueGMap(null);

        } else {
            setValueGMap(null);
        }

        console.log('game_profile', game_profile);
    }, [valueCountry]);

    /*
    function getCityTitle(option)
    {
      return Cities_UA_titles[option[5]]
    }

    function getCityFullName(option, long = true) 
    {
      // ['UA71000000000010357','UA71080000000036344','','','','P','Черкаський'],
      // ['UA71000000000010357','UA71080000000036344','UA71080030000050121','','','H','Березняківська'],
      // ['UA71000000000010357','UA71080000000036344','UA71080030000050121','UA71080030020051961','','C','Велика Яблунівка'],
      if (!Array.isArray(option))
        return option;

      let rayon_id = option[1];
      let gromada_id = option[2];

      let rayon = Cities_UA.filter((item) => {return item[0] == option[0] && item[1] == option[1] && 'P' == item[5]});
      let gromada = Cities_UA.filter((item) => {return item[0] == option[0] && item[1] == option[1] && item[2] == option[2] && 'H' == item[5]});
      
      //console.log('rayon', option, rayon, rayon_id)
      //if (rayon.lenght > 0)

      if (long)
        return Cities_UA_titles[option[5]] + " " + option[6] + ' - ' + gromada[0][6] + ' гр, ' + rayon[0][6] + ' р.'
      else
        return Cities_UA_titles[option[5]] + " " + option[6]; 
    }*/

    /*
    useEffect(() => {

      if (undefined === valueCountry || !valueRegion || "ua" != valueCountry.toLocaleLowerCase()) {
          setCities([]);
          return;
      };

      console.log('valueRegion:', valueRegion);
      let regCode = valueRegion.ua_code;
      regCode = "UA71000000000010357";

      let cities = Cities_UA.filter((item) => {return item[0] == regCode && Cities_UA_titles[item[5]]});
      setCities(cities.length > 0 ? cities.sort((a,b) => a[6]?.localeCompare(b[6])) : []);
      //console.log('cities:', cities);
     
  }, [valueCountry, valueRegion]);
  */
    function signOutBtn() {
        const auth = getAuth();

        document.cookie = "skey=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        signOut(auth).then(() => {
        navigate("/");
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }

    function saveChanges() {

        const db = getFirestore(firebaseApp);

        const fsProfile = doc(db, 'profiles', user.uid);
        
        let newData = {show_name: valueName, show_country: valueCountry/*, show_region: valueRegion, show_city: valueCity*/}
        
        newData.gmap = valueGMap;
        
        return setDoc(fsProfile, newData, { merge: true })
        .then(() => {
            enqueueSnackbar && enqueueSnackbar(`Профайл збережено!`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
            setSubmitDisabled(true);
            return true;
        })
        .catch((error) => {
            enqueueSnackbar && enqueueSnackbar(`Помилка! Профайл не збережено!`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
            return false;
        });
    }
        
    const ConfirmDialog = (props) => {
        const { title, children, open, setOpen, onConfirm } = props;
        return (
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >{title}</Box>
                    <Box>
                        <IconButton style={{ position: "absolute", top: "0", right: "0" }} onClick={()=> setOpen(false)}>
                            <HighlightOffIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
            <Button
                
                onClick={() => {
                  setOpen(false);
                  onConfirm();
                }}
              >
                Так
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpen(false)}
                color="secondary"
              >
                Ні
              </Button>
              
            </DialogActions>
          </Dialog>
        );
      };

    if (!user)
        return;

    return <>
    <Box sx={{ paddingLeft: "0px", paddingBottom: "0px" }}>
        <Header />
        <Box sx={{ display: 'flex', paddingLeft: "30px", alignItems: 'center', textAlign: 'center' }}>
        <Button style={{color: globTheme.palette.logo.main}} startIcon={<BrightnessAutoIcon />} onClick={()=>navigate("/")}>
        Грати</Button>
        { user && !user.isAnonymous && <Button style={{color: globTheme.palette.logo.main}}  startIcon={<AutoGraphIcon />} onClick={()=>navigate("/stat") }>
        Досягнення</Button>}
        <Button style={{color: globTheme.palette.logo.main}}  startIcon={<EmojiEventsIcon />} onClick={()=>navigate("/rating") }>
        Щабель</Button>
        </Box>
        {<Login currentUser={user}/>}  
    </Box>
       {(user && game_profile && !user.isAnonymous ) &&
       <><Box justifyContent="center" sx={{ minWidth: 275, margin: "30px" }}>
       <Card variant="outlined" sx={{paddingBottom:"50px"}}>
           <CardHeader
           avatar={
             <Avatar
                   alt={user.displayName}
                   src={user_photoURL} 
                   sx={{ width: 52, height: 52}}
                   />
           }
           action={
            (!process.env.REACT_APP_FACEBOOK_MODE)?<Button onClick={() => setConfirmOpen(true)} sx={{padding:"2px 10px"}}  endIcon={<LogoutIcon  />}>Вихід</Button>:<></>
           }
           title={user.displayName}
           subheader="Профіль гравця"
         />
        <CardContent>
            <FormControl required >
            <TextField
            id="filled-helperText"
            label="Ваше ім'я"
            size="small"
            color = "edit"
            multiline={false}
            value={valueName || ''} 
            onChange={(event) => {setValueName(event.target.value); setSubmitDisabled(false);}}
            helperText="Бачать інші гравці в щабелі"
            FormHelperTextProps={{sx:{fontSize:"16px"}}}
            />
            <Autocomplete
            disablePortal
            ref={refCountry}
            id="combo-box-country"
            autoHighlight
            size="small"
            color = "edit"
            defaultValue = {game_profile.show_country ? getLabelCountry(game_profile.show_country) : null}
            options={Countries}
            /*getOptionSelected = {(option, value) => {  
                return option?.id === value?.id || option?.name.toLowerCase() === value?.name.toLowerCase();
              }}*/
            isOptionEqualToValue = {(a,b) => (a.code.toLowerCase() == b.code.toLowerCase())}
            onChange={(event, newValue) => {
                setValueCountry(newValue ? newValue.code.toLocaleLowerCase() : null);
                setSubmitDisabled(false);
              }}  
            getOptionLabel ={(option) => option.label ?? option}
            sx={{ width: 300, paddingBottom:"20px", paddingTop:"20px"  }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > span': { mr: 1, fontSize: "26px", flexShrink: 0 } }} {...props}>
                  <span>{getFlagEmoji(option.code)}</span>{option.label}
                </Box>
              )}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label="Країна"
                  color = "edit"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          {valueCountry && <GoogleMaps country={valueCountry} onChange = {(val) => {setValueGMap(val); setSubmitDisabled(false)}} DefaultValue={valueGMap}/>}
            {/*valueCountry && <GooglePlacesAutocomplete  apiKey={"AIzaSyBHPLI0pVk01jcuKwW4WuN-oZ5vFRZutuk"} 
              apiOptions={{ 
                language: 'ua', 
                region: valueCountry, }}
              
              autocompletionRequest={{
                componentRestrictions: { country: valueCountry },
                fields: ["address_components"],
                types: ["(cities)"],
                //result_type: ['administrative_area_level_2']
              }}
             
              selectProps={{
                
                styles: {
                  input: (provided) => {return {
                    ...provided,
                    color: 'blue',
                  }},
                  option: (provided) => {return{
                    ...provided,
                    color: 'blue',
                  }},
                  singleValue: (provided) => ({
                    ...provided,
                    color: 'blue',
                  }),
                },
              }}
            />*/}
            {/*
            <Autocomplete
            ref={refRegion}
            disablePortal
            size="small"
            autoHighlight
            color = "edit"
            id="combo-box-region"
            options={Regions}
            value={valueRegion}
            onChange={(event, newValue) => {
                setValueRegion(newValue ? newValue : null);
                setSubmitDisabled(false);
              }}  
            getOptionLabel ={(option) => option.name ?? option}
            isOptionEqualToValue = {(a,b) => (a.shortCode.toLowerCase() == b.shortCode.toLowerCase())}
            sx={{ width: 300, paddingBottom:"20px"  }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > span': { mr: 1, fontSize: "26px", flexShrink: 0 } }} {...props}>
                  <span>{getFlagRegion(option.shortCode, valueCountry)}</span>{option.name}
                </Box>
              )}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label="Регіон"
                  color = "edit"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {(valueCountry && "ua" != valueCountry.toLocaleLowerCase()) && 
            <TextField
            id="filled-helperText"
            label="Місто або селище"
            size="small"
            color = "edit"
            multiline={false}
            ref={refCity}
            value={valueCity || ''} 
            onChange={(event) => {setValueCity(event.target.value); setSubmitDisabled(false);}}
            helperText="Щоб вивчати говірку рідного краю"
            FormHelperTextProps={{sx:{fontSize:"16px"}}}
            />}

            { (valueCountry && "ua" == valueCountry.toLocaleLowerCase()) &&
              <Autocomplete
              ref={refCity}
              disablePortal
              size="small"
              autoHighlight
              color = "edit"
              id="combo-box-city"
              noOptionsText="Немає варіантів" 
              options={Cities}
              value={valueCity || ''}
              onChange={(event, newValue) => {
                  setValueCity(newValue ? newValue : null);
                  setSubmitDisabled(false);
                }}  
              getOptionLabel ={(option) => getCityFullName(option, false) }

              isOptionEqualToValue = {(a,b) => (a && b && a[3] == b[3])}

              filterOptions={createFilterOptions({
                matchFrom: 'start',
                stringify: (option) => option[6],
              })} 

              sx={{ width: 300, paddingBottom:"20px"  }}

              renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > span': { mr: 1, fontSize: "26px", flexShrink: 0 } }} {...props}>
                    {getCityFullName(option) }
                  </Box>
                )}
              renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Місто \ селище"
                    helperText="Щоб вивчати говірку рідного краю"
                    color = "edit"
                    sx ={{'& p':{
                      fontSize:"16px"
                    }}}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            }*/}
            </FormControl>
        </CardContent>
           <CardActions style={{justifyContent: 'center'}}>
            <Box flexDirection={"column"}  display="flex" alignItems="center">
               <Button color='edit' disabled={submitDisabled} onClick = {saveChanges} sx={{padding:"2px 10px"}} variant="outlined" endIcon={<BookmarkBorderIcon />}>Зберегти зміни</Button>
               <Button onClick = {()=>{navigate("/")}} sx={{marginTop:"20px"}} variant="outlined" endIcon={<BrightnessAutoIcon />}>Продовжити грати без змін</Button>   
            </Box>
            </CardActions>
       </Card>
       
       </Box>
       <ConfirmDialog title="Вийти?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={signOutBtn}
        >
            Ви впевнені, що треба розлогінитись?
        </ConfirmDialog>
       </>
        }
        
        </>
    
}
