import Board from "./game/Board"
import Box from "@mui/material/Box"
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {setUserLastActive} from './UserContext';
import { useEffect } from "react";
import { useTheme } from '@mui/material/styles';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function Intro(props) {

    const globTheme = useTheme();

    const reduction = [[24, 23, 22, 21, 20], [24-5, 24-6, 24-11, 24-16], [24-10, 24-15, 24-20, 24-21, 24-22, 24-23] , [24-7, 24-8, 24-9, 24-14, 24-13, 24-12], [24-17,24-18,24-19,24-24]]
    const level_longstring = "словамовиврвйачіиградноїй".split("").reverse().join("");
    const raw = {
        board_type:'square',
        board_size: 5,
        level_red: JSON.stringify(reduction),
        level_longstring : level_longstring,
        level_words: ['слова', 'мови', 'рідної', 'вивчай', 'грай']
    };

    useEffect(() => {
      
      setUserLastActive(props.user.uid, 'intro');

    }, [props.user.uid]);

    return  <>
    <Board onIntroClick={()=>{setUserLastActive(props.user.uid, 'intro_board_click');props.setIntroShow(false)}} palettemode = {props.palettemode}  gamedata={raw} level={0} user={props.user}/>
    <Box display="flex" width="100%" sx={{ justifyContent: 'center',  }}>
    <Button sx={{m:0, pl:0.5, pt:0, pb:0}} style={{color: globTheme.palette.logo.secondary, backgroundColor: globTheme.palette.logo.main}} startIcon={<PlayCircleIcon />} onClick={()=>{setUserLastActive(props.user.uid, 'intro_button_click'); props.setIntroShow(false)}}>
        Грати</Button>
    {/*<IconButton sx={{width:"70%"}} ={primary} onClick={()=>{props.setIntroShow(false)}} endIcon={<PlayCircleIcon/>}>
      Грати
  </IconButton>*/}
    </Box>
    </>
};