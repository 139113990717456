import React,{ useState, useEffect, useRef, useLayoutEffect, useImperativeHandle} from 'react';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import HilbertCurve from './nextlevel/HilbertCurve';
import MaurerRose from './nextlevel/MaurerRose';
import Penrose from './nextlevel/Penrose';
import Embodary from './nextlevel/Embodary';

import { Animated} from "react-native";

import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Tooltip from "@mui/material/Tooltip";
import { keyframes } from '@mui/system';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {props.title && <Box sx={{ minWidth: 50, paddingRight:"5px" }}>
          <Typography variant="body2" color="text.secondary">{props.title}:</Typography>
        </Box>}
        <Box sx={{ flexGrow: 1 , mr: 1 }}>
          <LinearProgress variant="buffer" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const StepperSx = {
    "& .MuiStepConnector-root2": {
      left: "calc(-50% + 40px)",
      right: "calc(50% + 40px)"
    },
    "& .MuiStepConnector-line": {
      marginTop: "30px" // To position the line lower
    }
  };
  
  const steps = [
    'Прислів\'я',
    'Приказки',
    'Синоніми',
    'Література',
  ];

function getStep(iLevel) {
    // [1, 83, 161, 251]
    if (iLevel < 83)
        return 0;
    
    if (iLevel < 161)
        return 1;

    if (iLevel < 251)
        return 2;
    
    return 3;
}

function getPercent(iLevel) {
  // [1, 83, 161, 251]
  if (iLevel < 83)
      return Math.round(iLevel/83*100);
  
  if (iLevel < 161)
    return Math.round((iLevel - 83)/(161 - 83)*100);

  if (iLevel < 251)
    return Math.round((iLevel - 161)/(251 - 161)*100);
  
  
  return Math.round((iLevel - 251)/(913 - 251)*100);
}

export function getLevelTitle(iLevel) {
  if (undefined == iLevel)
    return "";

  return steps[getStep(iLevel)] + ": " + getPercent(iLevel) + "%";
};

export default function StatusBar(props) {
    
    const [show, setShow] = useState(null);
    const [percent, setPercent] = useState();

    const [showNextButton, setShowNextButton] = useState(false);

    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const progressRef = React.useRef(() => {});

    const fadeAnim = useRef(new Animated.Value(1)).current;
    const fadeAnim2 = useRef(new Animated.Value(1)).current;

    let template_path;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 5000,
          useNativeDriver: false, // <-- Add this
        }).start();
      };
    
    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 3 seconds
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 3000,
          useNativeDriver: false, // <-- Add this
        }).start();
      };
      
    const fadeOut2 = () => {
        // Will change fadeAnim value to 0 in 3 seconds
        Animated.timing(fadeAnim2, {
          toValue: 0,
          duration: 1000
        }).start();
      };

    React.useEffect(() => {
        progressRef.current = () => {
          if (progress > 100) {
            setProgress(0);
            setBuffer(10);
          } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;

            if (progress + diff < percent) {
                setProgress(progress + diff);
                setBuffer(progress + diff);// + diff2);
            }
            else {
                setProgress(percent);
                setShowNextButton(true);
                fadeOut();

                delay(2000).then(() => {props.play()});

                // fadeOut2();
            }

            
          }
        };
      });

    React.useEffect(() => {

      setPercent(getPercent(props.level));//Math.round(100*(1 + props.level - 14)/(83 - 14));
        
      const timer = setInterval(() => {
          progressRef.current();
        }, 500);

      /*const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }, 800);*/

      return () => {
        clearInterval(timer);
      };

    }, []);

    useEffect(() => {
        
      if (undefined == percent)
        return;
        
      let size;
      let lshow;
      let level = props.level;

      //let size, percent, show;

      if (level >= 251) {
          size = 9
          // template_path = "next_hilbert_curve.html"
          // percent = Math.round(100*(level - 251)/(913 - 251));
          lshow = <HilbertCurve percent={percent}  size={size} />

      } else if (level >= 161) {
          size = 8
          //template_path = "next_maurer_rose.html"
          //percent = Math.round(100*(1 + level - 161)/(251 - 161));
          lshow = <MaurerRose percent={percent} size={size} />

      } else if (level >= 83) {
          size = 7
          //template_path = "next_penrose.html"
          //percent = Math.round(100*(1 + level - 83)/(161 - 83));
          lshow = <Penrose percent={percent} size={size} />

      }/* else if (level >= 14) {
          size = 6
          // template_path = 'next_embodary.html'
          // percent = Math.round(100*(1 + level - 14)/(83 - 14));
          lshow = <Embodary percent={percent} size={size} />

      } else if (level >= 5) {
          size = 5
          // template_path = 'next_embodary.html'
          // percent = Math.round(100*(1 + level - 5)/(14 - 5));
          lshow = <Embodary percent={percent} size={size} />
          
      } */else if (level > 1) {
          size = 4
          // template_path = 'next_embodary.html'
          // percent = Math.round(100*(1 + level )/(5));
          lshow = <Embodary percent={percent} size={size} />
      };

      setShow(lshow);

    }, [props, progress, showNextButton, percent]);

    if (!show)
      return <></>;
    
    return <>{props.multigame && <Box style={{paddingBottom:"10px", }}>{props.multiscore}</Box>}
    {!(props.multigame) && <Stepper  style={{paddingTop:"5px", paddingBottom:"5px", display: "flex" }} activeStep={getStep(props.level)} alternativeLabel >
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
    </Stepper>}<Box sx={{ width: '100%' }}>
    <Animated.View
        style={
        {
            // Bind opacity to animated value
            opacity: fadeAnim2
        }
        }
    ><LinearProgressWithLabel title={props.multigame ? steps[getStep(props.level)] : ""} percent={percent} value={progress} valueBuffer={buffer}/>
    </Animated.View>
    <Box sx={{display: 'flex',justifyContent:"center",
      alignItems: 'flex-center', paddingTop:"5px", minHeight:"60px"}}>
    {showNextButton && <Stack>{/*props.multigame && props.multiscore*/}<Stack direction="row" spacing={2}   justifyContent="center"
alignItems="center"><Typography variant="body2" color="text.secondary">Рівень: {props.level}</Typography><Button variant="plain" onClick = {() => {props.play()}/*startPlayLevel(true)*/}  endIcon={<PlayCircleFilledIcon />}>Грати</Button></Stack></Stack>}
    </Box>
  </Box>
  <Box onClick = {() => {props.play()}} style={{cursor: "pointer"}}>
  <Animated.View
        style={
        {
            // Bind opacity to animated value
            opacity: fadeAnim
        }
        }
    >
  {show}
  </Animated.View></Box>
  </>;
};