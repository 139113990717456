import React,{useState,useEffect, useLayoutEffect, useRef, useImperativeHandle} from 'react';
import CrossPath from "./CrossPath";
import {getWordScreenshot} from "./CrossPath";
import { useTheme } from '@mui/material/styles';
//import { getDatabase, ref, set } from "firebase/database";
import firebaseApp from '../firebase.config';
import { doc, setDoc,updateDoc, getFirestore } from "firebase/firestore"
import { increment } from "firebase/firestore";
import { runTransaction } from "firebase/firestore";
import { getDatabase, ref, onValue, once, child, get } from "firebase/database";
import { SnackbarProvider, useSnackbar } from 'notistack';
//import badMoveMP3 from '../static/sound/bad_4ddb6f8795cf80f.mp3';
import badMoveMP3_1 from '../static/sound/oshybka-otvet4.mp3';
import badMoveMP3 from '../static/sound/gain_12_normal.mp3';

//import goodMoveMP3 from '../static/sound/good_56b309777180ab6.mp3';
 //import goodMoveMP3 from '../static/sound/open_25fa6d4661090c9.mp3'
import goodMoveMP3_1 from '../static/sound/good_790c8eaf0b0389c.mp3';
import goodMoveMP3 from '../static/sound/startgame.mp3';
import interestMoveMP3 from '../static/sound/interest.mp3';
import acceptMoveMP3 from '../static/sound/accept.mp3';
import {ColorSet} from './Colors';

import {
    Canvg,
    presets
  } from 'canvg'

const db = getFirestore(firebaseApp);
const database = getDatabase(firebaseApp);

const audioGoodMove = new Audio(goodMoveMP3);
const audioBadMove = new Audio(badMoveMP3);
const audioInterestMove = new Audio(interestMoveMP3);
const audioAcceptMove = new Audio(acceptMoveMP3);
audioBadMove.volume = 0.2;
audioGoodMove.volume = 0.05;
audioInterestMove.volume = 0.1;
audioAcceptMove.volume = 0.1;

export default  Selection = React.forwardRef((props, reff) => {

    const words_ref = useRef();
    const crossPathRef = useRef();
    var crosswordOffsetHeight = useRef(props.offsetHeight);
    var propsData = useRef(props);
    const mv_select_state = useRef(false)
    const EventVal = useRef(null)
    const gamedata = useRef()
    const mv_used_words = useRef([])
    const Vl = useRef([])
    const mv_used_raw = useRef([])
    const words_reds = useRef([])
    const mv_selected_list = useRef([])
    const scaleup = useRef(false);

    const globTheme = useTheme();

    const { enqueueSnackbar } = useSnackbar();
    //const enqueueSnackbar = null;
    
    const [level, SetLevel] = useState()
    //const [newdata, setData] = useState('Hello Word!')
    const [highlightComponent, setHighLight] = useState(null)
    // const highlightComponent = useRef(null);
    const [highlightComponentDemo, setHighLightDemo] = useState(null)
    const [largeBoard, setLargeBoard] = useState(props.largeBoard);
    const [mouseCoord, setMouseCoord] = useState();
    const setPrevTrans = useRef({x:0, y:0, s:1 }); 
    /*
    const [goodMoveSound] = useSound(goodMoveMP3,
        { volume: 0.1,  interrupt: true, }
      );*/

     /*const [badMoveSound] = useSound('../static/sound/bad_4ddb6f8795cf80f.mp3',
        { volume: 0.25, interrupt: true, }
      );*/

      /*const [goodMoveSound] = useSound('../static/sound/good_790c8eaf0b0389c.mp3',
        { volume: 0.1,  interrupt: true, }
      );*/

    //let mv_selected_list;// = []
    //let mv_used_raw = []
    //let mv_used_words;// = []
    let sibling;// = props.sibling
    //let Vl;// = props.Vl
    let game_mode;// = props.gamedata.board_type;
    let letterVl;// = props.letterVl
    let words;// = [...props.gamedata.level_words];

    //let [words, SetWords] = useState([...props.gamedata.level_words])
    //var words = props.gamedata.level_words;
    let size;// = props.gamedata.board_size;
    //let words_reds;// = JSON.parse(props.gamedata.level_red);
    let words_string;// = props.gamedata.level_longstring;
    
    let demoTimeout;

    useImperativeHandle(reff, () => ({ handleBoxToggle, mv_endSelectingWord, mv_hiliteSelection, mv_beginSelectingWord, updateUsedRaw, lets_help_blink_thefirst_timeout, makeScale }), [ ])

    /*
    useEffect (() => {
        SetLevel(props.level)
        words_ref.current = [...props.gamedata.level_words];
    }, [props.level])*/

    useEffect(() => {
        //SetWords([...props.gamedata.level_words]);
       
        
        
        if (undefined === props.level)
            return;
        
        
        setLargeBoard(props.largeBoard);
        SetLevel(props.level)
        words_ref.current = [...props.gamedata.level_words];

        propsData.current = {...props}
        
        mv_selected_list.current = []
        //console.log('Init Value used')
        mv_used_raw.current = []
        mv_used_words.current = []
        sibling = props.sibling
        Vl.current = props.Vl
        game_mode = props.gamedata.board_type;
        words_string = props.gamedata.level_longstring;
        sibling = props.sibling;
        //letterVl = props.letterVl
        size = props.gamedata.board_size;
        words_reds.current = JSON.parse(props.gamedata.level_red);
        words = [...props.gamedata.level_words];
        //words_ref.current = [...props.gamedata.level_words];
        gamedata.current = props.gamedata

        if (0 === props.level) {
            show_demo_word(0, props.color, 0)

            return (() => clearTimeout(demoTimeout));
        }

    }, [props.level, props.color, props.largeBoard, props.gamedata, props.Vl, props.letterVl])

    useEffect(() => {
        
        return;

        if (0 && largeBoard)
            return;

        //console.log('T1', mv_selected_list.current);

        let translateX = 0;
        let translateY = 0;
        let scale = 1.4

        let obj;

        let skip = false;
        let i = -1;

        if (mv_selected_list.current.length > 0) {
            i = mv_selected_list.current.length - 1;
            //console.log(mv_selected_list.current[i]);
            
            //console.log(props.Vl);
            //console.log(props.Vl[mv_selected_list.current[i].z]);
            obj = props.Vl[mv_selected_list.current[i].z];
            
            console.log('scrollIntoView');
            // obj.scrollIntoView({behavior: 'smooth'});
            console.log('scrollIntoView2');
            
            if (obj.transform && obj.transform.baseVal) {
                let x = obj.transform.baseVal[0].matrix.e;
                let y = obj.transform.baseVal[0].matrix.f;

                let d = 0;
                let d1 = 0;
                let m = 2;
                if (i == 0 && mouseCoord && mouseCoord.nativeEvent && mouseCoord.nativeEvent.layerX) {
                    d = (mouseCoord.nativeEvent.layerX - x) /5
                    d1 = (mouseCoord.nativeEvent.layerY - y) /5
                };

                translateX = -1*(scale - 1) * x + d;
                translateY = -1*(scale - 1) * y + d1;
                console.log(obj);
                console.log('Layer00', mouseCoord);
                console.log('Layer0', mouseCoord.nativeEvent);
                console.log('Layer1', mouseCoord.nativeEvent.layerX, mouseCoord.nativeEvent.layerY);
                console.log('Layer2', x, y)
                console.log(translateX, translateY);

                if (((x > 55) && (x < 205 )) && ( (y>55) && (y< 205))) {
                    // skip = true;
                }
                
                let distance = Math.sqrt((setPrevTrans.current.x - translateX)**2 + (setPrevTrans.current.y - translateY)**2); 
                console.log('Layer distance', distance);

                if (distance < 35)
                    skip = true;

                /*
                if (x < 160) {
                    translateX = 1*(160 - x )/m + d
                } else {
                    translateX = -1*(x - 160)/m - d
                }
                if (y < 160) {
                    translateY = (160 - y )/m + d1
                } else {
                    translateY = -1*(y - 160)/m - d1
                }*/
            };

            
            //translateY = 0
            //translateX = 0
            /*if (i == 0 ) {
                scaleup.current = `translate(${translateX},${translateY})scale(${scale})`;
                // console.log('Set setScaleUp', `translate(${translateX},${translateY}})scale(${scale})`);
            }
            else if ( i > 0) {
                scaleup.current = `scale(${scale})`;
            } else */{
                scaleup.current = false;
            }
        };

        //let scale = 1.0
        //translateY = 0.1
        //translateX = 0.1

        if (!skip || i == 0)
        if (crossPathRef.current) {
            
           setPrevTrans.current = {x:translateX, y:translateY, s:scale }; 
           props.makeScaleBoard(translateX, translateY, scale);
           makeScale(translateX, translateY, scale, largeBoard)
        }
        else {
            
            setPrevTrans.current = {x:0, y:0, s:1 }; 
            props.makeScaleBoard(0, 0, 1);
            makeScale(0, 0, 1, largeBoard)
        }

        // console.log('scrollIntoView');
        // obj && obj.scrollIntoView({behavior: 'smooth'});
        // console.log('scrollIntoView2');

    }, [highlightComponent, largeBoard]);

    if (!reff.current)
        return null;
    
    if (!propsData.current)
        return null;

    crosswordOffsetHeight.current = props.offsetHeight
    
    function makeScale(x, y, scale, largeBoard) {
        
        if (largeBoard)
            return;

        //console.log('makeScale AAAA0', crossPathRef);
        if (!crossPathRef.current)
            return;

        //console.log('makeScale AAAA1', x, y, scale);
        crossPathRef.current.makeScale(x, y, scale);
    }

    function updateUsedRaw(val) {
        mv_used_raw.current = [...mv_used_raw.current, val] //.push(val)
        
        let word = mv_get_word(val)
        mv_used_words.current = [...mv_used_words.current, word]
    }

    async function mv_endSelectingWord(e, color) {
    
        //return
        mv_select_state.current = false
        document.body.style.overflow = "auto";
        EventVal.current = "mv_endSelectingWord auto"

        //console.log('mv_endSelectingWord' + document.body.style.overflow)
        let word = mv_get_word()
        if ("" == word) {
            mv_selected_list.current = [];
            setHighLight(null);
            //highlightComponent.current = null;
            return
        }
        words = [...words_ref.current];

        let word1 = word.substring(0,word.length - 1);
        let word2 = (2 < word.length)? word.substring(0,word.length - 2):"";

        if (words.includes(word) && !mv_used_words.current.includes(word)) {
            //console.log('Good Word')
            try {
                if (!props.muted.current) audioGoodMove.play();
            } catch (err) {};
            await processCorrectWord(word);
        } else if (word1 && words.includes(word1) && !mv_used_words.current.includes(word1)) {
            //console.log('Good Word')
            try {
                if (!props.muted.current) audioGoodMove.play();
            } catch (err) {};

            await processCorrectWord(word1);
        } else if (word2 && words.includes(word2) && !mv_used_words.current.includes(word2)) {
            //console.log('Good Word')
            try {
                if (!props.muted.current) audioGoodMove.play();
            } catch (err) {};

            await processCorrectWord(word2);
        }
        else {
            
            // Lets HELP
            if (!lets_help(color))
                if (2 < word.length)
                    try {
                        if (!props.muted.current) setTimeout(audioBadMove.play);
                    } catch (err) {};

            check_word_exist(word);
        }

        mv_selected_list.current = [];
        setHighLight(null);
        //highlightComponent.current = null;
    }

    function check_word_exist(word) {
        if (word.length < 3)
            return;
        
        const postRef = ref(database, `/words/${word}`);
        onValue(postRef, (snapshot) => {
            if (snapshot.exists()) {
                enqueueSnackbar && enqueueSnackbar(`${word.toUpperCase()} - гарне слово, але тут щось інше!`, { autoHideDuration: 2550, anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },});
            }
        }, {
            onlyOnce: true
          });
    }

    function lets_help_blink_thefirst_timeout(count) {
        // console.log('BLINK ERROR!!!', count);

        //if (0 == mv_used_words.current.length)
        //    return;
        
        words = [...words_ref.current];

        for (var i=0; i < words_reds.current.length; i++) {
            
            //console.log('CURR', words, mv_used_words.current, mv_used_raw.current);
            if (mv_used_words.current && mv_used_words.current.includes(words[i]))
                continue;
            
            if (!(count--))
                return;

            let z = propsData.current.sibling_ordered[words_reds.current[i][0].toString()];
            let obj = props.letterVl.current['z'+ z];
            
            obj.animate(
                {
                opacity: [0, 1, 0, 1, 0, 1]
                },
                1000
            );
        }
    }

    function lets_help(color) {

        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
          );

        // lets hex help
        /*
        if ( -1 == $.inArray(word, words) || is_palindrom) {
            if (4 <= extra_check.length && ('voronoi2' == game_mode || 'hexagon' == game_mode || is_palindrom)) {

                iLen = extra_check.length
                still_check = true
                while (still_check) {
                    index_red = mv_get_index_red(extra_check.slice(0,iLen), true)
                    if (-1 != index_red) {
                        show_hex_help(index_red, extra_check.slice(0,iLen));
                        break
                    }
                    iLen = iLen - 1
                    still_check = (iLen >= 4)
                }
            }
        }
        */
        
        // Lets wave
        let index_red = mv_get_index_red(true)
        if (-1 != index_red && 2 < mv_selected_list.current.length && (5 <=  words[index_red].length)) {   
            if (-1 != index_red && !mv_used_words.current.includes(words[index_red]) ) {
                if (4 <  words[index_red].length && 2 * mv_selected_list.current.length >= words[index_red].length) {
                    
                    // show_message("ой, чуб горить ...")
                    props.ToolTipObj.current.selectIdx(index_red);
                    for (var i=0; i < mv_selected_list.current.length; i++) {

                        let obj = props.letterVl.current['z'+ mv_selected_list.current[i].z];
                        
                        delay(i*100).then(() =>obj.animate(
                            {
                            opacity: [0, 1, 0, 1, 0, 1]
                            },
                            1000
                        ));
                    };
                    try {
                        if (!props.muted.current) audioInterestMove.play();
                    } catch (err) {};
                    return true;
                }

            }
        }
        
        // Check the first letter
        if (!mv_selected_list.current[0])
            return false;

        // Blink if the first letter correct
        let iword = -1;
        // console.log('mv_selected_list.current:', letterVl, props.letterVl, mv_selected_list.current[0]);

        for (var i=0; i < words_reds.current.length; i++) {
            if (mv_get_cell(mv_selected_list.current[0]) == words_reds.current[i][0].toString()) {
                iword = i

                if ([1,2].includes(props.level)) {
                    // Show demo for 0, 1, 2 Level
                    show_demo_word(iword, color, 0);
                }
                else {
                    props.ToolTipObj.current.selectIdx(iword);
                    let obj = props.letterVl.current['z'+ mv_selected_list.current[0].z];
                    obj.animate(
                        {
                        opacity: [0, 1, 0, 1, 0, 1]
                        },
                        1000
                    );
                };
                
                try {
                    if (!props.muted.current)  audioAcceptMove.play();
                } catch (err) {};

                return true;
            }
        }
        return false;
        
    }

    function stop_demo() {

        if (demoTimeout) clearTimeout(demoTimeout);
        setHighLightDemo(null);

    }
    
    function show_demo_word(iword, color, len) {

        if (0 == len) {
            stop_demo();
        }

        let mv_list = []
        if (len >= words_reds.current[iword].length) {
            
            if (0 === props.level) {
                if (demoTimeout) clearTimeout(demoTimeout);
                demoTimeout = setTimeout(() => show_demo_word((1 + iword) % words_reds.current.length, color, 0), 600)
            } else {
                stop_demo();
            }

            return;
        }

        for (var j=0; j < len + 1; j++) {
            let t = words_reds.current[iword][j];
            let obj1 = mv_get_node(t);
            mv_list.push(obj1);

            let obj = props.letterVl.current['z'+ obj1.z];
            if (0) obj.animate(
                {
                opacity: [0, 1, 0, 1, 0, 1]
                },
                1000
            );
        }

        let new_ar = [...mv_list]
        // let str_color = color; //globTheme.palette.board.selection;
        
        let str_color = ColorSet[globTheme.palette.mode][iword % 10];
        //"#ff8a80"; //"rgb(255, 201, 226)";
        //console.log('str_color', str_color, globTheme.palette, props);
        let n = <>
        <CrossPath extra_pointer={true} id_object={{/*id:'id_cross_select'*/}} largeBoard={largeBoard} scaleup={scaleup} ref={crossPathRef} gamedata={gamedata.current} game_mode={game_mode} Vl={Vl.current} color={str_color} mv_selected_list={new_ar} crosswordOffsetHeight = {crosswordOffsetHeight.current}/></>
        setHighLightDemo(n)

        if (demoTimeout) clearTimeout(demoTimeout);
        
        demoTimeout = setTimeout(() => show_demo_word(iword, color, 1 + len), 400)
        return;

    }
    
    async function processCorrectWord(word) {
       
        let words = [...words_ref.current];
        
        let index_red = mv_get_index_red()
        
        /*
        if (null == crossPathRef.current)
            console.log('processCorrect2 is null');
        else
            console.log('processCorrect2 is not null');
        */
       
        if (-1 == index_red) {
            // Try to find correct position of word
            
            for (var i=0; i < words_reds.current.length; i++) {
        
                if (words[i]!=word) 
                    continue
                
                index_red = i

                mv_selected_list.current = []
                for (var j=0; j < words_reds.current[i].length; j++) {
                    let t = words_reds.current[i][j]
                    let obj = mv_get_node(t);
                    mv_selected_list.current.push(obj)
                }  
                break
            }
        }
       
        /*
        Firestore Security Rules:

        rules_version = '2';
        service cloud.firestore {
            match /databases/{database}/documents {
                match /current_passed_words/{game_id} {
                allow read, create: if request.auth != null;
                allow update: if (request.auth != null) && (request.resource.data.diff(resource.data).addedKeys().size() == 1);
                }
                
                match /games/{game_id} {
                allow read: if request.auth != null;
                }
            }
        }
        */
        if (-1!=index_red && word == words[index_red]) {
             
            let currentUserId = ""
            if (gamedata.current.currentUser.uid != gamedata.current.gameId)
                currentUserId = gamedata.current.currentUser.uid
            
            const docRef = doc(db, "current_passed_words", gamedata.current.gameId);
            
            //console.log('svg_html1');
            if (currentUserId == "") {
                
                // SOLO mode
                // var svg_html = getWordScreenshot({word: word, globTheme: globTheme, ...props})
                // console.log('svg_html', svg_html);
                
                await setDoc(docRef, {[word]:currentUserId}, { merge: true }).then(
                    async () => {

                        //var dataUrl= await toPng(crossPathRef.current);
                        //console.log('data URL ', dataUrl);
                        props.AddItemWordHistory(word);  
                        props.UpdateStatus('new word', word);
                        
                    }
                );

                return;
            };

            console.log('svg_html2');
            // MULTI Mode, need transaction

            //props.ToolTipObj.current.selectIdx(index_red);
            // setDoc(docRef, {[word]:'aaaaa'}, { merge: true });
            // setDoc(db, {[word]:'bbbbbbb'}, { merge: true });
            const meta_docRef = doc(db, "meta_data_games", gamedata.current.gameId);

            try {
                await runTransaction(db, async (transaction) => {
                    const sfDoc = await transaction.get(docRef);
                    const meta_sfDoc = await transaction.get(meta_docRef);
                    
                    if (!meta_sfDoc.exists() || (meta_sfDoc.data()['level'] && meta_sfDoc.data()['level']!= props.level)) {
                        throw "Document does not exist!";
                    };

                    let add_new = false;
                    if (!sfDoc.exists()) {

                        add_new = true;
                    } else {
                        
                        if (undefined == sfDoc.data()[word])
                            add_new = true;
                    }
                    
                    if (add_new) {
                        transaction.set(docRef, {[word]:currentUserId}, { merge: true });
                        const newScore = meta_sfDoc.data().players[currentUserId].score + 1;
                        transaction.update(meta_docRef, {[`players.${currentUserId}.score`]:  newScore});
                    } else {
                        throw "Document already exist!";
                    }
                });
                // Transaction successfully committed!
                props.AddItemWordHistory(word);

                //var svg_html = getWordScreenshot({word: word, globTheme: globTheme, ...props})
                //console.log('svg_html', svg_html.svg);
                var str_back_color = props.playersRef.current[currentUserId].color;
                var str_text_color = globTheme.palette.board.text;

                var show_word = word;
                
                if (props.gamedata.level_words2 && props.gamedata.level_words2[index_red])
                    if (16 > props.gamedata.level_words2[index_red].length )
                        show_word = props.gamedata.level_words2[index_red];

                var res = getPNGImage(show_word.toUpperCase(), str_back_color, str_text_color);

                //console.log('oooo', res);
                props.UpdateStatus('new word', { word: word, base64: res });

                /*s
                svg_html && getPNGImage(svg_html).then(
                    (res) =>  {
                        console.log('oooooooo:', res);
                        props.UpdateStatus('new word', { word: word, base64: res });
                    }
                );*/

                
                //console.log("Transaction successfully committed!");
            } catch (e) {
                console.log("Transaction failed: ", e);
            };              
        }
    }
    
    function getPNGImage(word, backColor, textColor) {

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        
        drawTextInBox(word, 'short_filmsmedium', 2, 72, 295, 105);
        //drawTextInBox(word, 'narbut_abetka', 2, 72, 295, 105);
        
        return (canvas.toDataURL());

        function drawTextInBox(txt, font, x, y, w, h, angle) {
            angle = angle || 0;
            var fontHeight = 16;
            var hMargin = 2;
            
            ctx.fillStyle = backColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.font = '' + fontHeight + 'px ' + font;
            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
            var txtWidth = ctx.measureText(txt).width + 2 * hMargin;
            ctx.save();
            ctx.translate(x+w/2, y);
            ctx.rotate(angle);
            // ctx.strokeRect(-w/2, 0, w, h);
            ctx.scale(w / txtWidth, h / fontHeight);
            ctx.translate(hMargin, 0)
            ctx.fillStyle = textColor;
            ctx.fillText(txt, -txtWidth/2, 0);
            ctx.restore();
          }
    }

    async function getPNGImage2(svgURL) {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = async () => {
                var canvas = document.createElement('canvas');
                var ctxt = canvas.getContext("2d");
                ctxt.drawImage(img, 0,0);
                resolve(canvas.toDataURL());
            };
            img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL.svg);
        });
    }

    async function toPng(data) {

        const preset = presets.offscreen()
        
        const {
            width,
            height,
            svg
        } = data
        // const canvas = new OffscreenCanvas(width, height)
        var canvas = document.createElement('canvas');
        //canvas.width = width;
        //canvas.height = height;

        const ctx = canvas.getContext('2d')
        const v = await Canvg.from(ctx, svg, preset)
        
        /**
         * Resize SVG to fit in given size.
         * @param width
         * @param height
         * @param preserveAspectRatio
         */
        //v.resize(width, height, 'xMidYMid meet')
        
        // Render only first frame, ignoring animations and mouse.
        await v.render()
        
        return canvas.toDataURL()
        // const blob = await canvas.convertToBlob()
        /*const arrayBuffer =  blob.arrayBuffer()  
        var base64 = btoa(
            new Uint8Array(arrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );*/      

        //return base64;
        //const pngUrl = new FileReaderSync().readAsDataURL(blob);

        //const pngUrl = URL.createObjectURL(blob)
        
        //return pngUrl
    }

    function mv_get_index_red(only_part = false) {

        var res = []
        let m_list = [...mv_selected_list.current]
        for (var i = 0; i < m_list.length; i++) {
                let item = m_list[i]

                res.push(mv_get_cell(item))
                /*
                if (''==item.z) {
                        x = size - 1 - item.x
                        y = size - 1 - item.y

                        res.push(x * size + y)
                } else 
                        res.push(mv_get_cell(x,y,item.z))*/
        }
        //console.log(sibling_ordered)
        //console.log('R1',res)
        let check_list = [...res].sort()
        let check2

        for (var i=0; i < words_reds.current.length; i++) {

            if (!only_part)
                check2 = [...words_reds.current[i]]
            else
                check2 = [...words_reds.current[i].slice(0, check_list.length)]

            if (JSON.stringify(check_list) == JSON.stringify(check2.sort())) {

                // Check the first letter
                if (only_part && res[0]!=words_reds.current[i][0])
                        return -1

                return i
            }
        }
        return -1
    }

    function mv_get_cell(item) {
     
        /*if (item.z.toString() == '') {
           x = size - 1 - item.x
           y = size - 1 - item.y
           return x * size + y;
        }*/
   
        return propsData.current.sibling_ordered.indexOf(item.z.toString())
   }
   
   function mv_get_node_index (t) {
           return propsData.current.sibling_ordered[t.toString()]
   }
   function mv_get_node (t) {
   
        let x,y = (null, null)

        if (size > 0) {
            x = Math.floor(t / size)
            y = t - x * size

            x = size - 1 - x
            y = size - 1 - y
        }
        
        let z = propsData.current.sibling_ordered[t.toString()]
        //return {x: null, y: null, z: z}
        return {x: x, y: y, z: z}
   }

    function mv_beginSelectingWord(e, largeBoard, color) {
        mv_select_state.current = true

        let newDate = new Date()
        stop_demo();
        
        EventVal.current = "mv_beginSelectingWord" + newDate.getTime()
        //e.preventDefault();
        //e.stopPropagation();
        //console.log('mv_beginSelectingWord:'+'preventDefault')
        // console.log('mv_beginSelectingWord:'+ color)
        mv_hiliteSelection(e, largeBoard, color);

    }

    function handleBoxToggle(e) {
    
        let t = e.target.closest(".box")
        EventVal.current = "handleBoxToggle1"
        if (!e.target.closest(".box")) {
            EventVal.current = "handleBoxToggle2"
            //console.log('OUT')
            //e.preventDefault();
            //e.stopPropagation();
            //mv_endSelectingWord(e);

            return
        }
       
        //console.log(x,y,z)
        //setData([x,y,z])
        //void 0 === e.target.closest(".box")[0] && (e.preventDefault(), e.stopPropagation(), mv_endSelectingWord(e))
        //var link = e.target.closest('.box');
     
        // this.element = React.createRef()
        // <div ref={this.element}>
        //this.element.current.addEventListener('click', this)
        // void 0 === $(e.target).closest(".box")[0] && (e.preventDefault(), e.stopPropagation(), mv_endSelectingWord(e))
        //console.log('MouseOver');
    
    }
    
    function mv_hiliteSelection(e, largeBoard, color) {
    
        if (!mv_select_state.current) return;
       
        document.body.style.overflow = "hidden";
        //document.body.style.overflow = "scrolls";
        //mv_select_state ? console.log('True Move') : console.log('False Move');
    
        // From mv_set_list
        

        let mv_list_changed = false
        
        var r = e.target;

        var t;
        if (r.classList.contains("box")) {
            t = r;
        } else if (r.parentElement && r.parentElement.classList.contains("box")) {
            t = r.parentElement;
        } else {    
            t = r.closest(".box");
        };

        //e.preventDefault();
        //e.stopPropagation();
        
        //console.log('mv_hiliteSelection:'+'preventDefault')
        //console.log('mv_hiliteSelection:'+'stopPropagation')

       /*if ((void 0 !== e.originalEvent) && e.originalEvent.changedTouches) {
                var a = e.originalEvent.changedTouches[0];
                r = document.elementFromPoint(a.clientX, a.clientY)
        }*/
        
        //var t = $(r).clcroposest(".box");
        //console.log(r)
        //console.log(r.closest(".box"))
    
        
        
        if (null !== t) {
            
            if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
            
                var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
                var touch = evt.touches[0] || evt.changedTouches[0];
                
                var elementMouseIsOver = document.elementFromPoint(touch.pageX, touch.pageY);
                t = elementMouseIsOver.closest(".box")
                /*if (null !== elementMouseIsOver.parentNode) {
                    t = elementMouseIsOver.parentNode; 
                    console.log(t);
                };*/
            };

            setMouseCoord(e);

            const x = t.getAttribute("data-x");
            const y = t.getAttribute("data-y");
            const z = t.getAttribute("data-z");

            let obj = {x: x, y:y, z:z};
            if (!can_select([...mv_used_raw.current], obj)) {return}
            
            if (!mv_if_inlist( obj) ) {
                if (mv_is_sibling( propsData.current.sibling, obj) ) {
                    
                    mv_selected_list.current.push(obj)
                    mv_list_changed = true
                    

                    //console.log('PUSH');
                    //console.log('mv_set_list true', mv_selected_list);
                }
            } else {
                // Already here, need to crop
                
                if (mv_crop_list(obj)) {
                    mv_list_changed = true
                }
            }
    
            if (mv_list_changed) {
            
                //console.log(mv_selected_list)
                mv_list_changed = false
                //console.log(mv_selected_list)
                //return
                //$(".temp-hilite-mark-tt", Jl).remove()
                let new_ar = [...mv_selected_list.current]
                
                let str_color = color; //globTheme.palette.board.selection;
                //"#ff8a80"; //"rgb(255, 201, 226)";
                //console.log('str_color', str_color, globTheme.palette, props);
                let n = <>
                <CrossPath id_object={{/*id:'id_cross_select'*/}} largeBoard={largeBoard} scaleup={scaleup} ref={crossPathRef} gamedata={gamedata.current} game_mode={game_mode} Vl={Vl.current} color={str_color} mv_selected_list={new_ar} crosswordOffsetHeight = {crosswordOffsetHeight.current}/></>
                setHighLight(n)
               //highlightComponent.current = n;
                //setHighLight(mv_pi(Vl, mv_selected_list, "rgb(255, 201, 226)", "temp-hilite-mark-tt", [], !0, [], crosswordOffsetHeight.current));
                
            }
        }
        // Unable to preventDefault inside passive event listener invocation.
        // e.preventDefault();
        e.stopPropagation();
    
    }

    function mv_get_word(val) {
        
        words_string = propsData.current.gamedata.level_longstring
        var word = ""

        let list = mv_selected_list.current;

        if (val !== undefined) {
            list = val
        }

        for (var i = 0; i < list.length; i++) {
                let item = list[i]
                //let obj = getVl(item)
                let j = propsData.current.sibling_ordered.indexOf(item.z)
                word = word  + words_string[j]
                //word = word  + getVl(item).innerText
                //word = word  + $(".letter-in-box", obj).text() 
        }
        return word.toLowerCase();
    }
    
    function getVl(item) {
            
        let x,y,z = (item.x, item.y, item.z)
    
        //console.log(letterVl.current['z'+z].innerText)
        return props.letterVl.current['z'+z];
    }

    function mv_crop_list(obj) {
        
        let m_list = [...mv_selected_list.current]
        let item = m_list[m_list.length - 1]
    
        if (item.z == '') {
            if ((item.x == obj.x) && (item.y == obj.y)) {
                return false
            }
        } else {
            if (item.z == obj.z) 
                return false
        }
        let new_list = []
    
        for (var i = 0; i < m_list.length ; i++) {
            item = m_list[i]
    
            if (item.z == '') {
                if ((item.x == obj.x) && (item.y == obj.y)) {
                    new_list.push(item)
                    break;
                }
                else
                {
                    new_list.push(item)  
                }
            } else {
                if (item.z == obj.z)  {
                    new_list.push(item)
                    break;
                }
                else
                {
                    new_list.push(item)  
                }
            }
        }
        mv_selected_list.current = new_list
        return true
    }

    function can_select(a_mv_used_raw, obj) {

        for (var j = 0; j < a_mv_used_raw.length; j++) {
            for (var i = 0; i < a_mv_used_raw[j].length; i++) {
                    
                let item = a_mv_used_raw[j][i]
                
                if (item.z != '') {
                    if (item.z == obj.z)
                            return false
                }else
                    if ((item.x == obj.x) && (item.y == obj.y)) {
                            return false
                    }
            }
        }
        return true
    }
    
    function mv_if_inlist(obj) {
            
        for (var i = 0; i < mv_selected_list.current.length; i++) {
            let item = mv_selected_list.current[i]
    
            if ('' == obj.z ) {
                if ((item.x == obj.x) && (item.y == obj.y)) {
                    return true
                }
            }
            else {
                if (item.z == obj.z) {
                        return true
                    }    
            }
        }
        return false
    
    }
    
    function mv_is_sibling(sibling, obj) {
            
        if (0 == mv_selected_list.current.length) {return true}
    
        let item = mv_selected_list.current[mv_selected_list.current.length - 1]
        //console.log(obj, sibling)
        if (obj.x===undefined || obj.y === undefined || obj.x===null || obj.y === null) {
                return (-1 != sibling[obj.z.toString()].indexOf(item.z.toString()) || -1 != sibling[obj.z.toString()].indexOf(item.z))
        }
    
        if ('' != obj.y.toString() && '' != obj.x.toString()) {
                if ((item.x == obj.x) && (Math.abs(item.y - obj.y)) == 1) {
                                return true
                        }
                if ((item.y == obj.y) && (Math.abs(item.x - obj.x)) == 1) {
                                return true
                        }
        } else {
                return (-1 != sibling[obj.z.toString()].indexOf(item.z.toString()) || -1 != sibling[obj.z.toString()].indexOf(item.z))
        }
    
        return false
    
    }

    return (<>{highlightComponent}{highlightComponentDemo}</>)
  })