//import firebase from 'firebase'

import { initializeApp } from "firebase/app";
//import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.9.3/firebase-app.js'
// './firebase/firebase-app.js'
// <meta http-equiv="Content-Security-Policy" content=" script-src 'self' 'unsafe-inline' 'unsafe-eval' *.gstatic.com *.googleapis.com *.firebasedatabase.app *.firebaseapp.com *.firebaseio.com *.googletagmanager.com *.facebook.com *.facebook.net *.google-analytics.com">
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyC_Zh1vPfSW_w6JP-P4XsZ1wtPo0MI0v2E",
    //authDomain: "mova-game-apps.firebaseapp.com",
    authDomain: "www.mova.ua",
    databaseURL: "https://mova-game-apps-14cc2.firebaseio.com/",
    databaseURL2: "https://mova-game-apps-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mova-game-apps",
    storageBucket: "mova-game-apps.appspot.com",
    messagingSenderId: "1090468798284",
    appId: "1:1090468798284:web:381cabf9c50ce41142f9e9",
    measurementId: "G-TD46STDGPR"
  };
  
export const firebaseConfig2 = {
    apiKey: "AIzaSyC_Zh1vPfSW_w6JP-P4XsZ1wtPo0MI0v2E",
    //authDomain: "mova-game-apps.firebaseapp.com",
    authDomain: "www.mova.ua",
    databaseURL: "https://mova-game-apps-b826f.firebaseio.com",
    projectId: "mova-game-apps",
    messagingSenderId: "1090468798284",
    appId: "1:1090468798284:web:381cabf9c50ce41142f9e9",
  };
  
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);

//const db = firebase.firestore();

//export default database;
export default firebaseApp;