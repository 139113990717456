import { useNavigate, useParams } from "react-router-dom";
import {useRef, useState,useEffect} from 'react';
import Header from "../Header";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {Link} from "react-router-dom";
import {useUser} from '../UserContext';
import Typography from '@mui/material/Typography';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, onSnapshot} from "firebase/firestore"; 
import { runTransaction, transaction, updateDoc, Timestamp, serverTimestamp } from "firebase/firestore";
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import firebaseApp from '../firebase.config';
import { getFirestore } from "firebase/firestore";
import {setUserLastActive} from '../UserContext';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Login from "../Login"
import { useTheme } from '@mui/material/styles';

import game_img_dark from '../static/img/game_600_600.png';
import game_img_light from '../static/img/game_600_600_light.png';



export default function MultiGameSplash(props) {

    let { gameId, fromId } = useParams();
    const navigate = useNavigate();
    const user = useUser();
    const invitor = useRef();
    const [avatar, setAvatar] = useState();
    const [usr_name, setUsrName] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const globTheme = useTheme();
    
    useEffect(() => {
        if (user && !user.isAnonymous ) {
                
            // TODO: redirect to multigame after Login
            user.firstLoginMultiGameRedirect.current = true;
            navigate("/");
            return;
        };
    }, [user]);

    return <>
    <Box sx={{display: 'flex',justifyContent:"center",  flexDirection: 'column',    paddingTop:"20px", paddingLeft: "10px", paddingBottom: "0px" }}>
        <Stack direction="row" sx={{display: 'flex',justifyContent:"center"}}>
        <Typography sx= {{pr:2, cursor:"pointer", fontSize:"21px"}} variant="body" onClick={() => navigate("/")}>Самотність</Typography>
        <Switch checked={true} onChange={(event) => {navigate("/")}} size="small" />
        <Typography sx= {{pl:2, cursor:"pointer", fontSize:"21px"}} variant="body3" >Друзі</Typography>
        </Stack>
      <Header />
      </Box>
        <Card sx={{ maxWidth: 325, ml:2, pb:2, mb:0 }} elevation={12} >
        <CardHeader
          avatar={
            <Avatar src={avatar} alt={usr_name}>
            </Avatar>
          }
          
          title={"Грати з друзями веселіше!"}
          subheader="Змагання  - хто більше знайде слів! Cпочатку, зареєструйся!"
        />
        <CardMedia 
          component="img"
          height="194"
          image={('dark' == globTheme.palette.mode) ? game_img_dark : game_img_light }
          alt="Paella dish"
         
        />
        <CardContent sx={{p:0, m:0}}>
        <Box sx={{display: 'flex',justifyContent:"center"   }}>
        <Login hidemessage={true}/>
        </Box>
        </CardContent>
        <CardActions disableSpacing>
        </CardActions>
    </Card></>
}