import { useState, useEffect, useRef} from 'react';

export default (props) => {

    const canvasRef = useRef(null);

    useEffect(() => {
        let canv = canvasRef.current
        // ctx = canv.getContext('2d')
        //Our first draw
        // Draw(canvas, context);

        let animationFrameId = init(canv, props.percent);

        //setTimeout(() => {grey_mode=2}, 5000 * props.percent / 100)

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }

      }, [])
    
      function init(canvas, percent) {
        // I've tried to scale the size of each step
        // It kind of works for: 0 <= iterations <= 8
        function incX(angle) {
          return  Math.round(scale * Math.cos(angle)*min/(Math.pow(iterations+1, 2.2)));
        }
        function incY(angle) {
          // Y increases downwards on the canvas -->
          // switch sign so it's the same as Cartesian.
          return Math.round(scale * Math.sin(angle)*min/(Math.pow(iterations+1, 2.2)))*-1;
        }
        
        //canvas.width = window.innerWidth;
        //canvas.height = window.innerHeight;
        var min = Math.min(canvas.width, canvas.height);
        var ctx = canvas.getContext("2d");
        
        // Try changing this!
        var iterations = 6;
      
        function goto_next() {
          //window.location.href = "/";
        }
      
        var x,y;
        var angle = 0;
        
        //
        // L-system
        //
        // Alphabet : A, B
        // Constants : F + −
        // Axiom : A
        // Production rules:
        // A → − B F + A F A + F B −
        // B → + A F − B F B − F A +
        // Here, "F" means "draw forward", 
        //       "−" means "turn left 90°", 
        //       "+" means "turn right 90°" and 
        //       "A" and "B" are ignored during drawing.
        
        // Axiom
        var result = "A";
        
        var rules = {
          A: "-BF+AFA+FB-", // Rule 1
          B: "+AF-BFB-FA+"  // Rule 2
        };
        
        var scale = 1;
        var dx = -40;
        var dy = 20;
      
        // Production
        for(var i = 0; i < iterations; ++i) {
          result = replaceAll(result, rules);
        }
      
        function replaceAll(str, mapObj){
          var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
      
          return str.replace(re, function(matched){
              return mapObj[matched];
          });
        }
        
        var numberOfF = (result.match(/F/g) || []).length;
        var currentFIndex = 0;
        
        x = dx + Math.round(scale * canvas.width / (iterations + 1));
        y = dy + Math.round(scale * canvas.height - canvas.height / (iterations + 1));
        
        //for(var index = 0; index < result.length; ++index) {
        var index = 0;
        var is_grey = 0;
      
        function draw() {
      
          if(index >= percent*result.length/100) {
            is_grey = 1;
            //return;
          }
      
          if(index >= result.length) {
            //goto_next();
            setTimeout(goto_next, 2000);
            return
          }
      
          if(result[index] === "F") {
            var color = currentFIndex / numberOfF * 360;
            
      
            //console.log(color)
            ctx.strokeStyle = "hsl(" + color + ", 100%, 40%)";
            if (is_grey)
              ctx.strokeStyle = "hsl(0, 0%, 50%)";
            ctx.beginPath();
            ctx.moveTo(x, y);
            x += incX(angle);
            y += incY(angle);
            ctx.lineTo(x, y);
            ctx.stroke();
            ++currentFIndex;
          } else if(result[index] === "+") {
            angle -= Math.PI/2;
          } else if(result[index] === "-") {
            angle += Math.PI/2;
          }    
          ++index;
      
          let timer_step = 30
          if (3 == iterations ) 
            timer_step = 4
      
          if (4 == iterations ) 
            timer_step = 8
      
          if (5 == iterations ) 
            timer_step = 16
      
          //timer_step = Math.round(timer_step*percent/100)
          timer_step = Math.round(timer_step)
      
          if(index % timer_step === 0) {
            return requestAnimationFrame(draw);
          } else {
            return draw();
      
          }
        }
        
        return draw();
    };

    return <canvas width="350px" height="350px" ref={canvasRef} />
};