import React,{useState,useRef, useEffect} from 'react';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Tooltip from "@mui/material/Tooltip";
import AvatarGroup from '@mui/material/AvatarGroup';
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import { keyframes } from '@mui/system';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import {handleFbFriends, ModalInviteFriends} from './ModalInviteFriends';

import { collection, getDocs, onSnapshot} from "firebase/firestore"; 
import { query, where, orderBy, limit } from "firebase/firestore";
import {serverTimestamp, getFirestore, arrayUnion, documentId } from 'firebase/firestore';
import firebaseApp from '../firebase.config';
const db = getFirestore(firebaseApp);
/* import { useTheme } from '@emotion/react'; */


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 6.2s infinite ease-in-out',
        border: '1px solid maroon',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(1.4)',
        opacity: 0,
      },
    },
  }));

  const blink = keyframes`
  from { opacity: 0.3; }
  to { opacity: 1; }
`;

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 3}px 0`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
})

export default function MultiScore(props) {
    
    const [players_score, setPlayers] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [show_total, setShowTotal] = useState(false);
    const [last_active_time, SetLastActiveTime] = useState("");
    const playersOnlineRef = useRef([]);

    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [photos, setPhoto] = useState({});
    const theme = useTheme();

    useEffect(() => {
        if (null == props.players)
            return;
        
        if (null == props.gameId)
            return;

        //setPlayers(props.players);
        //console.log(props.passed_words);

        for (const [key, value] of Object.entries(props.players)) {
            // console.log(`${key}: ${value}`);
            props.players[key].game_score = 0; 
            playersOnlineRef.current[key] = false; 
            // console.log('HHHHHH here');
            props.user.getUserPhotoURL(key, (url)=>{ setPhoto(prev => {prev[key] = url; return structuredClone(prev);})});
          }

        let allZero = true;

        /*
        if (props.passed_words)
        for (const [key, value] of Object.entries(props.passed_words)) {
            // console.log(`${key}: ${value}`);
            if (props.players[value]) {
                if (0 <= props.players[value].game_score) {
                    props.players[value].game_score++;
                    allZero = false;
                } else {
                    props.players[value].game_score = 0; 
                }
            }
          }

        let sortable;

        if (props.show == "short" && !allZero)
            sortable = Object.entries(props.players)
            .sort(([,a],[,b]) => b.game_score-a.game_score);
            //.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        else
            sortable = Object.entries(props.players)
            .sort(([,a],[,b]) => b.score-a.score);
        */
            let sortable;
            sortable = Object.entries(props.players)
            .sort(([,a],[,b]) => b.score-a.score);
        /*
        if (props.last_heroes) {
          console.log('Last', Object.entries(props.last_heroes)); 
          console.log('Last1', (props.last_heroes)); 
        }*/

        setShowTotal(allZero);
        setPlayers(sortable);

        if (props.playmode == "multi" && props.players && 1==Object.keys(props.players).length) {
          if (props.calcOpenModalFunction(props.gameId))
            handleOpen();
        }

        // documentId
        var rows_friends_ids = Object.keys(props.players);
        //console.log(rows_friends_ids);
        //console.log(props.players);

        if (rows_friends_ids.length > 0)
          return onSnapshot(query(collection(db, "logs_user_active"), orderBy(documentId()), where(documentId(), "in", rows_friends_ids), limit(150)), {}, (querySnapshot) => {
          let docs = [];

          var date = new Date();
          var FIVE_MIN=5*60*1000;

          var max_date = false;
          querySnapshot.forEach(async (doc) => {
              let data = doc.data();
              // doc.id, data.last_time
              //data from database
              // console.log("db: " + data.last_time.toDate())
              // console.log("current date: " + date)
              if (!data.last_time)
                return;

              if (!max_date || max_date < data.last_time.toDate())
                max_date = data.last_time.toDate();

              playersOnlineRef.current[doc.id] = 
                ((date - new Date(data.last_time.toDate())) < FIVE_MIN)
                
              //sconsole.log(playersOnlineRef.current[doc.id]);
          });
          SetLastActiveTime(max_date)
          
        });

    }, [props.players, props.passed_words, props.gameId]);

    function handleOpen() {

      
      if (props.playmode == "solo") 
        props.setSwitchChecked(true);
      else

        if (true) { //props.owner == props.userId) {
          
          if (process.env.REACT_APP_FACEBOOK_MODE) 
            handleFbFriends({userId: props.userId, gameId: props.gameId});
          else  
            setOpen(true);
        } else {
          // create new multi game
        }
    }

    function get_max_game_score(players_score)
    {
      let max_score = 0
      players_score.map((item, index) => {

        if (max_score < players_score[index][1].game_score)
          max_score = players_score[index][1].game_score;
      })
      
      return max_score;
    }

    let animatedUserAdd = {
      animation: "spin 4s  infinite",
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg) scale(1)"  
        },
        "40%": {
          opacity: 0.7,
          transform: "scale(1.2)" 
        },
        "100%": {
          opacity: 1,
          transform: "scale(1)" 
        },
      },
    }

    

    // animatedUserAdd = {}
    if (props.playmode !== "multi" || !players_score || 1 < Object.keys(players_score).length)
      animatedUserAdd = {}

    // console.log('players_score1', playersOnlineRef.current, Object.keys(playersOnlineRef.current).length);
     
    if (players_score )
         return (
            <>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <AvatarGroup max={5} spacing='medium'>
            {players_score && players_score.map((item, index) => 
                players_score && players_score[index] && <Badge    
                key={index}
                overlap="circular"
                badgeContent= {(props.show == "short" && !show_total) ? players_score[index][1].game_score : players_score[index][1].score /*+ ( !show_total && props.last_heroes && props.last_heroes[item[0]] ? "+1":"" )*/} 
                max={2000}
                showZero anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}

                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: players_score[index][1].color,
                      color: theme.palette.getContrastText(players_score[index][1].color)
                    }
                  }}
                  >
                <Tooltip title={item[1].name?item[1].name:""} key={index + last_active_time}>
                <Avatar
                    key={index}
                    alt={item[1].name}
                    src={/*item[1].photo*/photos[item[1].uid]}
                    /*style={(props.show == "full" && props.last_heroes && props.last_heroes[item[0]] || props.show == "short" && players_score[index][1].game_score && players_score[index][1].game_score == get_max_game_score(players_score)) ? {
                      border: '3px solid ' + players_score[index][1].color
                   } : {}}*/
                   style={(playersOnlineRef.current[item[1].uid])?{
                    border: theme.palette.onlineStatus
                 }: {}}
                    sx={(props.show == "short") ?{ width: 36, height: 36} : { }}
                    />
                </Tooltip>
                </Badge>
            )}
            </AvatarGroup>
            {(props.playmode == "multi" /*&& props.owner == props.userId*/ ) && <IconButton onClick={handleOpen}  >
              <Avatar sx={animatedUserAdd}>
                <PersonAddAltIcon />
              </Avatar>
            </IconButton>}
            {/*(props.playmode == "multi" && props.owner != props.userId) && <IconButton onClick={props.CreateNewMultiGame}  >
              <Avatar sx={animatedUserAdd}>
                <PersonAddAltIcon />
              </Avatar>
                </IconButton>*/}
            </Stack>
            <ModalInviteFriends open={open} handleClose={handleClose} gameId={props.gameId} userId = {props.userId} />
            </>
        )
    else
        return <></>
}