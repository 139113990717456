import {
    BrowserRouter,  
    Routes,
    Route,
    Link,
    Outlet
  } from "react-router-dom";

import React,{useState,useEffect, useRef} from 'react';

import Game from "./Game"
import About from "./About"
import Slova from "./Slova"
import Rating from "./Rating"
import Profile from "./Profile"
import Statistic from "./Statistic"
import Dictionary from "./Dictionary"
import Error from "./Error"
import Invite from "./Invite"
import Login from "./Login"
import Privacy from "./Privacy"
import {UserProvider} from "./UserContext"
import {useUser} from './UserContext';
import { cloneElement } from 'react';
import MultiGameSplash from "./game/MultiGameSplah";

const RequireAuth = ({ children}) => {
    const userIsLogged = useUser(); // Your hook to get login status
  
    //console.log(children)
    if (!userIsLogged || !children) {
       return <></>;
    }
    
    const clonedElement = cloneElement(children, {uid: userIsLogged.uid})
    // console.log(clonedElement);
    // children.props.uid = userIsLogged.uid;

    return clonedElement;
  };
  
export function RoutesPathes(props) {

    // const [game_profile, setProfile] = useState(null);

    //const user = useUser(); 

    /*if (!user)
        return <></>;
    */
    //user.onProfileUpdate = (item) => {setProfile(item.current)};

    return <>
    <Routes themeMode={props.acttheme.palette.mode} >
      <Route path="/" element={<UserProvider><Game /*uid={user.uid}*/ palettemode = {props.acttheme.palette.mode} ChangeDarkTheme={(mode) => props.setUserThemeMode(mode)} /></UserProvider>} />
      <Route path="/rating" element={<UserProvider><Rating /></UserProvider>} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/userdatadelete" element={<Privacy delete={true}/>} />
      <Route path="/dzherela" element={<About />} />
      <Route path="/dict/">
        <Route path=":word" element={<UserProvider><Dictionary /></UserProvider>} />
        <Route path="" element={<UserProvider><Dictionary /></UserProvider>} />
      </Route>
      <Route path="/profile" element={<UserProvider><RequireAuth><Profile /*game_profile={game_profile}*/ /></RequireAuth></UserProvider>} />
      <Route path="/stat" element={<UserProvider><RequireAuth><Statistic /*game_profile={game_profile}*/ /></RequireAuth></UserProvider>} />
      <Route path="/error" element={<Error />} />
      <Route path="/invite/:gameId/:fromId" element={<UserProvider><Invite /></UserProvider>} />
      <Route path="/login" element={<UserProvider><Login /></UserProvider>} />
      <Route path="/slova" element={<Slova />} />
      <Route path="/druzi" element={<UserProvider><MultiGameSplash /></UserProvider>} />
    </Routes>
    </>

}